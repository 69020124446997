const {getWisdPresalePrice} = require("../../../apihelper/presale");

const getPresalePriceData = async (round) => {
    try {
        const result = await getWisdPresalePrice(round);
        if (result.code) {
            throw new Error(`Error: ${result.code} ${result.message}`);
        }
        return result;
    } catch (error) {
        console.error("Error fetching presale price data:", error);
    }
};

const handleStripeCheckout = async (price_id) => {
    try {
        const response = await fetch("https://investing.crowdwisdom.live/create-onetime-checkout-session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({price_id}), // Send the price_id in the request body
        });

        const session = await response.json();

        if (session.error) {
            console.error("Server Error: ", session.error); 
            return;
        }

        if (session.url) {
            // window.location.href = session.url; // Redirect to Stripe Checkout
            window.open(session.url, '_blank');
        }
    } catch (error) {
        console.error("Error during checkout: ", error);
    }
};

module.exports = {getPresalePriceData, handleStripeCheckout};
