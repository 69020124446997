import {Routes, Route, BrowserRouter as Router, useLocation} from "react-router-dom";
import EmailTemplateDesign from "./pages/email_template_design";
import Home from "./pages/home/home";
import {Navigate} from "react-router-dom";
import Pricing from "./pages/Pricing";
import Screener360_Pricing from "./pages/Screener360_pricing";
import CryptoAlerts from "./pages/CryptoAlerts";
import Welcome from "./pages/home/welcome";
import CryptocurrencyList from "./pages/cryptocurrencylist/cryptoCurrencyList";
import Profile from "./pages/profile/profile";
import Login from "./pages/login/login";
import Account from "./pages/account/account";
import InfluencerAccount from "./pages/account/influencerAccount";
import PortfolioList from "./pages/account/portfoliolist";
import CryptoInfluencerRankings from "./pages/portfolio/cryptoInfluencerRankings";
import PrivacyPolicy from "./pages/privacypolicy/privacyPolicy";
import TermOfUse from "./pages/termsofuse/termsOfUse";
import About from "./pages/about/about";
import Aboutus from "./pages/about/aboutus";
import Portfolio from "./pages/portfolio/portfolio";
import OrderBook from "./pages/portfolio/orderBook";
import OrderBookShare from "./pages/portfolio/orderBookShare";
import PerformanceSummary from "./pages/portfolio/performanceSummary";
import PerformanceSummaryShare from "./pages/portfolio/performanceSummaryShare";
import CategoryBasedInfluencers from "./pages/portfolio/categoryBasedInfluencers";
import OverallInfluencerRankings from "./pages/portfolio/overallInfluencerRankings";
import {Helmet} from "react-helmet";
import PortfolioShare from "./pages/portfolio/PortfolioShare";
import PortfolioFaqs from "./pages/portfolioFaqs/portfolioFaqs";
import Portfolioadd from "./pages/addPage/portfolioadd";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {HashRouter} from "react-router-dom";
import AirdropForm from "./pages/AirdropFrom";
import Error from "./pages/error/error";
import {useState} from "react";
import Roadmap from "./pages/roadmap";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import {PathProvider} from "./includes/PathContext";
import MultiPortfolio from "./pages/portfolio/MultiPortfolio";
import MultiPortfolioWatchlist from "./pages/portfolio/MultiPortfolioWatchlist";
import Recommendations from "./pages/portfolio/recommendations";
import CryptoPage from "./pages/CryptoPage";
import CryptoPagePredictions from "./pages/CryptoPagePredictions";
import CryptoPortfolio from "./pages/home/cryptoPortfolio";
import PortFolioListGallery from "./pages/home/portfoliolistgallery";
import InfluencerPredictions from "./pages/home/influencerpredictions";
import CryptoForecastForm from "./pages/cryptoForm";
import CategoryPortfolioHome from "./pages/home/categoryPortfolios";
import Whitepaper from "./pages/whitepaper";
import MainLayout from "./pages/Mainlayout";
import PortfolioNetworthGraph from "./pages/portfolioNetworthGraph";
import NetworthGraphEachIndex from "./pages/NetworthGraphEachIndex";
import SidebarMenuList from "./includes/sidebarMenuList";
import NewUserAudit from "./pages/portfolio/new_user_audit";
import { HelmetProvider } from 'react-helmet-async';
import MetaProperty from "./common/meta_property/MetaProperty";
import Presale from "./pages/presale";

const ASSET_PATH = process.env.PUBLIC_URL;
const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Satoshi, sans-serif",
        },
    },
});

function App() {
    const [isBot, setIsBot] = useState(false);

    const isBotCheck = () => {
        const userAgent = navigator.userAgent.toLowerCase();

        // Check for both Googlebot and Bingbot
        return userAgent.indexOf("googlebot") !== -1 || userAgent.indexOf("bingbot") !== -1;
    };

    useEffect(() => {
        const botStatus = isBotCheck();
        setIsBot(botStatus);
    }, []);

    const [userdata, setUserdata] = useState(null);

    const [checkUserData, setCheckUserData] = useState(false);
    window.constants = {
        asset_path: ASSET_PATH, // Add the asset path to constants
    };
    useEffect(() => {
        //gopal
        // localStorage.setItem(
        //   "cw_portfolio_user",
        //   JSON.stringify({
        //     "user_id":"18","email_id":"gopalgoyal612002@gmail.com","full_name":"gopal goyal","picture":"https://lh3.googleusercontent.com/a/ACg8ocLGVg7GuPWypnJHOmyL6pYyQ7N-IbtaM0JXyxodr-35CN4=s96-c","portfolio_name":"gopal goyal1","country":"India","portfolio_form_redirect":"no","token":"ZWQxaXNVZzJyY3ZUNWljZmZwZHc2UDJoek1ha3RRSlphajFYbkQyOG1xV0toZGYxblZYckpBeFhoQkpXZGp4ODA4b1VZMTRMK25pbW1hWVdlZGdPRFJpWm80MGxwUWlCNmZydzZySVhnZHJ0REpJdUgyeWlQY1pjd3FienoyQUw="
        //        })
        // );

        //sukanta
        /*localStorage.setItem(
      "cw_portfolio_user",
      JSON.stringify({
        "user_id": "24",
        "email_id": "careers.sukanta@gmail.com",
        "full_name": "Sukanta Mishra",
        "picture": "https://lh3.googleusercontent.com/a/ACg8ocJpcM_OTAle8cx9gRwvQpDpvBqpaC1ULLBACI5k3o_Ib-g=s96-c",
        "portfolio_name": "Sukanta Mishra",
        "country": "India",
        "portfolio_form_redirect": "no",
        "token": "RFJ6cGtGQUtWQVBGZzFRa2JHLzA5QzhsdWFLWGZ5RTNCbzZBOGVYZktJV2FVaTBXSmF4cmdhNmlSWFVlZTQ3cUZSUlg5LzBVSlVGRXJ5OHFFY05DcklaQXlRSWp4NGM5RU9ZckVtL1pwY0Q4UnpPRThwWFduSithU3UrRUFKd1JZV1UwaEszMjcrSlZDRkZlV3JPU3J2VCtUcUZKbG5qNUtmWFRtLzZZRlpzPQ=="
    })
    );*/
        //tapan
        // localStorage.setItem(
        //       "cw_portfolio_user",
        //       JSON.stringify({
        //         "user_id": "13",
        //         "email_id": "tapan.sahoo@zeonai.com",
        //         "full_name": "Tapan Kumar Sahoo",
        //         "picture": "https://lh3.googleusercontent.com/a/ACg8ocJn-13lHN-Lb7qjipCyRuxKx8nEgfmPMmagDmQ3V8Y7=s96-c",
        //         "portfolio_name": "Tapan Kumar Sahoo",
        //         "country": "India",
        //         "portfolio_form_redirect": "no",
        //         "token": "WG8yb3kxUEVYOCszSlk5TVpFeURkZ3ZVQVpUbW1heXd4KzBHbldVYjdiR3ZTMVQyNmZXVzVpRjZCNHM3MC9uR0xmSDVOcmh4eHZqTnJobXNiWXJFWTZlVEZRVlpUbWxNb3ZDU2J2Tk4yZHpvd2UwbURCOVNER2VFRG9JTmVTZTBkaEtXMldzTDA2UEtaT1poWUtOQUx3PT0="
        //     })
        //     );

        const userData = localStorage.getItem("cw_portfolio_user");
        setUserdata(userData);
        setCheckUserData(true);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <HelmetProvider>
                {" "}
                <MetaProperty/>
                {/* Wrap the entire Router with HelmetProvider */}
                {!checkUserData && (
                    <>
                        <Box
                            sx={{display: "flex"}}
                            style={{
                                alignItem: "center",
                                justifyContent: "center",
                                marginTop: "300px",
                            }}
                        >
                            <CircularProgress disableShrink />
                        </Box>
                    </>
                )}
                {checkUserData && (
                    <>
                        <div className="App">
                            <Router>
                                <PathProvider>
                                    <Routes>
                                        <Route path="/home" element={<Welcome />} />
                                        <Route path="/" element={<Welcome />} />
                                        <Route path="/roadmap" element={<Roadmap />} />
                                        <Route path="/signUp" element={<Home />} />
                                        {userdata && (
                                            <>
                                                <Route path="/crypto-screener" element={<CryptocurrencyList />} />
                                                {/* <Route path="/my-portfolio" element={<Portfolio />} /> */}

                                                {/* <Route path="/portfolio" element={<PortfolioShare />} /> */}
                                                <Route path="/portfolio/:portfolioName" element={<PortfolioShare />} />
                                                <Route path="/crypto/:crypto" element={<CryptoPage />} />

                                                <Route path="/alerts" element={<CryptoAlerts />} />

                                                <Route
                                                    path="/crypto/:crypto/portfolios"
                                                    element={<CryptoPortfolio />}
                                                />
                                                <Route path="/whitepaper" element={<Whitepaper />} />

                                                <Route
                                                    path="/my-portfolio/:portfolioId/:portfolioName/order-book"
                                                    element={<OrderBook />}
                                                />

                                                <Route
                                                    path="/crypto-influencer-rankings"
                                                    element={<CryptoInfluencerRankings />}
                                                />
                                                <Route
                                                    path="/crypto-influencer-rankings/predictions"
                                                    element={<OverallInfluencerRankings />}
                                                />
                                                <Route path="/indices" element={<PortfolioNetworthGraph />} />

                                                <Route
                                                    path="/indices/:indexName"
                                                    element={<NetworthGraphEachIndex />}
                                                />
                                                <Route
                                                    path="/category/:categoryName"
                                                    element={<CategoryPortfolioHome />}
                                                />
                                                <Route
                                                    path="/category/:categoryName/influencers"
                                                    element={<CategoryBasedInfluencers />}
                                                />
                                                <Route path="/subscriptions" element={<Pricing />} />
                                                <Route
                                                    path="/indices-email-template"
                                                    element={<EmailTemplateDesign />}
                                                />

                                                <Route
                                                    path="/subscriptions/screener360"
                                                    element={<Screener360_Pricing />}
                                                />
                                                <Route
                                                    path="/portfolio/:portfolioName/order-book"
                                                    element={<OrderBookShare />}
                                                />

                                                <Route
                                                    path="/influencer/:influencerName"
                                                    element={<InfluencerAccount />}
                                                />

                                                <Route path="/whitepaper" element={<Whitepaper />} />

                                                <Route
                                                    path="/influencer/:portfolioName/portfolios"
                                                    element={<PortfolioList />}
                                                />

                                                <Route
                                                    path="/influencer/:portfolioName/predictions"
                                                    element={<InfluencerPredictions />}
                                                />

                                                <Route
                                                    path="/portfolio/:portfolioName/recommendations"
                                                    element={<Recommendations />}
                                                />

                                                <Route
                                                    path="/my-portfolio/:portfolioId/:portfolioName/recommendations"
                                                    element={<Recommendations />}
                                                />

                                                <Route
                                                    path="/my-portfolio/:portfolioId/:portfolioName/detailed-portfolio-analysis"
                                                    element={<PerformanceSummary />}
                                                />

                                                <Route path="/predict-now" element={<CryptoForecastForm />} />
                                                <Route path="/predict-now/login" element={<Login />} />

                                                <Route path="/airdrop-form" element={<AirdropForm />} />
                                                <Route path="/airdrop-form/login" element={<Login />} />

                                                <Route path="/portfolio/:portfolioName/login" element={<Login />} />

                                                <Route
                                                    path="/portfolio/:portfolioName/detailed-portfolio-analysis"
                                                    element={<PerformanceSummaryShare />}
                                                />

                                                <Route path="/account" element={<Account />} />
                                                <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                                                <Route path="/termofuse" element={<TermOfUse />} />
                                                <Route path="/contactus" element={<About />} />
                                                <Route path="/about-us" element={<Aboutus />} />
                                                <Route path="/portfolioadd" element={<Portfolioadd />} />
                                                <Route path="/profile" element={<Profile />} />
                                                <Route path="/portfoliofaqs" element={<PortfolioFaqs />} />
                                                <Route
                                                    path="/my-portfolio/:portfolioId/:portfolioName"
                                                    element={<Portfolio />}
                                                />

                                                <Route path="/my-portfolio" element={<MultiPortfolio />} />
                                                <Route path="/audit-my-portfolio" element={<NewUserAudit />} />

                                                <Route path="/my-watchlist" element={<MultiPortfolioWatchlist />} />

                                                <Route path="/portfolio-gallery" element={<Home />} />

                                                <Route
                                                    path="/crypto/:crypto/predictions"
                                                    element={<CryptoPagePredictions />}
                                                />
                                            </>
                                        )}
                                        {!userdata && (
                                            <>
                                                {isBot ? (
                                                    <>
                                                        <Route
                                                            path="/portfolio/:portfolioName"
                                                            element={<PortfolioShare />}
                                                        />
                                                        <Route
                                                            path="/crypto-screener"
                                                            element={<CryptocurrencyList />}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Route
                                                            path="/crypto-screener"
                                                            element={<CryptocurrencyList />}
                                                        />
                                                        <Route
                                                            path="/portfolio/:portfolioName"
                                                            element={<PortfolioShare />}
                                                        />
                                                    </>
                                                )}
                                                <Route path="/crypto-screener" element={<CryptocurrencyList />} />
                                                <Route path="/my-portfolio" element={<MultiPortfolio />} />
                                                <Route path="/my-watchlist" element={<Login />} />
                                                <Route path="/account" element={<Login />} />
                                                <Route
                                                    path="/category/:categoryName"
                                                    element={<CategoryPortfolioHome />}
                                                />
                                                <Route path="/whitepaper" element={<Whitepaper />} />
                                                <Route
                                                    path="/indices-email-template"
                                                    element={<EmailTemplateDesign />}
                                                />
                                                <Route path="/portfolio/:portfolioName" element={<PortfolioShare />} />
                                                <Route path="/indices" element={<PortfolioNetworthGraph />} />

                                                <Route
                                                    path="/indices/:indexName"
                                                    element={<NetworthGraphEachIndex />}
                                                />
                                                <Route path="/alerts" element={<CryptoAlerts />} />
                                                <Route path="/subscriptions" element={<Pricing />} />
                                                <Route path="/audit-my-portfolio" element={<NewUserAudit />} />
                                                <Route
                                                    path="/subscriptions/screener360"
                                                    element={<Screener360_Pricing />}
                                                />
                                                <Route
                                                    path="/category/:categoryName/influencers"
                                                    element={<CategoryBasedInfluencers />}
                                                />
                                                <Route path="/crypto/:crypto" element={<CryptoPage />} />
                                                <Route
                                                    path="/crypto/:crypto/predictions"
                                                    element={<CryptoPagePredictions />}
                                                />
                                                <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                                                <Route path="/termofuse" element={<TermOfUse />} />
                                                <Route path="/contactus" element={<About />} />
                                                <Route path="/about-us" element={<Aboutus />} />
                                                <Route path="/portfolioadd" element={<Login />} />
                                                <Route path="/profile" element={<Login />} />
                                                <Route
                                                    path="/my-portfolio/:portfolioId/:portfolioName/order-book"
                                                    element={<Login />}
                                                />
                                                <Route path="/whitepaper" element={<Whitepaper />} />

                                                <Route
                                                    path="/portfolio/:portfolioName/recommendations"
                                                    element={<Recommendations />}
                                                />

                                                <Route
                                                    path="/my-portfolio/:portfolioId/:portfolioName/recommendations"
                                                    element={<Login />}
                                                />

                                                <Route
                                                    path="/crypto-influencer-rankings"
                                                    element={<CryptoInfluencerRankings />}
                                                />
                                                <Route
                                                    path="/crypto-influencer-rankings/predictions"
                                                    element={<OverallInfluencerRankings />}
                                                />

                                                <Route
                                                    path="/portfolio/:portfolioName/order-book"
                                                    element={<OrderBookShare />}
                                                />
                                                <Route
                                                    path="/influencer/:influencerName"
                                                    element={<InfluencerAccount />}
                                                />

                                                <Route path="/predict-now" element={<CryptoForecastForm />} />
                                                <Route path="/predict-now/login" element={<Login />} />

                                                <Route path="/airdrop-form" element={<AirdropForm />} />
                                                <Route path="/airdrop-form/login" element={<Login />} />
                                                <Route path="/portfolio/:portfolioName/login" element={<Login />} />

                                                <Route
                                                    path="/influencer/:portfolioName/portfolios"
                                                    element={<PortfolioList />}
                                                />
                                                <Route
                                                    path="/influencer/:portfolioName/predictions"
                                                    element={<InfluencerPredictions />}
                                                />

                                                <Route
                                                    path="/crypto/:crypto/portfolios"
                                                    element={<CryptoPortfolio />}
                                                />
                                                <Route
                                                    path="/my-portfolio/:portfolioId/:portfolioName/detailed-portfolio-analysis"
                                                    element={<Login />}
                                                />

                                                <Route
                                                    path="/portfolio/:portfolioName/detailed-portfolio-analysis"
                                                    element={<PerformanceSummaryShare />}
                                                />
                                                <Route path="/portfoliofaqs" element={<PortfolioFaqs />} />
                                                <Route path="/portfolio-gallery" element={<Home />} />
                                                <Route path="/presale" element={<Presale />} />
                                            </>
                                        )}

                                        <Route path="/login" element={<Home showPopup={true} />} />
                                        <Route path="*" element={<Error />} />
                                    </Routes>
                                </PathProvider>
                            </Router>
                        </div>
                    </>
                )}
            </HelmetProvider>
        </ThemeProvider>
    );
}

export default App;
