import React from 'react';
import { useNavigate } from 'react-router-dom';
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, matchPath } from "react-router-dom";


const LoginPopUp = ({ onClose, signupUrl, onSignUp }) => {

const [isReferrer, setIsReferrer] = useState(false);
const location = useLocation();


    const mediaQueryVar = useMediaQuery(
        json2mq({
          minWidth: 900,
        })
      );

    const navigate = useNavigate();

    const handleSignUpNow = () => {
      if (onSignUp) {
        onSignUp();
      } else if (signupUrl) {
        navigate(signupUrl);
      } else {
        navigate('/login');
      }
    };

    const handleContinueWithGoogle = () => {
      
      fetch(process.env.REACT_APP_API_URL + "/initiategooglelogin")
    .then((res) => res.json())
    .then((res) => {
      //console.log(res);
      window.location.replace(res.google_url);
    });
    };

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const referrerId = urlParams.get('ref');
      console.log("Loginpopup Ref ID: ",referrerId);
      
      if (referrerId) {
        setIsReferrer(true); // Set to true if referral ID is found
      }
    }, []);
    const loginSource = sessionStorage.getItem("loginSource");
    const getText = () => {
      
      if (loginSource) {
        sessionStorage.removeItem("loginSource");
      }
      if (loginSource === "addToPortfolio") {
        return "Add Your Portfolio for Best Buy-Sell Recommendations";
      }
      if (location.pathname === "/predict-now") {
        return "Predict accurately and win $wisecoins worth $5";
      }
      if (location.pathname === "/my-portfolio" || location.pathname === "/audit-my-portfolio") {
        return "Add Your Portfolio for Best Buy-Sell Recommendations";
      }
      if (matchPath("/crypto/:crypto", location.pathname) || location.pathname === "/crypto-screener") {
        return "Premium Signals @ $1 per month";
      }
      return "Sign up and Get $wisecoins worth $10";
    };

    const shouldDisplayImage =
  loginSource === "addToPortfolio" ||
  location.pathname === "/my-portfolio" ||
  location.pathname === "/audit-my-portfolio";
  
  
    const styles = {
        overlay: {
            position: 'fixed',
            top: mediaQueryVar===true ? 0 : 'auto',
            bottom: mediaQueryVar===true ? 'auto' : '30%',
            left: 0,
            width: '100%',
            height: mediaQueryVar===true ? '100%' : 'auto',
            backgroundColor:mediaQueryVar===true ?  'rgba(0, 0, 0, 0.8)':'rgba(0, 0, 0, 0.0)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: mediaQueryVar===true ? 'center' : 'flex-end',
            zIndex: 1001,
            paddingBottom: 0,
        },
        popupContainer: {
          width:  mediaQueryVar===false? '75%': '480px',
          padding: mediaQueryVar===false? '0px': '10px',
          background: 'linear-gradient(90deg, #9AEB37 0%, #FFF73F 100%)',
          borderRadius: mediaQueryVar===false? '24px': '24px',
          borderTopLeftRadius : mediaQueryVar ===true?'24px':'24px',
          borderTopRightRadius : mediaQueryVar ===true?'24px':'24px',
          textAlign: 'center',
          paddingTop:'0px',
          paddingBottom: mediaQueryVar===false? '10px': '',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        },
        popupContent: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        image: {
          width: mediaQueryVar===false? '145px': '160px',
          height:'145px',
          marginTop: mediaQueryVar===false? '-45px': '-30px',
          marginBottom: '10px',
          borderRadius:'50%'
        },
        heading: {
          fontSize: mediaQueryVar===false? '15px': '18px',
          fontWeight: 'bold',
          color: '#000',
        },
        text: {
          fontSize: mediaQueryVar===false? '17px': '20px',
          color: '#1877F2',
          marginBottom: mediaQueryVar===false?'0px':'8px',
          lineHeight:'24px'
        },
        boldText: {
          fontSize:  mediaQueryVar===false? '16px': '18px',
          fontWeight: 'bold',
          color:'black'
        },
        signupButton: {
          width:'80%',
          backgroundColor: '#1877F2',
          color: '#fff',
          padding: mediaQueryVar===true?'9px 20px':'3px 20px',
          borderRadius: '1000px',
          border: 'none',
          fontSize: '16px',
          cursor: 'pointer',
          marginBottom:'2px',
          marginTop:'8px'
        },
        laterButton: {
          display: mediaQueryVar===false? 'none': 'block',
          backgroundColor: 'transparent',
          color: '#1877F2',
          padding: '5px',
          border: 'none',
          fontSize: '17px',
          cursor: 'pointer',
         
        },
      };

   
  return (
    <div style={styles.overlay}>
      <div style={styles.popupContainer}>
      {mediaQueryVar === false &&(
      <div style={{ display: 'flex', justifyContent: 'center' }}>
   <button style={{
    width: '48px',
    height: '4px',
    borderRadius: '100px',
    background: '#E2E2E2',
    border: 'none',
    margin: '5px',

  }}></button></div>
  
  
  )}
     

<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
  <img
    className="frameIcon"
    alt=""
    onClick={onClose}
    src={`${window.constants.asset_path}/images/frame17.svg`}
    style={{
      background: "#F3F3F3",
      width: "30px",
      height: "30px",
      marginTop:"10px",
      marginRight:"10px"
     
    }}
  />
</div>
        <div style={styles.popupContent}>
        {!shouldDisplayImage && (
      <img
        src={`${window.constants.asset_path}/images/login.png`}
        alt="Wise coins logo"
        style={styles.image}
      />
    )}

       
          <p style={styles.text}><strong>{getText()}</strong><br></br></p>
        
        
        
      <button 
        style={{
          width: '80%',
          padding: mediaQueryVar ? '4px 20px' : '3px 10px',
          borderRadius: '1000px',
          border: '1px solid black',
          fontSize: '16px',
          cursor: 'pointer',
         
          marginTop:  '15px',
          backgroundColor: "white",
          color: "black",
          marginBottom: mediaQueryVar === false ? '10px' : '30px',
          
        }} 
        onClick={() => {
          handleContinueWithGoogle();
        }}
        
      >
        <img 
          src={`${window.constants.asset_path}/images/google.svg`} 
          alt="google logo" 
          style={{
            width: "23px",
            backgroundColor: "white",
            color: "black",
            marginRight: "10px"
          }} 
        />
        Continue With Google
      </button>

   
    <div style={{display:"flex",flexDirection:"row",gap:"20px"}}>
    
    <a href="https://investing.crowdwisdom.live/termofuse">Terms of Use</a>
    <a href="https://investing.crowdwisdom.live/privacyPolicy">Privacy Policy</a>

    </div>

   


   
        </div>
      </div>
    </div>
  );
};



export default LoginPopUp;
