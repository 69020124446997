import Slider from "@mui/material/Slider";
import React from "react";

const RHSlider = ({className=''}) => {
    return (
        <div className={className}>
            <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
        </div>
    );
};

export default RHSlider;
