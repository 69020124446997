// import {BorderBottom} from "@mui/icons-material";
import React from "react";
import {useState, useEffect, useRef} from "react";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import {Grid, useStepContext} from "@mui/material";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import {Tabs, Tab, Button} from "@mui/material";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
// import {ButtonGroup, BottomNavigation, Paper, Chip} from "@mui/material";
// import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {useLocation} from "react-router-dom";
import LoginPopUp from "./loginpopup";

const drawerWidth = 292;

// function SubscriptionTab({title, price, categories, onSelectAmount, price_id}) {
//     const amount = parseFloat(price.replace("$", "")) * 100;
//     return (
//         <Box
//             sx={{
//                 padding: 2,
//                 textAlign: "center",
//                 borderRadius: 3, // Rounded corners for the card
//                 border: "1px solid #ccc", // Border around the card
//                 boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Light shadow for a lifted effect
//                 overflow: "hidden",
//                 backgroundColor: "#fff",
//                 margin: "auto",
//                 marginLeft: "8px",
//             }}
//         >
//             {/* Title and Price section with gradient background */}
//             <Typography
//                 variant="h6"
//                 component="div"
//                 style={{
//                     fontFamily: "Satoshi, sans-serif",
//                     fontWeight: 500,
//                     fontSize: "26px",
//                     margin: "5px",
//                 }}
//             >
//                 <span
//                     style={{
//                         backgroundColor: "#1877f2",
//                         padding: "6px 8px",
//                         borderRadius: "5px",
//                         color: "white",
//                         marginLeft: "3px",
//                     }}
//                 >
//                     {" "}
//                     {title}
//                 </span>
//             </Typography>

//             <Typography
//                 variant="subtitle1"
//                 style={{
//                     fontSize: "26px",
//                     fontWeight: "bold",
//                 }}
//             >
//                 {price}
//                 <span
//                     style={{
//                         fontSize: "18px",
//                         color: "grey",
//                     }}
//                 >
//                     {price === "$1" ? " /per month, Paid Annually" : " /year"}
//                 </span>
//             </Typography>

//             <Button
//                 onClick={() => onSelectAmount(price_id)}
//                 sx={{
//                     marginTop: 3,
//                     width: "65%",
//                     borderRadius: "25px",
//                     backgroundColor: "#1877F2",
//                     color: "#fff",
//                     fontFamily: "Satoshi, sans-serif",
//                     fontWeight: "bold",
//                     textTransform: "none",
//                     fontSize: "19px",
//                     ":hover": {
//                         backgroundColor: "#0A1D37", // Darker shade on hover
//                     },
//                 }}
//             >
//                 Select Plan
//             </Button>

//             <Box sx={{marginTop: 2, textAlign: "left"}}>
//                 {categories.map((category, catIndex) => (
//                     <Box key={catIndex} sx={{marginBottom: 2}}>
//                         <Typography variant="h6" fontWeight="bold" fontSize="18px" color="black">
//                             {category.categoryTitle}
//                         </Typography>
//                         {category.features.map((feature, featIndex) => (
//                             <Typography key={featIndex} sx={{marginBottom: 1}}>
//                                 <span style={{fontSize: "18px", marginRight: "5px"}}>
//                                     <img
//                                         src="/images/green-tick.svg"
//                                         alt="Green Tick"
//                                         style={{width: "18px", height: "18px"}}
//                                     />
//                                 </span>
//                                 {feature}
//                             </Typography>
//                         ))}
//                     </Box>
//                 ))}
//             </Box>

//             {/* Subscribe Button */}
//             <Button
//                 onClick={() => onSelectAmount(price_id)}
//                 variant="contained"
//                 sx={{
//                     marginTop: 3,
//                     width: "70%",
//                     borderRadius: "25px",
//                     backgroundColor: "#1877F2",
//                     color: "#fff",
//                     fontFamily: "Satoshi, sans-serif",
//                     fontWeight: "bold",
//                     textTransform: "none",
//                     fontSize: "19px",
//                     ":hover": {
//                         backgroundColor: "#0A1D37", // Darker shade on hover
//                     },
//                 }}
//             >
//                 Select Plan
//             </Button>
//         </Box>
//     );
// }

const Pricing = (props) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const {window1} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const loc = location.pathname;
    const container = window1 !== undefined ? () => window1().document.body : undefined;
    const [selectedValue, setSelectedValue] = useState("");

    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );
    // const mediaQueryVar2 = useMediaQuery(
    //     json2mq({
    //         maxWidth: 410,
    //     })
    // );

    // const mediaQueryVar3 = useMediaQuery(
    //     json2mq({
    //         maxWidth: 415,
    //     })
    // );
    const handleModalClose = () => {
        // Close the modal and reset the URL to the welcome page
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };

    // const styles = {
    //     container: {
    //         fontFamily: "Arial, sans-serif",
    //         width: "100%",
    //         margin: mediaQueryVar ? "auto" : "0px",
    //         backgroundColor: "#f7f9fc",
    //         padding: "20px",
    //         borderRadius: "10px",
    //         boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    //         maxWidth: mediaQueryVar ? "850px" : "100%",
    //         flex: "1",
    //         marginLeft: mediaQueryVar ? "30%" : "",
    //         marginTop: "2%",
    //         paddingBottom: "1.5%",
    //         marginRight: mediaQueryVar ? "" : "",
    //     },
    //     header: {
    //         background: "linear-gradient(90deg, rgba(24, 119, 242, 0.17) 0.02%, rgba(24, 119, 242, 0.00) 123.27%)",
    //         padding: "15px",
    //         borderRadius: "0px 14px 0px 0px",
    //         marginBottom: "20px",
    //         textAlign: "left",
    //         borderBottom: "1px solid #1877F2",
    //     },
    //     headerText: {
    //         margin: 0,
    //         color: "#000",
    //         fontWeight: "600",
    //         fontSize: mediaQueryVar ? "20px" : "18px",
    //         marginLeft: mediaQueryVar ? "2%" : "0px",
    //         fontFamily: "'Satoshi', sans-serif",
    //     },
    //     gridContainer: {
    //         display: "grid",
    //         gridTemplateColumns: "1.5fr 1fr 1fr",
    //         gap: "20px",
    //         alignItems: "start",
    //     },
    //     featureList: {
    //         paddingRight: mediaQueryVar ? "20px" : "-20px",
    //         borderRight: mediaQueryVar ? "1px solid #D1D1D1" : "",
    //         height: mediaQueryVar ? "70.8vh" : "",
    //         width: mediaQueryVar ? "24vw" : mediaQueryVar3 ? "45vw" : "35vw",
    //         marginRight: mediaQueryVar ? "" : mediaQueryVar3 ? "-40px" : "",
    //     },
    //     featureTitle: {
    //         fontSize: mediaQueryVar ? "24px" : "14px",
    //         marginTop: mediaQueryVar ? "9%" : "17%",
    //         fontWeight: "600",
    //         marginLeft: "9%",
    //     },
    //     features: {
    //         listStyleType: "none",
    //         paddingLeft: "0",
    //         fontSize: "16px",
    //         lineHeight: "1.8em",
    //         marginLeft: "9%",
    //         marginRight: "5%",
    //     },
    //     plan1: {
    //         background: "linear-gradient(180deg, #EBF3FF 0%, #FFF 100%)",
    //         border: "1px solid #B7D6FF",
    //         borderRadius: "16px",
    //         padding: mediaQueryVar ? "20px" : "20px 20px 10px 20px",
    //         textAlign: "center",
    //         width: "100%",
    //         minWidth: mediaQueryVar ? "" : "80px",
    //         maxWidth: mediaQueryVar ? "160px" : "",
    //         marginLeft: mediaQueryVar ? "4vw" : "",
    //         borderBottom: "1px solid #B7D6FF",
    //     },
    //     plan2: {
    //         background: "linear-gradient(180deg, #EBF3FF 0%, #FFF 100%)",
    //         border: "1px solid #B7D6FF",
    //         borderRadius: "16px",
    //         padding: mediaQueryVar ? "20px" : "20px 20px 10px 20px",
    //         textAlign: "center",
    //         width: "100%",
    //         minWidth: mediaQueryVar ? "" : "80px",
    //         maxWidth: mediaQueryVar ? "160px" : "",
    //         marginLeft: mediaQueryVar ? "1vw" : "0.1vw",
    //     },
    //     priceText: {
    //         fontSize: mediaQueryVar ? "24px" : "15.29px",
    //         fontWeight: "600",
    //         margin: "10px 0",
    //         marginBottom: "20%",
    //     },
    //     planFeatures: {
    //         listStyleType: "none",
    //         paddingLeft: "0",
    //         fontSize: "20px",
    //         lineHeight: "2.2em",
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //     },
    //     subscribeButton: {
    //         backgroundColor: "#1877F2",
    //         color: "#fff",
    //         padding: mediaQueryVar ? "10px 24px 10px 18px" : "4.572px 10.973px",
    //         border: "none",
    //         borderRadius: "40px",
    //         cursor: "pointer",
    //         marginTop: "20px",
    //         width: mediaQueryVar ? "8.5vw" : "20vw",
    //         marginLeft: mediaQueryVar ? "-6px" : mediaQueryVar2 ? "-17px" : "-15px",
    //         fontSize: mediaQueryVar ? "20px" : "9.144px",
    //         fontWeight: "600",
    //         fontFamily: "'Satoshi', sans-serif",
    //     },
    // };

    const handleCheckout = async (price_id, email) => {
        console.log("Price Id: ", price_id, email);
        try {
            const response = await fetch("https://investing.crowdwisdom.live/create-checkout-session", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({price_id, email}), // Send the price_id in the request body
            });

            const session = await response.json();

            if (session.error) {
                console.error("Server Error: ", session.error); // Log server error
                alert(`Error: ${session.error}`);
                return;
            }

            if (session.url) {
                window.location.href = session.url; // Redirect to Stripe Checkout
            }
        } catch (error) {
            console.error("Error during checkout: ", error);
        }
    };
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

    const onSelectAmount = (price_id) => {
        const userData = localStorage.getItem("cw_portfolio_user");
        if (!userData) {
            const originalUrl = "/subscriptions";
            sessionStorage.setItem("originalUrl", originalUrl);
            window.history.pushState({}, "", "/login");
            localStorage.setItem("isPopupManuallyOpened", "true");
            setSelectedValue("");
            // Open the login modal
            setLoginModalOpen(true);

            return;
        } else {
            const userData_full = JSON.parse(userData);
            setSelectedValue(price_id);
            // Access the email_id
            const emailId = userData_full.email_id;

            console.log("USER DATA:", emailId);
            handleCheckout(price_id, emailId); // Call handleCheckout with the selected amount
        }
    };
    const drawer = (
        <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
            <SidebarMenuList />
        </div>
    );

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [selectedPlan, setSelectedPlan] = useState("Index Full Pack");

    const planContainerStyleDesktop = {
        display: "flex",
        justifyContent: "center",
        gap: "20px",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "15px",
        color: "#000",
        width: "100%",
        fontFamily: "Satoshi, sans-serif",
        
    };
    const planContainerStyleMobile = {
        display: "flex",
        justifyContent: "center",
        gap: "20px",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "15px",
        color: "#000",
        maxWidth: "1000px",
        fontFamily: "Satoshi, sans-serif",
    };
    const cardStyle = (plan) => ({
        width: "100%",
        padding: "15px",
        borderRadius: "15px",
        textAlign: "center",
        cursor: "pointer",
        border: selectedPlan === plan ? "2px solid #ffb400" : "2px solid #1877F2",
        backgroundColor: selectedPlan === plan ? "#fff" : "#fff",
        boxShadow: selectedPlan === plan ? "0px 0px 20px rgba(255, 180, 0, 0.5)" : "none",
        transition: "all 0.3s ease",
    });

    // const buttonStyle = {
    //     marginTop: "15px",
    //     marginBottom: "10px",
    //     padding: "8px 25px",
    //     borderRadius: "25px",
    //     backgroundColor: "#1877F2",
    //     color: "#fff",
    //     border: "none",
    //     fontWeight: "bold",
    //     cursor: "pointer",
    //     fontSize: "15px",
    //     transition: "background-color 0.3s ease",
    // };

    const selectPlan = (plan) => {
        setSelectedPlan(plan);
    };

    // const [selectedTab, setSelectedTab] = useState(0);

    const plans = [
        {
            title: "Index360",
            price: "$99",
            price_id: "price_1QMizYKAXtGoEI6m6DBks3qh",
            categories: [
                {
                    categoryTitle: "Access to 10 Indices",
                    features: [
                        "Regular updates on changes in composition and strategy",
                        "Comparisons with benchmarks",
                        "Weekly Newsletter for the latest market insights",
                    ],
                },
                {
                    categoryTitle: "Portfolio Access",
                    features: [
                        "Complete access to portfolio testing and benchmarking",
                        "Free unlimited notifications for portfolio updates",
                        "Unlimited portfolio views",
                        "Personalized portfolio recommendations",
                        "Unlimited Crypto Notifications",
                        "Top 10 Portfolios Access",
                        "Free Own Portfolio Audit and Personalized Recommendations",
                        "Portfolio analysis, including identifying weak cryptos",
                        "Order book with decision efficiency tracking",
                        "Comparisons with other portfolios for strategic insights",
                    ],
                },
                {
                    categoryTitle: "Crypto Screener and Price Predictions",
                    features: [
                        "Access to 20 crypto signals for technical analysis and trend identification",
                        "Free unlimited notifications for crypto updates",
                        "Unlimited access to crypto influencer predictions, including accuracy and rankings",
                        "Influencer performance tracking for informed decision-making",
                    ],
                },

                {
                    categoryTitle: "Priority Chat Support",
                    features: [],
                },
                {
                    categoryTitle: "9000 Wisecoins worth $50 (Q2 listing, redeemable before listing)",

                    features: [],
                },
            ],
        },
        {
            title: "Index360",
            price: "$50",
            price_id: "price_1QjHi6KAXtGoEI6mxCqIGant",
            categories: [
                {
                    categoryTitle: "Access to 10 Indices",
                    features: [
                        "Regular updates on changes in composition and strategy",
                        "Comparisons with benchmarks",
                        "Weekly Newsletter for the latest market insights",
                    ],
                },
                {
                    categoryTitle: "Portfolio Access",
                    features: [
                        "Complete access to portfolio testing and benchmarking",
                        "Free unlimited notifications for portfolio updates",
                        "Unlimited portfolio views",
                        "Personalized portfolio recommendations",
                        "Unlimited Crypto Notifications",
                        "Top 10 Portfolios Access",
                        "Free Own Portfolio Audit and Personalized Recommendations",
                        "Portfolio analysis, including identifying weak cryptos",
                        "Order book with decision efficiency tracking",
                        "Comparisons with other portfolios for strategic insights",
                    ],
                },
                {
                    categoryTitle: "Crypto Screener and Price Predictions",
                    features: [
                        "Access to 20 crypto signals for technical analysis and trend identification",
                        "Free unlimited notifications for crypto updates",
                        "Unlimited access to crypto influencer predictions, including accuracy and rankings",
                        "Influencer performance tracking for informed decision-making",
                    ],
                },

                {
                    categoryTitle: "Priority Chat Support",
                    features: [],
                },
                {
                    categoryTitle: "9000 Wisecoins worth $50 (Q2 listing, redeemable before listing)",

                    features: [],
                },
            ],
        },
        {
            title: "Index360",
            price: "$20",
            price_id: "price_1QjHjrKAXtGoEI6mUTrYpW94",
            categories: [
                {
                    categoryTitle: "Access to 10 Indices",
                    features: [
                        "Regular updates on changes in composition and strategy",
                        "Comparisons with benchmarks",
                        "Weekly Newsletter for the latest market insights",
                    ],
                },
                {
                    categoryTitle: "Portfolio Access",
                    features: [
                        "Complete access to portfolio testing and benchmarking",
                        "Free unlimited notifications for portfolio updates",
                        "Unlimited portfolio views",
                        "Personalized portfolio recommendations",
                        "Unlimited Crypto Notifications",
                        "Top 10 Portfolios Access",
                        "Free Own Portfolio Audit and Personalized Recommendations",
                        "Portfolio analysis, including identifying weak cryptos",
                        "Order book with decision efficiency tracking",
                        "Comparisons with other portfolios for strategic insights",
                    ],
                },
                {
                    categoryTitle: "Crypto Screener and Price Predictions",
                    features: [
                        "Access to 20 crypto signals for technical analysis and trend identification",
                        "Free unlimited notifications for crypto updates",
                        "Unlimited access to crypto influencer predictions, including accuracy and rankings",
                        "Influencer performance tracking for informed decision-making",
                    ],
                },

                {
                    categoryTitle: "Priority Chat Support",
                    features: [],
                },
                {
                    categoryTitle: "9000 Wisecoins worth $50 (Q2 listing, redeemable before listing)",

                    features: [],
                },
            ],
        },
        
    ];

    // const handleTabChange = (event, newValue) => {
    //     setSelectedTab(newValue);
    // };

    return (
        <div
            style={{
                backgroundColor: mediaQueryVar ? "#EDF1F6" : "#fff",
                paddingBottom: "15%",
                marginRight: mediaQueryVar ? "" : "-10px",
                display: "flex",
            }}
            className="row"
        >
            {/* <div className="col-sm-12 col-md-4"></div> */}

            <div>
                {mediaQueryVar ? (
                    <div className="layout">
                        <div>
                            <Box
                                component="nav"
                                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                                aria-label="mailbox folders"
                            >
                                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        display: {xs: "block", sm: "none"},
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: drawerWidth,
                                            backgroundColor: "white",
                                            color: "#727376",
                                        },
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                                <Drawer
                                    variant="permanent"
                                    sx={{
                                        display: {xs: "none", sm: "block"},
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: drawerWidth,
                                            backgroundColor: "white",
                                            color: "#727376",
                                        },
                                    }}
                                    open
                                >
                                    {drawer}
                                </Drawer>
                            </Box>
                        </div>
                        <div className="content" style={{padding: "0px 2%", marginTop: "20px"}}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    background: "linear-gradient(90deg, #9AEB37 0%, #FFF73F 100%)",
                                    paddingLeft: "30px",
                                    borderRadius: "19px",
                                    marginBottom: "20px",
                                }}
                            >
                                <img
                                    src="https://i0.wp.com/crowdwisdom360.com/wp-content/uploads/2024/08/TopAd.png"
                                    alt="Top Portfolios"
                                    style={{
                                        width: "355px",
                                        marginRight: "20px",
                                        minHeight: "150px",
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        paddingRight: "30px",
                                        paddingBottom: "15px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                        }}
                                    >
                                        <h2
                                            style={{
                                                fontSize: "25px",
                                                fontWeight: "660",
                                                color: "#115A46",
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                                padding: "0",
                                                textAlign: "right",
                                            }}
                                        >
                                            <u>Maximize</u> your Crypto <u>Returns</u> with Best Influencer Portfolios
                                            and Personalized Recommendations{" "}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <Helmet>
                                <title>Subscriptions</title>
                            </Helmet>
                            {isLoginModalOpen && (
                                <LoginPopUp
                                    isLoginModalOpen={isLoginModalOpen}
                                    setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                                    onClose={handleModalClose}
                                />
                            )}
                            <Grid item xs={12} align="left">
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between", // Changed from space-between to flex-end
                                        marginTop: "0px",
                                        marginBottom: "10px",
                                        marginLeft: "5px",
                                    }}
                                >
                                    {mediaQueryVar === false ? (
                                        <div
                                            style={{
                                                marginLeft: "10px",
                                                border: "2px solid #1877F2",
                                                borderRadius: "28px",
                                                padding: "2px 4px",
                                                backgroundColor: "#1877F2",
                                                color: "white",
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                width: "202px",
                                                textAlign: "center",
                                                height: "40px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: "black",
                                                    backgroundColor: "white",
                                                    borderRadius: "28px",
                                                    width: "190px",
                                                    padding: "2px 5px",
                                                    fontFamily: "Satoshi, sans-serif",
                                                    height: "33px",
                                                }}
                                            >
                                                Subscriptions
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                border: "2px solid #1877F2",
                                                borderRadius: "28px",
                                                padding: "5px 4px",
                                                backgroundColor: "#1877F2",
                                                color: "white",
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                width: "210px",
                                                textAlign: "center",
                                                marginBottom: "10px",
                                                fontFamily: "Satoshi, sans-serif",
                                                height: "48px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: "black",
                                                    backgroundColor: "white",
                                                    borderRadius: "28px",
                                                    width: "200px",
                                                    padding: "2px 6px",
                                                    height: "35px",
                                                }}
                                            >
                                                Subscriptions
                                            </div>
                                        </div>
                                    )}
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="end"
                                        onClick={handleDrawerToggle}
                                        sx={{display: {sm: "none"}}}
                                    >
                                        <img
                                            src="/images/menubar.svg"
                                            alt="Menu Icon"
                                            style={{
                                                width: "24px",
                                                height: "24px",
                                                marginRight: "10px",
                                                marginTop: "10px",
                                            }}
                                        />
                                    </IconButton>
                                </div>
                            </Grid>
                            <div className>
                                <Box
                                    component="nav"
                                    sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                                    aria-label="mailbox folders"
                                >
                                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                                    <Drawer
                                        container={container}
                                        variant="temporary"
                                        open={mobileOpen}
                                        onClose={handleDrawerToggle}
                                        ModalProps={{
                                            keepMounted: true, // Better open performance on mobile.
                                        }}
                                        sx={{
                                            display: {xs: "block", sm: "none"},
                                            "& .MuiDrawer-paper": {
                                                boxSizing: "border-box",
                                                width: drawerWidth,
                                                backgroundColor: "white",
                                                color: "#727376",
                                            },
                                        }}
                                    >
                                        {drawer}
                                    </Drawer>
                                    <Drawer
                                        variant="permanent"
                                        sx={{
                                            display: {xs: "none", sm: "block"},
                                            "& .MuiDrawer-paper": {
                                                boxSizing: "border-box",
                                                width: drawerWidth,
                                                backgroundColor: "white",
                                                color: "#727376",
                                            },
                                        }}
                                        open
                                    >
                                        {drawer}
                                    </Drawer>
                                </Box>
                            </div>
                            <div style={mediaQueryVar ? planContainerStyleDesktop : planContainerStyleMobile}>
                                {/* Plan 1: Index Full Pack */}
                                <div style={cardStyle("Index Full Pack")} onClick={() => selectPlan("Index Full Pack")}>
                                    <h4
                                        style={{
                                            backgroundColor: "#1877F2",
                                            backgroundClip: "text",
                                            WebkitBackgroundClip: "text",
                                            color: "transparent",
                                            fontSize: "23px",
                                            fontWeight: "bold", // Optional for styling
                                        }}
                                    >
                                        Index360
                                    </h4>
                                    {/* <h2
                            style={{
                                fontWeight: "500",
                                fontSize: "30px",
                            }}
                        >
                            $99
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "grey",
                                }}
                            >
                                {" "}
                                /year
                            </span>
                        </h2> */}
                                    {/* <button onClick={() => onSelectAmount(plans[0].price_id)} style={buttonStyle}>
                            Select Plan
                        </button> */}
                                    <div className=" d-flex justify-content-center my-5">
                                        <select
                                            className="form-control w-50 bg-primary text-white"
                                            value={selectedValue}
                                            onChange={(e) => onSelectAmount(e.target.value)}
                                        >
                                            <option className="bg-white text-dark" value="">
                                                Select Plan
                                            </option>
                                            <option className="bg-white text-dark" value={plans[0]?.price_id}>
                                                $99/ 12 months
                                            </option>
                                            <option className="bg-white text-dark" value={plans[1]?.price_id}>
                                                $50/ 6 months
                                            </option>
                                            <option className="bg-white text-dark" value={plans[2]?.price_id}>
                                                $20/ 2 months
                                            </option>
                                        </select>
                                    </div>
                                    <p style={{textAlign: "left", fontWeight: "bold", fontSize: "15px"}}>
                                        Access to 10 Indices
                                    </p>
                                    <ul
                                        style={{
                                            textAlign: "left",
                                            listStyleType: "none",
                                            paddingLeft: "0",
                                            fontSize: "15px",
                                        }}
                                    >
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Regular updates on changes in Composition and Strategy
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Comparisons with Benchmarks
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Weekly Newsletter for the latest market insights
                                        </li>
                                    </ul>
                                    <p style={{textAlign: "left", fontWeight: "bold", fontSize: "15px"}}>
                                        Portfolio Access
                                    </p>
                                    <ul
                                        style={{
                                            textAlign: "left",
                                            listStyleType: "none",
                                            paddingLeft: "0",
                                            fontSize: "15px",
                                        }}
                                    >
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Complete access to portfolio testing and benchmarking
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Free unlimited notifications for portfolio updates
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Unlimited portfolio views
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Personalized portfolio recommendations
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Unlimited Crypto Notifications
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Top 10 Portfolios Access
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Free Own Portfolio Audit and Personalized Recommendations
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Portfolio analysis,including identifying weak cryptos
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Comparisons with other portfolios for strategic insights
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Order book with decision efficiency tracking
                                        </li>
                                    </ul>
                                    <p style={{textAlign: "left", fontWeight: "bold", fontSize: "15px"}}>
                                        Crypto Screener and Price Predictions
                                    </p>
                                    <ul
                                        style={{
                                            textAlign: "left",
                                            listStyleType: "none",
                                            paddingLeft: "0",
                                            fontSize: "15px",
                                        }}
                                    >
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Access to 20 crypto signals for technical analysis and trend identification
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Free unlimited notifications for crypto updates
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Unlimited access to crypto influencer predictions, including accuracy and
                                            rankings
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Influencer performance tracking for informed decision-making
                                        </li>
                                    </ul>
                                    <p style={{textAlign: "left"}}>
                                        <strong>Priority Chat Support</strong>
                                    </p>
                                    <p style={{textAlign: "left"}}>
                                        <strong>9000 Wisecoins worth $50</strong> (redeemable before listing)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : <div className="layout">
                        <div>
                            <Box
                                component="nav"
                                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                                aria-label="mailbox folders"
                            >
                                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        display: {xs: "block", sm: "none"},
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: drawerWidth,
                                            backgroundColor: "white",
                                            color: "#727376",
                                        },
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                                <Drawer
                                    variant="permanent"
                                    sx={{
                                        display: {xs: "none", sm: "block"},
                                        "& .MuiDrawer-paper": {
                                            boxSizing: "border-box",
                                            width: drawerWidth,
                                            backgroundColor: "white",
                                            color: "#727376",
                                        },
                                    }}
                                    open
                                >
                                    {drawer}
                                </Drawer>
                            </Box>
                        </div>
                        <div className="content">
                            
                            <Helmet>
                                <title>Subscriptions</title>
                            </Helmet>
                            {isLoginModalOpen && (
                                <LoginPopUp
                                    isLoginModalOpen={isLoginModalOpen}
                                    setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                                    onClose={handleModalClose}
                                />
                            )}
                            <Grid item xs={12} align="left">
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between", // Changed from space-between to flex-end
                                        marginTop: "0px",
                                        marginBottom: "10px",
                                        marginLeft: "5px",
                                    }}
                                >
                                    {mediaQueryVar === false ? (
                                        <div
                                            style={{
                                                marginLeft: "10px",
                                                border: "2px solid #1877F2",
                                                borderRadius: "28px",
                                                padding: "2px 4px",
                                                backgroundColor: "#1877F2",
                                                color: "white",
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                width: "202px",
                                                textAlign: "center",
                                                height: "40px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: "black",
                                                    backgroundColor: "white",
                                                    borderRadius: "28px",
                                                    width: "190px",
                                                    padding: "2px 5px",
                                                    fontFamily: "Satoshi, sans-serif",
                                                    height: "33px",
                                                }}
                                            >
                                                Subscriptions
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                border: "2px solid #1877F2",
                                                borderRadius: "28px",
                                                padding: "5px 4px",
                                                backgroundColor: "#1877F2",
                                                color: "white",
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                width: "210px",
                                                textAlign: "center",
                                                marginBottom: "10px",
                                                fontFamily: "Satoshi, sans-serif",
                                                height: "48px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: "black",
                                                    backgroundColor: "white",
                                                    borderRadius: "28px",
                                                    width: "200px",
                                                    padding: "2px 6px",
                                                    height: "35px",
                                                }}
                                            >
                                                Subscriptions
                                            </div>
                                        </div>
                                    )}
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="end"
                                        onClick={handleDrawerToggle}
                                        sx={{display: {sm: "none"}}}
                                    >
                                        <img
                                            src="/images/menubar.svg"
                                            alt="Menu Icon"
                                            style={{
                                                width: "24px",
                                                height: "24px",
                                                marginRight: "10px",
                                                marginTop: "10px",
                                            }}
                                        />
                                    </IconButton>
                                </div>
                            </Grid>
                            <div className>
                                <Box
                                    component="nav"
                                    sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                                    aria-label="mailbox folders"
                                >
                                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                                    <Drawer
                                        container={container}
                                        variant="temporary"
                                        open={mobileOpen}
                                        onClose={handleDrawerToggle}
                                        ModalProps={{
                                            keepMounted: true, // Better open performance on mobile.
                                        }}
                                        sx={{
                                            display: {xs: "block", sm: "none"},
                                            "& .MuiDrawer-paper": {
                                                boxSizing: "border-box",
                                                width: drawerWidth,
                                                backgroundColor: "white",
                                                color: "#727376",
                                            },
                                        }}
                                    >
                                        {drawer}
                                    </Drawer>
                                    <Drawer
                                        variant="permanent"
                                        sx={{
                                            display: {xs: "none", sm: "block"},
                                            "& .MuiDrawer-paper": {
                                                boxSizing: "border-box",
                                                width: drawerWidth,
                                                backgroundColor: "white",
                                                color: "#727376",
                                            },
                                        }}
                                        open
                                    >
                                        {drawer}
                                    </Drawer>
                                </Box>
                            </div>
                            <div style={mediaQueryVar ? planContainerStyleDesktop : planContainerStyleMobile}>
                                {/* Plan 1: Index Full Pack */}
                                <div style={cardStyle("Index Full Pack")} onClick={() => selectPlan("Index Full Pack")}>
                                    <h4
                                        style={{
                                            backgroundColor: "#1877F2",
                                            backgroundClip: "text",
                                            WebkitBackgroundClip: "text",
                                            color: "transparent",
                                            fontSize: "23px",
                                            fontWeight: "bold", // Optional for styling
                                        }}
                                    >
                                        Index360
                                    </h4>
                                    {/* <h2
                            style={{
                                fontWeight: "500",
                                fontSize: "30px",
                            }}
                        >
                            $99
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "grey",
                                }}
                            >
                                {" "}
                                /year
                            </span>
                        </h2> */}
                                    {/* <button onClick={() => onSelectAmount(plans[0].price_id)} style={buttonStyle}>
                            Select Plan
                        </button> */}
                                    <div className=" d-flex justify-content-center my-5">
                                        <select
                                            className="form-control w-50 bg-primary text-white"
                                            value={selectedValue}
                                            onChange={(e) => onSelectAmount(e.target.value)}
                                        >
                                            <option className="bg-white text-dark" value="">
                                                Select Plan
                                            </option>
                                            <option className="bg-white text-dark" value={plans[0]?.price_id}>
                                                $99/ 12 months
                                            </option>
                                            <option className="bg-white text-dark" value={plans[1]?.price_id}>
                                                $50/ 6 months
                                            </option>
                                            <option className="bg-white text-dark" value={plans[2]?.price_id}>
                                                $20/ 2 months
                                            </option>
                                        </select>
                                    </div>
                                    <p style={{textAlign: "left", fontWeight: "bold", fontSize: "15px"}}>
                                        Access to 10 Indices
                                    </p>
                                    <ul
                                        style={{
                                            textAlign: "left",
                                            listStyleType: "none",
                                            paddingLeft: "0",
                                            fontSize: "15px",
                                        }}
                                    >
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Regular updates on changes in Composition and Strategy
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Comparisons with Benchmarks
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Weekly Newsletter for the latest market insights
                                        </li>
                                    </ul>
                                    <p style={{textAlign: "left", fontWeight: "bold", fontSize: "15px"}}>
                                        Portfolio Access
                                    </p>
                                    <ul
                                        style={{
                                            textAlign: "left",
                                            listStyleType: "none",
                                            paddingLeft: "0",
                                            fontSize: "15px",
                                        }}
                                    >
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Complete access to portfolio testing and benchmarking
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Free unlimited notifications for portfolio updates
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Unlimited portfolio views
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Personalized portfolio recommendations
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Unlimited Crypto Notifications
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Top 10 Portfolios Access
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Free Own Portfolio Audit and Personalized Recommendations
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Portfolio analysis,including identifying weak cryptos
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Comparisons with other portfolios for strategic insights
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Order book with decision efficiency tracking
                                        </li>
                                    </ul>
                                    <p style={{textAlign: "left", fontWeight: "bold", fontSize: "15px"}}>
                                        Crypto Screener and Price Predictions
                                    </p>
                                    <ul
                                        style={{
                                            textAlign: "left",
                                            listStyleType: "none",
                                            paddingLeft: "0",
                                            fontSize: "15px",
                                        }}
                                    >
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Access to 20 crypto signals for technical analysis and trend identification
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Free unlimited notifications for crypto updates
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Unlimited access to crypto influencer predictions, including accuracy and
                                            rankings
                                        </li>
                                        <li style={{position: "relative", paddingLeft: "25px"}}>
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    left: "0",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                <img
                                                    src="/images/green-tick.svg"
                                                    alt="Green Tick"
                                                    style={{width: "18px", height: "18px"}}
                                                />
                                            </span>
                                            Influencer performance tracking for informed decision-making
                                        </li>
                                    </ul>
                                    <p style={{textAlign: "left"}}>
                                        <strong>Priority Chat Support</strong>
                                    </p>
                                    <p style={{textAlign: "left"}}>
                                        <strong>9000 Wisecoins worth $50</strong> (redeemable before listing)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    );
};

export default Pricing;
