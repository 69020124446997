import Footer from "../../common/footer/Footer";
import Header from "../../common/header";
import "./presale.css";
import MeetTeam from "./presale/MeetTeam";
import PresaleForm from "./presale/PresaleForm";

const Presale = () => {
    return (
        <>
            <div className="main-div presale page-wrapper">
                <Header />
                <PresaleForm/>
                {/* <MeetTeam/> */}
                <Footer className="bottom-0" />
            </div>
        </>
    );
};

export default Presale;
