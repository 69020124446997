export const getPortfolioData = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    slug: data.slug,
    start_index: data.start_index,
    portfolio_type_id: data.portfolio_type_id,
    limit: data.limit,
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/myportfolio`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const addToPortfolio = (data) => {


  console.log("UPDATE API CALL INITIATE");

  var myHeaders = new Headers();
  console.log(data);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  console.log(data.date_time)
  // const today = new Date();

  // const month = data.date_time.getMonth()+1;
  // const year = data.date_time.getFullYear();
  // const date = data.date_time. getDate();
  // const currentDate = month + "/" + date + "/" + year;
  // handleDateChange(month + "/" + date + "/" + year)
  var raw = JSON.stringify({
    portfolio_type_id: data.portfolio_type_id ,
    portfolio_name: data.portfolio_name ,
     existing_portfolio:data.existing_portfolio,
    user_id: data.user_id,
    slug:  data.slug,
    unit: data.unit,
    acquisition_price: data.acquisition_price,
    date_time: data.date_time,
    type:data.type,
    
  });
  var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/updatetoportfolio`, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
};


export const copyToPortfolio = (data) => {


  console.log("UPDATE API CALL INITIATE");

  var myHeaders = new Headers();
  console.log(data);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  console.log(data.date_time)
  // const today = new Date();

  // const month = data.date_time.getMonth()+1;
  // const year = data.date_time.getFullYear();
  // const date = data.date_time. getDate();
  // const currentDate = month + "/" + date + "/" + year;
  // handleDateChange(month + "/" + date + "/" + year)
  var raw = JSON.stringify({
    portfolio_type_id: data.portfolio_type_id ,
    portfolio_name: data.portfolio_name ,
    existing_portfolio:data.existing_portfolio,
    user_id: data.user_id,
    portfolio_data:data.portfolio_data,
  });
  var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/copyportfolio`, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
};
export const getPortfolioShare = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    portfolio_id:data.portfolio_id,
    slug: data.slug,
    start_index: data.start_index,
    limit: data.limit,
    Token: data.Token
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/portfolio-share`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getPortfolioShareWithoutLogin = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    portfolio_id:data.portfolio_id,
    slug: data.slug,
    start_index: data.start_index,
    limit: data.limit,
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/portfolio_share_without_login`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};
export const getUserData = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    portfolio_id:data.portfolio_id,
    slug: data.slug,
    start_index: data.start_index,
    limit: data.limit,
    Token: data.Token
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getuserdata`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getInfluencerData = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
 
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    portfolio_id:data.portfolio_id,
    slug: data.slug,
    start_index: data.start_index,
    limit: data.limit,
    Token: data.Token
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getInfluencerData`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};


export const getNormalUserData = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    Token: data.Token
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getNormalUserData`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};
export const getUserPurchaseData = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    Token: data.Token
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getUserPurchaseData`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getOrderBookData = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    slug: data.slug,
    start_index: data.start_index,
    portfolio_type_id: data.portfolio_type_id,
    limit: data.limit,
    timefilter: data.time_filter,
    searchfilter: data.searchfilter
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/order_book`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getOrderBookShare = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    portfolio_id:data.portfolio_id,
    slug: data.slug,
    start_index: data.start_index,
    limit: data.limit,
    timefilter: data.time_filter,
    searchfilter: data.searchfilter
  });
  console.log("searchfilter"+data.portfolio_id);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/order_book_share`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};


export const getOrderBookShareWithoutLogin = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    portfolio_id:data.portfolio_id,
    slug: data.slug,
    start_index: data.start_index,
    limit: data.limit,
    timefilter: data.time_filter,
    searchfilter: data.searchfilter
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/order_book_share_wihout_login`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getmultiPortfolio = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    search_text:data.search_text,
    start_index: data.start_index,
    limit: data.limit,
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/viewmultipleportfolio`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};
export const RenamePortfolio = (data) => {


  console.log("UPDATE API CALL INITIATE");

  var myHeaders = new Headers();
  console.log(data);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);

  var raw = JSON.stringify({
    portfolio_id: data.portfolio_id ,
    portfolio_name: data.portfolio_name ,
    
    user_id: data.user_id,
   
    
  });
  var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/renameportfolio`, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
};

export const updateProfilePhoto = (userData) => {
  console.log("UPDATE API CALL INITIATE");

  const myHeaders = new Headers();
  myHeaders.append("Authorization", userData.token);

  const bodyContent = new FormData();
  bodyContent.append("user_id", userData.user_id);
  bodyContent.append("portfolio_id", userData.portfolioTypeId);
  bodyContent.append("portfolio_photo", userData.selectedFile);

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/updateportfoliophoto`, {
    method: "POST",
    body: bodyContent,
    headers: myHeaders,
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => console.log(err));
};

export const getPerformanceSummaryData = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    slug: data.slug,
    start_index: data.start_index,
    portfolio_type_id: data.portfolio_type_id,
    limit: data.limit,
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/performance_summary`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getPerformanceSummaryShare = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", data.Token);
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
    user_id: data.user_id,
    portfolio_id:data.portfolio_id,
    slug: data.slug,
    start_index: data.start_index,
    limit: data.limit,
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/performance_summary_share`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getPerformanceSummaryShareWithoutLogin = (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  // myHeaders.append("Token",data.Token);
  var raw = JSON.stringify({
   
    portfolio_id:data.portfolio_id,
    slug: data.slug,
    start_index: data.start_index,
    limit: data.limit,
  });
  console.log(raw);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/performance_summary_share_without_login`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};


