const { Category } = require("@mui/icons-material");

//login call
const getCryporCurrencyListHome = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "123456xxxxxxxxxxxx");
   
    var raw = JSON.stringify({
        "slug": data.slug,
        "start_index": data.start_index,
        "limit": data.limit,
        "category" : data.category,
        "hashtagdata" : data.hashtagdata,
        "selected_filters": data.selected_filters,
        "volume" : data.volume,
        "marketCap" : data.marketCap, 
        "sentiment_filters" : data.sentiment_filters,
        "user_id" : data.user_id ,
        "pathname" : data.pathname
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/cryptocurrencylisthome`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));
};

const getPortfolioForGallery = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestBody = {
        "user_id": data.user_id,
        "search_text": data.search_text,
        "date": {
            "type": '1',
            "value": data.value
        },
        "is_verified": data.is_verified || "no",
        "sort_by_roi": data.sort_by_roi || "desc",
        "start_index": data.start_index,
        "limit": data.limit,
    };

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow'
    };

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getportfolioforgallery`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
            return { code: "500", message: "Internal Server Error" };
        });
};



const getPortfolioForCategoryGallery = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestBody = {
        "user_id": data.user_id,
        "search_text": data.search_text,
        "categoryName": data.categoryName,
        "date": {
            "type": '1',
            "value": data.value
        },
        "is_verified": data.is_verified || "no",
        "sort_by_roi": data.sort_by_roi || "desc",
        "start_index": data.start_index,
        "limit": data.limit,
    };

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow'
    };

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getportfolioforcategorygallery`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
            return { code: "500", message: "Internal Server Error" };
        });
};

const getPortfolioForGalleryWatchlist = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestBody = {
        "user_id": data.user_id,
        "search_text": data.search_text,
        "date": {
            "type": '1',
            "value": data.value
        },
        "is_verified": data.is_verified || "no",
        "sort_by_roi": data.sort_by_roi || "desc",
        "start_index": data.start_index,
        "limit": data.limit,
    };

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow'
    };

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getportfolioforgallerywatchlist`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
            return { code: "500", message: "Internal Server Error" };
        });
};

const getPortfolioForGalleryList = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestBody = {
        "user_id": data.user_id,
        "search_text": data.search_text,
        "portfolioName": data.portfolioName,
        "date": {
            "type": '1',
            "value": data.value
        },
        "is_verified": data.is_verified || "no",
        "sort_by_roi": data.sort_by_roi || "desc",
        "start_index": data.start_index,
        "limit": data.limit,
    };

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow'
    };

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getportfolioforgallerylist`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
            return { code: "500", message: "Internal Server Error" };
        });
};

const getPortfolioForGallery1 = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestBody = {
        "user_id": data.user_id,
        "search_text": data.search_text,
        "date": {
            "type": '1',
            "value": data.value
        },
        "is_verified": data.is_verified || "no",
        "sort_by_roi": data.sort_by_roi || "desc",
        "start_index": data.start_index,
        "limit": data.limit,
    };

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow'
    };

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getportfolioforgallery1`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
            return { code: "500", message: "Internal Server Error" };
        });
};

const getSymbolbyPortfolioid = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", data.Token);

    var requestBody = {
        "user_id": data.user_id,
        "portfolio_id": data.portfolio_id
    };

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow'
    };

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getsymbolbyportfolioid`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
            return { code: "500", message: "Internal Server Error" };
        });
};

const getCryptoPortfolioForGallery = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestBody = {
        "user_id": data.user_id,
        "search_text": data.search_text,
        "date": {
            "type": '1',
            "value": data.value
        },
        "is_verified": data.is_verified || "no",
        "sort_by_roi": data.sort_by_roi || "desc",
        "start_index": data.start_index,
        "limit": data.limit,
        "crypto": data.crypto
    };

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow'
    };

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getCryptoPortfolioForGallery`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
            return { code: "500", message: "Internal Server Error" };
        });
};

const getTopPerformingCryptos = (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestBody = {
        "user_id": data.user_id,
        "value": data.value,
    };

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: 'follow'
    };

    return fetch(`https://crowdwisdom.live/wp-json/portfolio/v1/getTopPerformingCryptos`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
            return { code: "500", message: "Internal Server Error" };
        });
};




// Export all functions at once
module.exports = {
    getCryporCurrencyListHome,
    getPortfolioForGallery,
    getPortfolioForCategoryGallery,
    getPortfolioForGalleryWatchlist,
    getPortfolioForGalleryList,
    getPortfolioForGallery1,
    getSymbolbyPortfolioid,
    getCryptoPortfolioForGallery,
    getTopPerformingCryptos,
   
};
