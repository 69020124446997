import React, {useEffect, useState} from "react";
import RHSlider from "../../../common/hooks/slider/RHSlider";
import {getPresalePriceData, handleStripeCheckout} from "./api";

const PresaleForm = () => {
    const [currentPresale, setCurrentPresale] = useState();
    const [nextStage, setNextStage] = useState(null);
    const [priceAmount, setPriceAmount] = useState("");
    const [paymentTab, setPaymentTab] = useState(1);

    useEffect(() => {
        // Fetch current presale price data
        getPresalePriceData(1)
        .then((data) => setCurrentPresale(data))
        .catch((error) => console.error("Error fetching current presale price:", error));

        // Fetch next stage price data
        getPresalePriceData(2)
        .then((data) => setNextStage(data))
        .catch((error) => console.error("Error fetching next stage price:", error));
    }, []);

    const handlePayPrice = (value) => {
        setPriceAmount(value);
    };

    const handleCardCheckout = () => {
        if (!priceAmount) {
            alert("Please select a price");
            return;
        }
        handleStripeCheckout(priceAmount);
    };

    const handleCryptoCheckout = () => {
        alert("Work in progress");
    };

    const handleTabChange = (tabNum) => {
        setPaymentTab(tabNum);
    };

    const presaleAmountPriceData = [
        {
            price: "20",
            stripe_price_id: "price_1QiAbkKAXtGoEI6mt5oRDjcc",
        },
        {
            price: "50",
            stripe_price_id: "price_1QiAfDKAXtGoEI6moqhBiev3",
        },
        {
            price: "99",
            stripe_price_id: "price_1QiAfsKAXtGoEI6m0nX6khu2",
        },
    ];

    return (
        <div className="main-content">
            <div className="d-flex justify-content-center align-items-center mt-4">
                <div className="card rounded shadow d-flex justify-content-center align-items-center">
                    <div className="heading text-center">
                        <h1 className="d-inline-block">CrowdWisdom Presale Stage 1</h1>
                    </div>
                    <div className="price-div">
                        <p>
                            1 $WISD ={" "}
                            <span>
                                {currentPresale
                                    ? `$${currentPresale.data?.[0]?.presale_price || "Not known"}`
                                    : "Fetching..."}
                            </span>
                        </p>
                        <p>
                            Next Stage ={" "}
                            <span>
                                {nextStage ? `$${nextStage.data?.[0]?.presale_price || "Not known"}` : "Fetching..."}
                            </span>
                        </p>
                    </div>
                    <div className="cypto-btn">
                        <button onClick={() => handleTabChange(1)} className={paymentTab === 1 ? "active" : ""}>
                            <img src="images/temp_images/image-2.png" alt="crypto-img" />
                            <span className="button-text">USDT</span>
                        </button>
                        <button onClick={() => handleTabChange(2)} className={paymentTab === 2 ? "active" : ""}>
                            <img src="images/temp_images/image-1.png" alt="crypto-img" />
                            <span className="button-text">CARD</span>
                        </button>
                    </div>
                    <div className="input-div-main">
                        <div className="input-div row">
                            <label htmlFor="you-pay-amount" className="col-8">
                                {paymentTab === 1 ? "USDT Amount You Pay": "Amount You Pay"}
                            </label>
                            <div className="select-container col-4">
                                <select onChange={(e) => handlePayPrice(e.target.value)} value={priceAmount}>
                                    <option>Select</option> {/* Static default option */}
                                    {presaleAmountPriceData &&
                                        presaleAmountPriceData.map((amount, index) => (
                                            <option key={index} value={amount.stripe_price_id}>
                                                ${amount.price}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <div className="input-div row mt-3">
                            <label htmlFor="you-pay-amount" className="col-8">
                                Amount In WISD You Receive
                            </label>
                            <div className=" col-4">
                                <input className="col-4" type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="connect-wallet-btn mt-3">
                        {paymentTab === 1 ? (
                            <button onClick={handleCryptoCheckout}>
                                <img src="images/icons/wallet.svg" alt="crypto-img" />
                                <span className="button-text">Connect Wallet</span>
                            </button>
                        ) : (
                            <button onClick={handleCardCheckout}>
                                <img src="images/icons/wallet.svg" alt="crypto-img" />
                                <span className="button-text">Pay with card</span>
                            </button>
                        )}
                    </div>
                    <div className="text-info-div w-100 mt-4">
                        <div className="text-start">
                            {" "}
                            {/* Added text-start class to align text to the left */}
                            <p>Amount Raised For Presale</p>
                            <p>USD Raised: </p>
                            <p>Token Sold: </p>
                        </div>
                    </div>
                    <RHSlider className="slider" />
                </div>
            </div>
            <div className="bottom-btn container mt-3">
                <button>
                    <span className="button-text">Whitepaper</span>
                </button>
                <button>
                    <span className="button-text">How To Buy</span>
                </button>
            </div>
            <div className="bottom-signup-btn container mt-4">
                <button>
                    <img src="images/icons/signup-arrow.png" alt="crypto-img" />
                    <span className="button-text">Sign Up For Listing Updates</span>
                </button>
            </div>
        </div>
    );
};

export default PresaleForm;
