import "./account.css";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { EditText } from "react-edit-text";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Helmet } from 'react-helmet';
import { useState } from "react";
import { Button, Grid,Dialog, DialogActions, DialogContent, DialogTitle, TextField, Card, CardContent } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNavigate } from "react-router-dom";
import { updateAccount } from "../../apihelper/profile";
import SidebarMenuList from "../../includes/sidebarMenuList";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";
import json2mq from "json2mq";
import { Link } from 'react-router-dom';
import { getNormalUserData, getUserPurchaseData } from "../../apihelper/portfolio";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import LoginPopUp from '../loginpopup';
import { getPortfolioData,getmultiPortfolio } from "../../apihelper/portfolio";
import {
  getPortfolioForGalleryWatchlist,
  getSymbolbyPortfolioid,
} from "../../apihelper/homepage";
import LoadingButton from "@mui/lab/LoadingButton";
import { Tabs, Tab } from '@mui/material';
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const drawerWidth = 292;

function Account(props) {
  const location = useLocation(); 
  console.log("current URL  : ",location.pathname);
  const loc = location.pathname;
  const navigate = useNavigate();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [redirectpage, setRedirectpage] = useState(false);
  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const [snackbarmessage, setSnackbarmessage] = React.useState("Loading...");
  const [fullName, setFullName] = React.useState("");
  const [profilePhoto, setProfilePhoto] = React.useState();
  const [userLocalData, setUserLocalData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [gallarydata, setGallarydata] = useState([]);
  const [gallarydata1, setGallarydata1] = useState([]);
  const [walletAddress, setWalletAddress] = useState(''); // State to store the input value
  const [portfolio_name, setPortfolio_Name] = useState('');
  const [portfolio_Id, setPortfolio_Id] = useState('');
  const [coin_data, setCoin_data] = useState('');
  const [activeTab, setActiveTab] = useState('myPortfolios');
  const [rowsToShow, setRowsToShow] = useState(7);
  const formatDateTime = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    
    // Format the date
    const [year, month, day] = datePart.split("-");
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
  
    let daySuffix = "th";
    if (day % 10 === 1 && day !== "11") {
      daySuffix = "st";
    } else if (day % 10 === 2 && day !== "12") {
      daySuffix = "nd";
    } else if (day % 10 === 3 && day !== "13") {
      daySuffix = "rd";
    }
  
    const formattedDate = `${monthName} ${parseInt(day, 10)}${daySuffix}`;
  
    // Format the time
    const [hour, minute] = timePart.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12;
  
    const formattedTime = `${formattedHour}:${minute} ${ampm}`;
  
    return `${formattedDate}`;
  };

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };

  const user = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const userId = user.user_id;

  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbaropen(false);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));


  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        if (!userData) {
          throw new Error("No user data found in local storage");
        }

        const user_idValue = userData.user_id;
        const user_token = userData.token;
        console.log("Minfluencer Name : ",user_idValue);
        // Fetch user data
        const userRes = await getNormalUserData({
          user_id: user_idValue,
          Token: user_token,
        });

        if (userRes.code === "200") {
        console.log("User data fetched successfully: ", userRes.data);
          const userData = userRes.data[0];
          setFullName(userRes.data[0].full_name);
          setDescription(userRes.data[0].description);
          setTwitterLink(userRes.data[0].twitter_link);
          setProfilePhoto(userRes.data[0].photo);
          setWalletAddress(userRes.data[0].wallet_address);
          setPortfolio_Id(userRes.data[0].portfolio_id);
          setCoin_data(userRes.data[0].coins_count);

        } else {
          console.log(userRes.message);
        }

      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);



console.log("Portfolio Name Default : ",portfolio_name);




  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleButtonClick = () => {
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setProfilePhoto(file);
    console.log("photo: ", file);
  };
  console.log("profile Photo: ",profilePhoto);
  // Your component code


  const handleButtonSaveClick = () => {
    
    setSnackbaropen(true);
    setSeverity("info")
    setSnackbarmessage("Loading...")
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log(fullName,profilePhoto)
    const user_id=userData.user_id
    const token=userData.token
    const about = description;
    const wallet_address = walletAddress;
    const twitter = twitterLink;
    console.log("user account profile:1");
    updateAccount({user_id,fullName,profilePhoto,token,about,wallet_address,twitter}).then(res=>{
      if(res.code==="200"){
        console.log("user account profile:", JSON.stringify(res, null, 2));
        setSnackbarmessage(res.message);
        setFullName(res.data.full_name);
          setDescription(res.data.description);
          setTwitterLink(res.data.twitter_link);
          setProfilePhoto(res.data.photo);
          setWalletAddress(res.data.wallet_address);
        
        // Update other properties as needed

        localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));
      }
      else{
        setSeverity("error")
        setSnackbarmessage(res.message);
      }
    })
;

    handleDialogClose();
  };


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <SidebarMenuList />
    </div>
  );

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );


  const [youtubeLink, setYoutubeLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');
  const [telegramLink, setTelegramLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');

  const [isEditingYoutube, setIsEditingYoutube] = useState(false);
  const [isEditingTwitter, setIsEditingTwitter] = useState(false);
  const [isEditingTelegram, setIsEditingTelegram] = useState(false);
  const [isEditingInstagram, setIsEditingInstagram] = useState(false);
  const [localUserData, setLocalUserData] = useState("");
const [filter, setFilter] = useState("");
const [roivalue, setRoivalue] = useState("--");
const [portfoliovalue, setPortfoliovalue] = useState("--");
const [slugdata, setSlugdata] = useState([]);
const [data, setData] = useState([]);
const [lastUpdatedTime, setLastUpdatedTiem] = useState("");
const [portfolioid, setportfolioid] = useState("");
const [initialPage, setInitialPage] = useState(false);
const [loaderbutton, setLoaderButton] = useState(true);
const [loader, setLoader] = useState(false);
const [addMoreLoader, setAddMoreLoader] = useState(false);
const [photogalleryloader, setphotogalleryloader] = useState(false);
const [photoindex, setphotoIndex] = useState(0);
const [starredPortfolios, setStarredPortfolios] = useState({});
const [galleryloaderbutton, setgalleryloaderbutton] = useState(true);
const [addMoregalleryLoader, setAddMoregalleryLoader] = useState(false);
const [selecteddata, setselecteddata] = useState("");
  const [verifieddata, setverifieddata] = useState("");
  const [roidata, setroidata] = useState("");



//youtube
  const handleYouTEditClick = (e) => {
    e.preventDefault();
    setIsEditingYoutube(true);
  };

  const [isSaved, setIsSaved] = useState(false);

  const handleInputChange = (e) => {
    setWalletAddress(e.target.value);
    setIsSaved(false); // Reset saved state on input change
  };

  const handleSave = () => {
    if (walletAddress) {
      // Logic to save the wallet address goes here.
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log("User data: ",userData);
    const user_id=userData.user_id;
    const token=userData.token;

    updateAccount({
      user_id: user_id,
      fullName,
      profilePhoto,
      about: description,
      youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
      token : token,
    })
    .then(res=>{
      if(res.code==="200"){
        
        userData.full_name = fullName;
        userData.wallet_address = walletAddress;
       
        setFullName(res.data.full_name);
          setDescription(res.data.description);
          setTwitterLink(res.data.twitter_link);
          setProfilePhoto(res.data.photo);
          setWalletAddress(res.data.wallet_address);

      }
      else{
        alert(res.message);
      }
    })
    setIsSaved(true); // Set saved state to true after saving

    
      
    }
  };

  
  const handleYouTSaveClick = (e) => {
    e.preventDefault();
    // Update the database with the new description (about)
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log("User data: ",userData);
    const user_id=userData.user_id;
    const token=userData.token;

    updateAccount({
      user_id: user_id,
      fullName,
      profilePhoto,
      about: description,
      youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
      token : token,
    })
    .then(res=>{
      if(res.code==="200"){
        //console.log("Data updated successfully: res ",res.data);
        //console.log("Data updated successfully: userdata",userData);

        userData.full_name = fullName;
        userData.youtube = youtubeLink;
        setYoutubeLink(youtubeLink);
        setIsEditingYoutube(false);
        localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));

      }
      else{
        alert(res.message);
      }
    })
   
  };
  
  const handleYouTChange = (e) => {
    setYoutubeLink(e.target.value);
  };
  


//twitter
const handleTwitterEditClick = (e) => {
  e.preventDefault();
  setIsEditingTwitter(true);
};

const handleTwitterSaveClick = (e) => {
  e.preventDefault();
  // Update the database with the new description (about)
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  //console.log("User data: ",userData);
  const user_id=userData.user_id;
  const token=userData.token;

  updateAccount({
    user_id: user_id,
    fullName,
    about: description,
    profilePhoto,
    youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
    token : token,
  })
  .then(res=>{
    if(res.code==="200"){
      //console.log("Data updated successfully: res ",res.data);
      //console.log("Data updated successfully: userdata",userData);

      setFullName(res.data.full_name);
          setDescription(res.data.description);
          setTwitterLink(res.data.twitter_link);
          setProfilePhoto(res.data.photo);
          setWalletAddress(res.data.wallet_address);

    }
    else{
      alert(res.message);
    }
  })
 
}; 


const handleTwitterChange = (e) => {
  setTwitterLink(e.target.value);
};


//instagram
const handleInstaEditClick = (e) => {
  e.preventDefault();
  setIsEditingInstagram(true);
};

const handleInstaSaveClick = (e) => {
  e.preventDefault();
  // Update the database with the new description (about)
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  //console.log("User data: ",userData);
  const user_id=userData.user_id;
  const token=userData.token;

  updateAccount({
    user_id: user_id,
    fullName,
    profilePhoto,
    about: description,
    youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
      token : token,
  })
  .then(res=>{
    if(res.code==="200"){
      console.log("Data updated successfully: res ",res.data);
      //console.log("Data updated successfully: userdata",userData);

      userData.full_name = fullName;
      userData.instagram = instagramLink;
      setInstagramLink(instagramLink);
      setIsEditingInstagram(false);
      localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));

    }
    else{
      alert(res.message);
    }
  })
 
}; 


const handleInstaChange = (e) => {
  setInstagramLink(e.target.value);
};



//telegram
const handleTeleEditClick = (e) => {
  e.preventDefault();
  setIsEditingTelegram(true);
};

const handleTeleSaveClick = (e) => {
  e.preventDefault();
  // Update the database with the new description (about)
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  //console.log("User data: ",userData);
  const user_id=userData.user_id;
  const token=userData.token;

  updateAccount({
    user_id: user_id,
    fullName,
    profilePhoto,
    about: description,
    youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
    token : token,
  })
  .then(res=>{
    if(res.code==="200"){
      //console.log("Data updated successfully: res ",res.data);
      //console.log("Data updated successfully: userdata",userData);

      userData.full_name = fullName;
      userData.telegram = telegramLink;
      setTelegramLink(telegramLink);
      setIsEditingTelegram(false);
      localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));

    }
    else{
      alert(res.message);
    }
  })
 
}; 


const handleTeleChange = (e) => {
  setTelegramLink(e.target.value);
};



  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState('');
  

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    // Update the database with the new description (about)
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log("User data: ",userData);
    const user_id=userData.user_id;
    const token=userData.token;

    updateAccount({
      user_id: user_id,
      fullName,
      profilePhoto,
      about: description,
      youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
      token : token,
    })
    .then(res=>{
      if(res.code==="200"){
       // console.log("Data updated successfully: res ",res.data);
        //console.log("Data updated successfully: userdata",userData);

        setFullName(res.data.full_name);
          setDescription(res.data.description);
          setTwitterLink(res.data.twitter_link);
          setProfilePhoto(res.data.photo);
          setWalletAddress(res.data.wallet_address);

      }
      else{
        alert(res.message);
      }
    })
   
  }; 


  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };



  const container =
    window !== undefined ? () => window().document.body : undefined;

  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Use the second argument as the active tab value
  };
  const handleTabChange2 = (tabName) => {
    setActiveTab(tabName); // Change the active tab based on clicked button
  };
  
  const [watchlist_loader, setWatchlist_loader] = useState(false);
  
  const fetchUserPurchaseData = async () => {  // Renamed to avoid conflict with existing fetchData
    try {
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      if (!userData) {
        throw new Error("No user data found in local storage");
      }
  
      const user_idValue = userData.user_id;
      const user_token = userData.token;
      console.log("Minfluencer Name : ", user_idValue);
      // Fetch user data
      const userRes = await getUserPurchaseData({
        user_id: user_idValue,
        Token: user_token,
      });
  
      if (userRes.code === "200") {
        if(userRes.data.length > 0){
          setWatchlist_loader(true);
        }
        console.log("User data fetched successfully: ", userRes.data);
        const userData = userRes.data[0];
        setGallarydata1(userRes.data);

      } else {
        console.log(userRes.message);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };
  
 
  useEffect(() => {
    if (activeTab === 'myPortfolios') {
      fetchData1();
    } else if (activeTab === 'portfolioWatchlist') {
      fetchphotogallery();
    }
    else if (activeTab === 'purchases') {  // Only fetch data when active tab is "purchases"
      fetchUserPurchaseData();
    }
  }, [activeTab]);

  if (redirectpage) {
    return navigate("/");
  }

  const generateReferralLink = () => {
    const formattedFullName = fullName.replace(/\s+/g, '-'); // Replace spaces with hyphens
    return `https://investing.crowdwisdom.live/signUp?ref=${formattedFullName}`;
  };
  

  const fetchData1 = () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    console.log("User data: ",userData);
    setUserLocalData(userData);
    
    getmultiPortfolio({
      user_id: userData.user_id,
      search_text: filter,
      Token: userData.token, 
       start_index: 0,
    
    }).then((res) => {
      if (res.code === "200") {
        console.log("fetchdata1 is called 200");
        setRoivalue(res.total_profit);
        setPortfoliovalue(res.total_portfolio_value);
        setSlugdata(res.data);
        setData(res.data);
        setLastUpdatedTiem(res.updated_on);
        setportfolioid(res.portfolio_id);
        console.log("AFter clicking fetchData:",res.data);
     
        if (res.data.length === 0) {
          setInitialPage(true);
        }
        if (res.data.length < 10) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        console.log("fetchdata1 is called 200 not");
      }
    });
  }
  const fetchphotogallery = () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const user_id = userData ? userData.user_id : 0;
    console.log("gallery dats is called:");

    setphotogalleryloader(false);
    getPortfolioForGalleryWatchlist({
      user_id: user_id,
      search_text: filter,
      start_index: `${photoindex}`,
      limit: `${7}`,
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully");
        setGallarydata(res.data);
        console.log("gallery dats is called:",res.data);
       
        res.data.forEach((portfolio) => {
          starredPortfolios[portfolio.portfolio_id] = true;
        });
  
        // Set the starred portfolios state based on the watchlist data from the database
        setStarredPortfolios(starredPortfolios);

        if (res.data.length < 7) {
          setgalleryloaderbutton(false);
        } else {
          setgalleryloaderbutton(true);
        }
        if(gallarydata.length<7){
        setphotogalleryloader(true);
        }
        setAddMoregalleryLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };
  const handleLoadMore = () => {
    setRowsToShow(rowsToShow + 4);
  };
  
  const loadMoregallery = () => {
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      const user_id = userData ? userData.user_id : 0;
      setAddMoregalleryLoader(true);
      setphotoIndex(photoindex + 7);
      // console.log(index);
  
      getPortfolioForGalleryWatchlist({
        user_id: user_id,
        search_text: filter,
        value: selecteddata,
        is_verified: verifieddata ? "yes" : "no",
        sort_by_roi: roidata,
        start_index: photoindex + 7,
        limit: `${7}`,
      }).then((res) => {
        if (res.code === "200") {
          console.log("data got successfully");
          setGallarydata([...gallarydata, ...res.data]);
          
          res.data.forEach((portfolio) => {
            starredPortfolios[portfolio.portfolio_id] = true;
          });
    
          // Set the starred portfolios state based on the watchlist data from the database
          setStarredPortfolios(starredPortfolios);
  
          if (res.data.length < 7) {
            // console.log("change Loaderbutton");
            setgalleryloaderbutton(false);
          } else {
            setgalleryloaderbutton(true);
          }
          setphotogalleryloader(true);
          setAddMoregalleryLoader(false);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    };
  
  return (
    <>
    {mediaQueryVar === true? (
    <Box sx={{ display: "flex", flexWrap: "wrap", backgroundColor:"#EDF1F6" }} className="main_account">
      <Helmet>
        <title>Account</title>
      </Helmet>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <div
          style={{
            backgroundColor: "#EDF1F6",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }} >
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "0px",
                    marginBottom: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    style={{
                      fontFamily:"Satoshi, sans-serif",
                      marginTop:"-5px",
                      marginLeft:"-5px",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                  >
                    <div onClick={() => navigate("/")}>
      <img
        src="/images/left-arrow.svg"
        alt="profile_pic"
        style={{
          width: "20px",
          height: "18px",
          marginRight: "15px",
          cursor:"pointer"
        }}
      />
      My Account
    </div>
                  </Typography>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { sm: "none" } }}
                  >
                    <img
                      src="/images/menubar.svg"
                      alt="Menu Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "10px",
                        marginTop: "-20px",
                      }}
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box
           
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
           
          >
            
            <Grid container spacing={2}>
             

<Box style ={{
 backgroundColor:"white",
 marginTop:"18px",
 marginLeft:"18px",
 marginRight:"18px",
 padding:"15px 25px",
 borderRadius:"10px",
 width:"100%"
}}>

<div style={{
  gap: "20px",
  display: "inline-flex",
  padding:"5px 2px",
  borderRadius:"38px",
  paddingRight:"20px",
  marginBottom:"0px",
  }}>

{profilePhoto ?(
  <img src={profilePhoto} alt ="profile_pic" style={{
    width: "42px", 
    height: "42px", 
    position: "relative", 
    borderRadius: "33px",
    border: "2px black solid"}}
     ></img>
):(
  <img src="/images/avtar.jpeg" alt ="profile_pic" style={{
    width: "42px", 
    height: "42px", 
    position: "relative", 
    borderRadius: "33px",
    border: "2px black solid"}}
     ></img>
)}

<div style={{
  justifyContent: "center", 
  alignItems: "center", 
  gap: "5px",
   display: "flex"
   }}>
<div
  style={{
    color: "black",
    fontSize: "20px",
    fontWeight: "600",
    marginLeft: "-5px",
    fontFamily: "Satoshi, sans-serif",
    wordWrap: "break-word",
  }}
>
  {fullName ? (
    fullName
  ) : (
    <div
      style={{
        width: "25vw", // Adjust width to match the expected size of the text
        height: "24px", // Adjust height based on font size
        backgroundColor: "#E0E0E0", // Light gray color for skeleton
        borderRadius: "4px", // Optional: rounded corners
        animation: "skeleton-loading 1.5s infinite linear",
      }}
    ></div>
  )}
</div>

</div>
</div>
<Button
      variant="outlined"
      style={{
        backgroundColor: 'white',
        textTransform: 'none',
        fontFamily: 'Satoshi, sans-serif',
        fontSize: '14px',
        fontWeight: '600',
        height: mediaQueryVar === false ? "35px" : "",
        borderColor:"white",
        padding:"0px",
        marginTop:"-40px"
      }}
      onClick={handleButtonClick}
      onMouseDown={(e) => {
        // Change button styles on click
      }}
      onMouseUp={(e) => {
        // Revert button styles when click is released
      }}
    >
      <img src="/images/edit-new.svg" style={{
        marginRight: "0px",
        height:"24px",
        width:"24px",
        marginLeft:"30px"
      }} />
    </Button>

        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            value={fullName}
            onChange={handleNameChange}
          />
          <input
            accept="image/*"
            type="file"
            onChange={handlePhotoChange}
            style={{ display: 'none' }}
            id="profile-photo-upload"
          />
          <label htmlFor="profile-photo-upload">
            <Button variant="contained" color="primary" component="span">
              Upload Photo
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleButtonSaveClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={severity}
      />



</Box>
<Box style={{
    backgroundColor: "white",
    marginTop: "15px",
    marginLeft: "20px",
    marginRight: "20px",
    padding: "10px 25px 15px 25px",
    borderRadius: "10px",
    width: "100%",
  }}>
<Box
  style={{
    backgroundColor: "white",
    marginTop: "15px",
    marginLeft: "0px",
    marginRight: "0px",
    padding: "0px",
    borderRadius: "10px",
    width: "100%",
  }}
>
  <div style={{ marginBottom: "15px" }}>
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      {/* Left: Description and Edit Button */}
      <div
  style={{
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between Description and button
  }}
>
  <Typography
    style={{
      fontSize: "18px",
      fontWeight: "600",
      fontFamily: "Satoshi, sans-serif",
    }}
  >
    Description
  </Typography>

  <div
    style={{
      padding: "6px 14px",
      borderRadius: "48px",
      backgroundColor: "#1877F2",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }}
  >
    <button
      style={{
        background: "none",
        border: "none",
        display: "flex",
        alignItems: "center",
      }}
      onClick={isEditing ? handleSaveClick : handleEditClick}
    >
      <img
        src={isEditing ? "/images/save_link.svg" : "/images/wedit.svg"}
        style={{
          width: "12px",
          height: "12px",
          marginBottom: "2px",
          marginRight: "0px",
        }}
        alt="Edit Icon"
      />
    </button>
  </div>
</div>


      {/* Right: Twitter Field */}
      <div
        className="wrapper1"
        style={{
          marginTop: "0",
          marginBottom: "0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          className="socialBtn2"
          style={{
            display: "flex",
            border: "2px solid #1877F2",
            padding: "5px",
            borderRadius: "46px",
            background: "#fff",
            gap: "10px",
           width:"35vw"
          }}
        >
          <img
            src="/images/twitter.svg"
            width="32px"
            height="32px"
            style={{
              border: "2px solid black",
              borderRadius: "50%",
              padding: "5px",
              marginLeft: "2px",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            {isEditingTwitter ? (
              <input
                type="text"
                value={twitterLink}
                onChange={handleTwitterChange}
                placeholder="Add Link"
                style={{
                  width: "100%",
                  color: "black",
                  backgroundColor: "#F7F7F7",
                  border: "none",
                  fontSize: "16px",
                  fontFamily: "Satoshi, sans-serif",
                  outline: "none",
                }}
              />
            ) : (
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#9D9DA5",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
              >
                {twitterLink || "Add Link"}
              </div>
            )}
          </div>
          <div
            style={{
              padding: "6px 14px",
              borderRadius: "48px",
              backgroundColor: "#1877F2",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <button
              style={{
                background: "none",
                border: "none",
                display: "flex",
                alignItems: "center",
              }}
              onClick={
                isEditingTwitter
                  ? (e) => handleTwitterSaveClick(e, "twitter")
                  : (e) => handleTwitterEditClick(e, "twitter")
              }
            >
              <img
                src={isEditingTwitter ? "/images/save_link.svg" : "/images/wedit.svg"}
                style={{
                  width: "12px",
                  height: "12px",
                  marginBottom: "2px",
                  marginRight: "0px",
                }}
                alt="Edit Icon"
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    {isEditing ? (
      <TextField
      value={description}
      onChange={handleDescriptionChange}
      multiline
      rows={4}
      variant="outlined"
      style={{
        marginTop: "10px",
        fontFamily: "Satoshi, sans-serif",
        fontSize: "16px",
        fontWeight: "500",
        marginBottom: "0px",
        width: "99%", // Added for full width
      }}
      />
    ) : (
      <Typography
        style={{
          color: "#7F7F80",
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "Satoshi, sans-serif",
          wordWrap: "break-word",
          marginTop: "10px",
        }}
      >
        {description}
      </Typography>
    )}
  </div>
</Box>
<div style={{
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  flexWrap: 'wrap',
}}>
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    width: 'calc(50% - 10px)', 
    maxWidth: '400px',
    
  }}>
    <label 
  htmlFor="userInput" 
  style={{ 
    marginBottom: '8px', 
    fontSize: '16px', 
    color: '#333',
    display: 'flex', // Ensures horizontal alignment
    alignItems: 'center',
    gap: '10px', // Adds space between text and button
  }}
>
  <span 
    style={{
      fontSize: '18px',
      fontWeight: '600',
      fontFamily: 'Satoshi, sans-serif',
    }}
  >
    Solana Wallet Address
  </span>

  <div
    style={{
      padding: "6px 14px",
      borderRadius: "48px",
      backgroundColor: "#1877F2",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }}
  >
    <button
      style={{
        background: "none",
        border: "none",
        display: "flex",
        alignItems: "center",
      }}
      onClick={handleSave}
    >
      <img
        src={isEditingTwitter ? "/images/save_link.svg" : "/images/save_link.svg"}
        style={{
          width: "12px",
          height: "12px",
          marginBottom: "2px",
          marginRight: "0px",
        }}
        alt="Edit Icon"
      />
    </button>
  </div>
</label>


    <input
      type="text"
      id="userInput"
      placeholder="Enter your Wallet Address Here"
      value={walletAddress}
      onChange={handleInputChange} 
      style={{
        background:'white',
        color:'black',
        padding: '10px',
        border: '2px solid #1877F2',
        borderRadius: '15px',
        fontSize: '16px',
        width: '100%',
        marginBottom:"1%",
        boxSizing: 'border-box',
        marginTop: '16px' 
      }}
    />

    {isSaved && (
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        marginTop: '10px', 
        color: 'green',
        fontFamily: 'Satoshi, sans-serif',
      }}>
        <span style={{ marginRight: '8px', marginBottom:"8px" }}>✔</span>
        <span style={{marginBottom:"8px"}}>Your wallet address has been saved!</span>
      </div>
    )}
  </div>

  <div style={{
    backgroundColor: '#E3EEFD',
    padding: '10px',
    borderRadius: '12px',
    textAlign: 'center',
    marginLeft: '10px',
    flex: '1',
    maxWidth: 'calc(50% - 10px)'
  }}>
    <h3 style={{
      fontSize: '18px',
      fontFamily: 'Satoshi, sans-serif',
      fontWeight: '700',
      color: '#444',
      marginBottom: '15px'
    }}>
      Referral Link
    </h3>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <input
        type="text"
        value={generateReferralLink()}
        readOnly
        style={{
          width: '100%',
          padding: '12px',
          border: 'none',
          borderRadius: '25px',
          fontSize: '16px',
          fontFamily: 'Satoshi, sans-serif',
          color: '#333',
          marginBottom: '3px',
          background: '#FFFFFF',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s ease',
          marginRight: '10px'
        }}
        onFocus={(e) => e.target.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'}
        onBlur={(e) => e.target.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.1)'}
      />
      <img
        src="/images/copy-new.svg"
        alt="Copy"
        style={{
          cursor: 'pointer',
          width: '24px',
          height: '24px',
          marginBottom: '20px',
          transition: 'transform 0.2s ease'
        }}
        onClick={() => navigator.clipboard.writeText(generateReferralLink())}
        onMouseEnter={(e) => e.target.style.transform = 'scale(1.1)'}
        onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
      />
    </div>
  </div>
</div>
</Box>
<Box
      sx={{
        backgroundColor: "#1877F2",
        padding: { xs: '0px', sm: '0px' },
        borderRadius: "50px", // Rounded border for the outer box
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: { xs: '320px', sm: '415px' }, // Adjusted width for better fit
        height: { xs: '40px', sm: '45px' }, // Adjusted height for better spacing
        marginLeft: "20px",
        marginTop:"15px"
      }}
    >
      <Tabs
  value={activeTab}
  onChange={handleTabChange}
  aria-label="tabs example"
  sx={{
    marginLeft: "1px",
    borderRadius: "50px",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    display: 'flex',
  }}
>
  <Tab
    label="My Portfolios"
    value="myPortfolios"
    sx={{
      whiteSpace: "nowrap",
      margin: { xs: "3px", sm: "5px" },
      minHeight: "auto", // Ensure padding adjustments work
      padding: { xs: "5px 12px", sm: "8px 10px" }, // Reduced padding
      borderRadius: "25px", // Rounded border for the tab
      textTransform: "none",
      fontSize: { xs: "14px", sm: "16px" },
      fontWeight: activeTab === "myPortfolios" ? "700" : "500",
      backgroundColor: activeTab === "myPortfolios" ? "#FFFFFF" : "transparent",
      color: activeTab === "myPortfolios" ? "#000000 !important" : "#FFFFFF",
      border: activeTab === "myPortfolios" ? "2px solid #1877F2" : "none",
    }}
  />
  <Tab
    label="Portfolio Watchlist"
    value="portfolioWatchlist"
    sx={{
      whiteSpace: "nowrap",
      margin: { xs: "3px", sm: "5px" },
      minHeight: "auto", // Ensure padding adjustments work
      padding: { xs: "5px 12px", sm: "8px 10px" }, // Reduced padding
      borderRadius: "25px",
      textTransform: "none",
      fontSize: { xs: "14px", sm: "16px" },
      fontWeight: activeTab === "portfolioWatchlist" ? "700" : "500",
      backgroundColor: activeTab === "portfolioWatchlist" ? "#FFFFFF" : "transparent",
      color: activeTab === "portfolioWatchlist" ? "#000000 !important" : "#FFFFFF",
      border: activeTab === "portfolioWatchlist" ? "2px solid #1877F2" : "none",
    }}
  />
  <Tab
    label="Purchases"
    value="purchases"
    sx={{
      whiteSpace: "nowrap",
      margin: { xs: "3px", sm: "5px" },
      minHeight: "auto", // Ensure padding adjustments work
      padding: { xs: "5px 12px", sm: "8px 10px" }, // Reduced padding
      borderRadius: "25px",
      textTransform: "none",
      fontSize: { xs: "14px", sm: "16px" },
      fontWeight: activeTab === "purchases" ? "700" : "500",
      backgroundColor: activeTab === "purchases" ? "#FFFFFF" : "transparent",
      color: activeTab === "purchases" ? "#000000 !important" : "#FFFFFF",
      border: activeTab === "purchases" ? "2px solid #1877F2" : "none",
    }}
  />
</Tabs>

    </Box>
    {activeTab=="myPortfolios" && (
      <TableContainer style={{ overflowX: "auto", borderRadius: "5px", margin: "15px 0px 20px 20px", width: "100%", paddingRight:"20px" }}>
      <div style={{ border: "1px solid #EEE", borderRadius: "5px", overflow: "hidden", width: "100%" }}>
                      <Table
                        className="non_responsiveTable" aria-label="customized table" 
                        style={{ borderCollapse: "collapse", width: "100%" }}
      
                      >
                        <TableHead>
                          <TableRow>
                          <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "50vw" }}>
                  <button disabled style={{
                    fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
                   Names
                  </button>
      
                            </StyledTableCell>
                            <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "18vw" ,minWidth:"8vw"}}>
                  <button disabled style={{
                    fontWeight: "500", textAlign: "center", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
          ROI
          </button>
        
                            </StyledTableCell>
          <StyledTableCell 
         
          className="cryptotableviewHeader" style={{ padding: 0,width: "15vw",minWidth:"8vw" }}>
                  <button 
                  
                 style={{
                    fontWeight: "500", textAlign: "center", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
        Current Value 
        </button>
        </StyledTableCell>
                          
        <StyledTableCell 
        
        className="cryptotableviewHeader" style={{ padding: 0, width: "15vw" ,minWidth:"10vw"}}>
                  <button
                    style={{
                    fontWeight: "500", textAlign: "center", color: "#727376", fontSize: "16px", width: "100%", height: "auto",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
         Gain
        </button>
                            </StyledTableCell>
                            <StyledTableCell 
        
        className="cryptotableviewHeader" style={{ padding: 0, width: "8vw" ,minWidth:"10vw"}}>
                  <button
                    style={{
                    fontWeight: "500", textAlign: "left", color: "#fff", fontSize: "16px", width: "100%", height: "auto",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
         Analysis
        </button>
                            </StyledTableCell>
                            <StyledTableCell 
        
        className="cryptotableviewHeader" style={{ padding: 0, width: "8vw" ,minWidth:"10vw"}}>
                  <button
                    style={{
                    fontWeight: "500", textAlign: "left", color: "#fff", fontSize: "16px", width: "100%", height: "auto",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
         Order Book
        </button>
                            </StyledTableCell>
                            
                          </TableRow>
                        </TableHead>
                        {loader === false && userData && (
  <TableBody>
    {Array.from({ length: 7 }).map((_, index) => (
      <StyledTableRow key={index}>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        
        
      </StyledTableRow>
    ))}
  </TableBody>
)}

                       
                        {loader && (
                          <TableBody>
                            {slugdata.slice(0, rowsToShow).map((obj, idx) => (
                            <StyledTableRow     
                            
                           
                            sx={{
                              backgroundColor: idx % 2 === 0 ? "white" : "#F0F0F0",
                              cursor: "pointer"
                            }}
              
                            className={mediaQueryVar ? 'clickable-row' : ''} key={idx} style={{ backgroundColor: idx % 2 === 0 ? "#FFF" : "#F6F6F6" }}>
                                 <StyledTableCell
        component="th"
        scope="row"
        style={{
          borderBottom: "none",
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          paddingLeft: "16px",
          paddingTop: "6px",
          paddingBottom: "6px",
          borderRight: "1px solid #DADEDF",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))',
            padding: "1px 7px 10px 15px",
            marginLeft: "-20px",
            marginRight: "-5px",
            marginBottom: "-5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // vertical alignment
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="btcParent">
             
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: "5px 7px", margin: "0 -5px -10px -20px" }}>
                    <Box>
                      {obj.photo ? (
                        <img
                          width={39.77}
                          height={38}
                          src={obj.photo}
                          style={{
                            borderRadius: "19px",
                            border: "2px solid black",
                          }}
                        />
                      ) : (
                        <img
                          width={39.77}
                          height={38}
                          src={'/images/avtar.jpeg'}
                          style={{
                            borderRadius: "19px",
                            border: "2px solid black",
                          }}
                        />
                      )}
                    </Box>
                    <Box sx={{ marginLeft: "5px", marginTop: "8px" }}>
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "5px",
                        }}
                      >
                        {obj.portfolio_name.replace(/-/g, " ")}
                      </Typography>
                  
                    </Box>
                  </Box>
                </div>
              
            </div>
          </div>
      
          
        </div>
      </StyledTableCell>
                                <StyledTableCell
                                  className="one"
                                  style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingLeft: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                  {/* <div className="table_mobile">PRICE</div> */}
      
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>{obj.cal_roi}</span>
                                    {/* <span>
                                      Last Updated {obj.price_today_updated_on}
                                    </span> */}
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                  className="one"
                                  style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingLeft: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                  
                                  {obj.net_worth !== ''  ? obj.net_worth : 'N/A'}
                                </StyledTableCell>
                              
                                <StyledTableCell
                                   style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingLeft: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                  {/* <div className="table_mobile">ROI VALUE</div> */}
                                  <div
                                    // style={{
                                    //   color: `${obj.outlook_for_today[0].textcolor}`,
      
                                    //   fontWeight:
                                    //     `${obj.outlook_for_today[0].bold}` === "yes"
                                    //       ? "bold"
                                    //       : "",
                                    //   fontStyle:
                                    //     `${obj.outlook_for_today[0].itllic}` === "yes"
                                    //       ? "italic"
                                    //       : "",
                                    // }}
                                  >
                                
                                {obj.total_net_profit !== ''  ? obj.total_net_profit : 'N/A'}
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                   style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "500",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                
                                <button
            style={{
              backgroundColor: '#FFFFFF',
              color: '#007BFF',
              border: '1px solid #007BFF',
              borderRadius: '48px',
              padding: '4px 10px',
              fontSize: '11px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#1877F2';
              e.target.style.color = '#FFFFFF';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#FFFFFF';
              e.target.style.color = '#007BFF';
            }}
            onClick={() =>
              navigate(`../my-portfolio/${obj.portfolio_type_id}/${obj.portfolio_name.replace(/ /g, '-')}/detailed-portfolio-analysis`)


            }
            
            
          >
            Analysis
          </button>
                     
                                </StyledTableCell>
                                <StyledTableCell
                                   style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "500",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                
                                <button
            style={{
              backgroundColor: '#FFFFFF',
              color: '#007BFF',
              border: '1px solid #007BFF',
              borderRadius: '48px',
              padding: '4px 10px',
              fontSize: '11px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#1877F2';
              e.target.style.color = '#FFFFFF';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#FFFFFF';
              e.target.style.color = '#007BFF';
            }}
            onClick={() =>
              navigate(`../my-portfolio/${obj.portfolio_type_id}/${obj.portfolio_name.replace(/ /g, '-')}/order-book`)


            }
          >
            Order Book
          </button>
                     
                                </StyledTableCell>

      
                                
      
      
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                      {slugdata.length === 0 && loader === true && (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          <h4>No Data Found</h4>
                        </div>
                      )}
                       </div>
                       {rowsToShow < slugdata.length && (
  <div className="loadingButton">
    <LoadingButton
      loading={addMoreLoader}
      loadingPosition="center"
      variant="outlined"
      style={{
        maxWidth: "360px",
        marginBottom: "20px",
        marginTop:"10px",
        width: mediaQueryVar === false ? "80vw" : "180px",
        backgroundColor: "rgba(67, 97, 238, 0.15)",
        borderRadius: "6px",
        border: "none",
        textTransform: "none",
        borderStyle: "solid",
        borderColor: "rgba(67, 97, 238, 0.15)",
        borderWidth: "1px",
        color: "#4361ee",
      }}
      onClick={handleLoadMore}
    >
      <span>Load More</span>
    </LoadingButton>
  </div>
)}
                    </TableContainer>
                     
                    
    )}
    {activeTab=="portfolioWatchlist" && (
      <TableContainer style={{ overflowX: "auto", borderRadius: "5px", margin: "15px 0px 20px 20px", width: "100%", paddingRight:"20px" }}>
      <div style={{ border: "1px solid #EEE", borderRadius: "5px", overflow: "hidden", width: "100%" }}>
                      <Table
                        className="non_responsiveTable" aria-label="customized table" 
                        style={{ borderCollapse: "collapse", width: "100%" }}
      
                      >
                        <TableHead>
                          <TableRow>
                          <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "50vw" }}>
                  <button disabled style={{
                    fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
                   Names
                  </button>
      
                            </StyledTableCell>
                            <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "18vw" ,minWidth:"8vw"}}>
                  <button disabled style={{
                    fontWeight: "500", textAlign: "center", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
          ROI
          </button>
        
                            </StyledTableCell>
          <StyledTableCell 
         
          className="cryptotableviewHeader" style={{ padding: 0,width: "15vw",minWidth:"8vw" }}>
                  <button 
                  
                 style={{
                    fontWeight: "500", textAlign: "center", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
        Current Value
        </button>
        </StyledTableCell>
                          
        <StyledTableCell 
        
        className="cryptotableviewHeader" style={{ padding: 0, width: "15vw" ,minWidth:"10vw"}}>
                  <button
                    style={{
                    fontWeight: "500", textAlign: "center", color: "#727376", fontSize: "16px", width: "100%", height: "auto",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
         Gain
        </button>
                            </StyledTableCell>
                            <StyledTableCell 
        
        className="cryptotableviewHeader" style={{ padding: 0, width: "8vw" ,minWidth:"10vw"}}>
                  <button
                    style={{
                    fontWeight: "500", textAlign: "left", color: "#fff", fontSize: "16px", width: "100%", height: "auto",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
         Analysis
        </button>
                            </StyledTableCell>
                            <StyledTableCell 
        
        className="cryptotableviewHeader" style={{ padding: 0, width: "8vw" ,minWidth:"10vw"}}>
                  <button
                    style={{
                    fontWeight: "500", textAlign: "left", color: "#fff", fontSize: "16px", width: "100%", height: "auto",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
         Order Book
        </button>
                            </StyledTableCell>
                            
                          </TableRow>
                        </TableHead>
                        {photogalleryloader==false&&  (
  <TableBody>
    {Array.from({ length: 7 }).map((_, index) => (
      <StyledTableRow key={index}>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        
        
      </StyledTableRow>
    ))}
  </TableBody>
)}

                       
                        {photogalleryloader && gallarydata.length>0 && (
                          <TableBody>
                            {gallarydata.slice(0, rowsToShow).map((obj, idx) => (
                            <StyledTableRow     
                            
                           
                            sx={{
                              backgroundColor: idx % 2 === 0 ? "white" : "#F0F0F0",
                              cursor: "pointer"
                            }}
              
                            className={mediaQueryVar ? 'clickable-row' : ''} key={idx} style={{ backgroundColor: idx % 2 === 0 ? "#FFF" : "#F6F6F6" }}>
                                 <StyledTableCell
        component="th"
        scope="row"
        style={{
          borderBottom: "none",
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          paddingLeft: "16px",
          paddingTop: "6px",
          paddingBottom: "6px",
          borderRight: "1px solid #DADEDF",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))',
            padding: "1px 7px 10px 15px",
            marginLeft: "-20px",
            marginRight: "-5px",
            marginBottom: "-5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // vertical alignment
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="btcParent">
             
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: "5px 7px", margin: "0 -5px -10px -20px" }}>
                    <Box>
                      {obj.photo ? (
                        <img
                          width={39.77}
                          height={38}
                          src={obj.photo}
                          style={{
                            borderRadius: "19px",
                            border: "2px solid black",
                          }}
                        />
                      ) : (
                        <img
                          width={39.77}
                          height={38}
                          src={'/images/avtar.jpeg'}
                          style={{
                            borderRadius: "19px",
                            border: "2px solid black",
                          }}
                        />
                      )}
                    </Box>
                    <Box sx={{ marginLeft: "5px", marginTop: "8px" }}>
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "5px",
                        }}
                      >
                        {obj.portfolio_name.replace(/-/g, " ")}
                      </Typography>
                  
                    </Box>
                  </Box>
                </div>
              
            </div>
          </div>
      
          
        </div>
      </StyledTableCell>
      <StyledTableCell
                                  className="one"
                                  style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingLeft: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                  {/* <div className="table_mobile">PRICE</div> */}
      
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>{obj.cal_roi}</span>
                                    {/* <span>
                                      Last Updated {obj.price_today_updated_on}
                                    </span> */}
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                  className="one"
                                  style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingLeft: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                  {obj.net_worth !== ''  ? obj.net_worth : 'N/A'}
                                </StyledTableCell>
                              
                                <StyledTableCell
                                   style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingLeft: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                  {/* <div className="table_mobile">ROI VALUE</div> */}
                                  <div
                                    // style={{
                                    //   color: `${obj.outlook_for_today[0].textcolor}`,
      
                                    //   fontWeight:
                                    //     `${obj.outlook_for_today[0].bold}` === "yes"
                                    //       ? "bold"
                                    //       : "",
                                    //   fontStyle:
                                    //     `${obj.outlook_for_today[0].itllic}` === "yes"
                                    //       ? "italic"
                                    //       : "",
                                    // }}
                                  >
                                
                                    {obj.total_net_profit !== ''  ? obj.total_net_profit : 'N/A'}
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                   style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "500",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                
                                <button
            style={{
              backgroundColor: '#FFFFFF',
              color: '#007BFF',
              border: '1px solid #007BFF',
              borderRadius: '48px',
              padding: '4px 10px',
              fontSize: '11px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#1877F2';
              e.target.style.color = '#FFFFFF';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#FFFFFF';
              e.target.style.color = '#007BFF';
            }}
            onClick={() =>
              navigate(`../portfolio/${obj.portfolio_name.replace(/ /g, '-')}/detailed-portfolio-analysis`)


            }
            
            
          >
            Analysis
          </button>
                     
                                </StyledTableCell>
                                <StyledTableCell
                                   style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "500",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                      textAlign:"center"
                                  }}
                                >
                                
                                <button
            style={{
              backgroundColor: '#FFFFFF',
              color: '#007BFF',
              border: '1px solid #007BFF',
              borderRadius: '48px',
              padding: '4px 10px',
              fontSize: '11px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#1877F2';
              e.target.style.color = '#FFFFFF';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#FFFFFF';
              e.target.style.color = '#007BFF';
            }}
            onClick={() =>
              navigate(`../portfolio/${obj.portfolio_name.replace(/ /g, '-')}/order-book`)


            }
          >
            Order Book
          </button>
                     
                                </StyledTableCell>
      
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                      {photogalleryloader && gallarydata.length === 0 && (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          <h4>No Data Found</h4>
                        </div>
                      )}
                       </div>
                       {rowsToShow < gallarydata.length && (
  <div className="loadingButton">
    <LoadingButton
      loading={addMoreLoader}
      loadingPosition="center"
      variant="outlined"
      style={{
        maxWidth: "360px",
        marginBottom: "20px",
        marginTop:"10px",
        width: mediaQueryVar === false ? "80vw" : "180px",
        backgroundColor: "rgba(67, 97, 238, 0.15)",
        borderRadius: "6px",
        border: "none",
        textTransform: "none",
        borderStyle: "solid",
        borderColor: "rgba(67, 97, 238, 0.15)",
        borderWidth: "1px",
        color: "#4361ee",
      }}
      onClick={handleLoadMore}
    >
      <span>Load More</span>
    </LoadingButton>
  </div>
)}
                    </TableContainer>

                     
                    
    )}
    {activeTab=="purchases" && gallarydata1.length > 0 && gallarydata1[0].paid_member=="yes" && (
      <TableContainer style={{ overflowX: "auto", borderRadius: "5px", margin: "15px 0px 20px 20px", width: "100%", paddingRight:"20px" }}>
      <div style={{ border: "1px solid #EEE", borderRadius: "5px", overflow: "hidden", width: "100%" }}>
                      <Table
                        className="non_responsiveTable" aria-label="customized table" 
                        style={{ borderCollapse: "collapse", width: "100%" }}
      
                      >
                        <TableHead>
                          <TableRow>
                          <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "30vw" }}>
                  <button disabled style={{
                    fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
                   Name
                  </button>
      
                            </StyledTableCell>
                            <StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width: "auto" ,minWidth:"8vw"}}>
                  <button disabled style={{
                    fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
          Date
          </button>
        
                            </StyledTableCell>
          <StyledTableCell 
         
          className="cryptotableviewHeader" style={{ padding: 0, width: "auto",minWidth:"8vw" }}>
                  <button 
                  
                 style={{
                    fontWeight: "500", textAlign: "left", color: "#727376", fontSize: "16px", width: "100%", height: "45px",
                    border: "none", background: "white", padding: "12px", margin: "0 2px 10px 0", boxShadow: "0px 2px 17px -4px rgba(45, 54, 67, 0.22)"
                  }}>
        Amount 
        </button>
        </StyledTableCell>
                        
                            
                          </TableRow>
                        </TableHead>
                        {watchlist_loader === false && userData && (
  <TableBody>
    {Array.from({ length: 1 }).map((_, index) => (
      <StyledTableRow key={index}>
        
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          <Stack>
            <Skeleton
              sx={{ marginTop: '10px' }}
              variant="rounded"
              height={40}
            />
          </Stack>
        </StyledTableCell>
        
        
      </StyledTableRow>
    ))}
  </TableBody>
)}

                       
                        {loader && (
                          <TableBody>
                            <StyledTableRow     
                            
                           
                            sx={{
                              backgroundColor: "white" ,
                              cursor: "pointer"
                            }}
              
                            className={mediaQueryVar ? 'clickable-row' : ''}  style={{ backgroundColor: "#FFF"  }}>
                                 <StyledTableCell
        component="th"
        scope="row"
        style={{
          borderBottom: "none",
          color: "#000",
          fontSize: "16px",
          fontWeight: "500",
          paddingLeft: "16px",
          paddingTop: "6px",
          paddingBottom: "6px",
          borderRight: "1px solid #DADEDF",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            background: 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))',
            padding: "1px 7px 10px 15px",
            marginLeft: "-20px",
            marginRight: "-5px",
            marginBottom: "-5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // vertical alignment
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="btcParent">
             
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', padding: "5px 7px", margin: "0 -5px -10px -20px" }}>
                   
                    <Box sx={{ marginLeft: "5px", marginTop: "8px", marginBottom:"8px" }}>
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "5px",
                        }}
                      >
                      {gallarydata1[0].subscription_type}
                      </Typography>
                  
                    </Box>
                  </Box>
                </div>
              
            </div>
          </div>
      
          
        </div>
      </StyledTableCell>
                                <StyledTableCell
                                  className="one"
                                  style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingLeft: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                                  }}
                                >
                                  {/* <div className="table_mobile">PRICE</div> */}
      
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span>{gallarydata1[0].subscribed_on ? formatDateTime(gallarydata1[0].subscribed_on) : 'N/A'}</span>
                                    {/* <span>
                                      Last Updated {obj.price_today_updated_on}
                                    </span> */}
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell
                                  className="one"
                                  style={{
                                    borderBottom: "none",
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      paddingLeft: "16px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      borderRight: "1px solid #DADEDF",
                                  }}
                                >
                                  {gallarydata1[0].amount}
                                </StyledTableCell>
                              
      
                              </StyledTableRow>
                          </TableBody>
                        )}
                      </Table>
                      {gallarydata1[0].paid_member=="no" && loader === true && (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          <h4>No Data Found</h4>
                        </div>
                      )}
                       </div>
                      
                    </TableContainer>
                     
                    
    )}
              {/* <div style={{
  display: "flex",
  flexWrap: "wrap",
  gap: "15px",
  justifyContent: "space-between",
  marginTop: "30px",
  marginLeft: "20px",
  marginRight: "20px",
  width: "calc(100% - 40px)", // Adjust width to account for margins
}}>
  <div style={{
    flex: "1 1 calc(20% - 10px)", // Adjusts to take one-third of the available width minus the gap
    minWidth: "250px", // Ensures a minimum width
    minHeight: "90px",
    height: "90px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    border: "1px solid #E4E4E4",
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/roadmap.svg" alt="Roadmap" style={{ marginRight: "30px", marginLeft: "10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap",
      }}>
        View Roadmap
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{ marginRight: "15px" }} />
  </div>

  <div style={{
    flex: "1 1 calc(20% - 10px)", // Adjusts to take one-third of the available width minus the gap
    minWidth: "250px", // Ensures a minimum width
    minHeight: "90px",
    height: "90px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    border: "1px solid #E4E4E4",
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/report.svg" alt="Report" style={{ marginRight: "30px", marginLeft: "10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap"
      }}>
        Access My Report
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{ marginRight: "15px" }} />
  </div>

  <Link to="/my-watchlist" style={{ textDecoration: 'none', flex: "1 1 calc(33.33% - 20px)", maxWidth: "calc(33.33% - 20px)", minWidth: "300px" }}>
    <div style={{
      minHeight: "90px",
      height: "90px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "5px 14px",
      borderRadius: "10px",
      backgroundColor: "white",
      boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
      alignItems: "center",
      border: "1px solid #E4E4E4",
    }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src="/images/watchlist.svg" alt="Watchlist" style={{ marginRight: "30px", marginLeft: "10px" }} />
        <Typography style={{
          color: "black",
          fontSize: "18px",
          whiteSpace: "nowrap"
        }}>
          Go To Watchlist
        </Typography>
      </div>
      <img src="/images/go.svg" alt="Go" style={{ marginRight: "15px" }} />
    </div>
  </Link>
</div> */}



              {/* <div style={{
                marginLeft:"20px",
                marginTop:"20px",
                width:"100%",
                marginRight:"20px"
              }}>
                <Typography style={{
                  marginBottom:"10px",
                  fontSize:"24px",
                  fontWeight:"600"
                }}>Milestone</Typography>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between" }}>
  <div class="milestone1" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>

    <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone1.svg" alt="Milestone1" style={{
          
          width: "76px",  // Adjust width as necessary
          height: "76px", // Adjust height as necessary
          clipPath: "circle(33% at 50% 50%)",
        }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone2" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone2.svg" alt="Milestone2" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone3" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone3.svg" alt="Milestone3" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone4" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone4.svg" alt="Milestone4" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>
</div>

                </div> */}
              

              {/* <Grid item xs={12} style={{marginLeft:"5px"}}>
      <Typography style={{ fontSize: "24px", fontWeight: "600", marginBottom:"10px" }}>Transaction History</Typography>
      <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Total Received in Farming</Typography>
          </CardContent>
          <img src="/images/handcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Buy Back History</Typography>
          </CardContent>
          <img src="/images/clockcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      </Grid>
    </Grid> */}

            </Grid>
          </Box>
        </div>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{snackbarmessage}</Alert>
        </Snackbar>
      </Box>
    </Box>
    ):(
      <Box
sx={{
display: "flex",
flexDirection: "column",
minHeight: "100vh", // Ensures it takes at least the full viewport height
flexGrow: 1, // Allows the Box to grow and fill the available space
overflowY: "auto", 
padding:1

}}
>
<Helmet>
        <title>Account</title>
</Helmet>
<CssBaseline />

<Box
component="nav"
sx={{
  width: "100%", // Take full width on mobile
}}
aria-label="mailbox folders"
>
<Drawer
  container={container}
  variant="temporary"
  open={mobileOpen}
  onClose={handleDrawerToggle}
  ModalProps={{
    keepMounted: true,
  }}
  sx={{
    "& .MuiDrawer-paper": {
      width: "80%", // Adjust width for mobile
      backgroundColor: "white",
      color: "#727376",
    },
  }}
>
  {drawer}
</Drawer>
</Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 1,
          paddingBottom: 3,
          paddingLeft: 1,
          paddingRight: 0,
          backgroundColor: "white",
          paddingBottom: "2vw",
          marginRight: "0px",
          overflow: "auto", // Allow scrolling
        }}
      >
        <div
          style={{
            backgroundColor: "#FFF",
           
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "5px",
                    marginBottom: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    style={{
                      fontFamily:"Satoshi, sans-serif",
                      marginTop:"-5px",
                      marginLeft:"-5px",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: "400",
                    }}
                  >
                    <div onClick={() => navigate("/")}>
                    <img src="/images/left-arrow.svg" alt ="profile_pic" style={{
    width: "20px", 
    height: "18px", 
    cursor:"pointer"
    }}
     ></img><span style={{marginLeft:"10px"}}>My Account</span></div>
                  </Typography>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { sm: "none" } }}
                  >
                    <img
                      src="/images/menubar.svg"
                      alt="Menu Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "10px",
                        marginTop: "-10px",
                      }}
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box
            
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
           
          >
            
            <Grid container spacing={2}>
             

            <Box style={{
  backgroundColor: "white",
  marginTop: "6px",
  marginLeft: "8px",
  marginRight: "0px",
  marginBottom: "0px",
  padding: "10px",
  overflow: "hidden",
  width: "100%",
}}>
  <div style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    marginBottom: "0px",
  }}>
    <div style={{
      gap: "20px",
      display: "inline-flex",
    }}>
      {profilePhoto ? (
        <img src={profilePhoto} alt="profile_pic" style={{
          width: "42px", height: "42px", borderRadius: "33px", border: "2px black solid"
        }} />
      ) : (
        <img src="/images/avtar.jpeg" alt="profile_pic" style={{
          width: "42px", height: "42px", borderRadius: "33px", border: "2px black solid"
        }} />
      )}

      <div style={{
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        display: "flex",
      }}>
        <div
  style={{
    color: "black",
    fontSize: "20px",
    fontWeight: "600",
    marginLeft: "-5px",
    fontFamily: "Satoshi, sans-serif",
    wordWrap: "break-word",
  }}
>
  {fullName ? (
    fullName
  ) : (
    <div
      style={{
        width: "30vw", // Adjust width to match the expected size of the text
        height: "24px", // Adjust height based on font size
        backgroundColor: "#E0E0E0", // Light gray color for skeleton
        borderRadius: "4px", // Optional: rounded corners
        animation: "skeleton-loading 1.5s infinite linear",
      }}
    ></div>
  )}
</div>

      </div>
    </div>

    <Button
      variant="outlined"
      style={{
        backgroundColor: 'white',
        textTransform: 'none',
        fontFamily: 'Satoshi, sans-serif',
        fontSize: '14px',
        fontWeight: '600',
        height: mediaQueryVar === false ? "35px" : "",
        borderColor:"white",
        padding:"0px"
      }}
      onClick={handleButtonClick}
      onMouseDown={(e) => {
        // Change button styles on click
      }}
      onMouseUp={(e) => {
        // Revert button styles when click is released
      }}
    >
      <img src="/images/edit-new.svg" style={{
        marginRight: "0px",
        height:"24px",
        width:"24px",
        marginLeft:"30px"
      }} />
    </Button>
  </div>

  <Dialog open={isDialogOpen} onClose={handleDialogClose}>
    <DialogTitle>Edit Profile</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Full Name"
        type="text"
        fullWidth
        value={fullName}
        onChange={handleNameChange}
      />
      <input
        accept="image/*"
        type="file"
        onChange={handlePhotoChange}
        style={{ display: 'none' }}
        id="profile-photo-upload"
      />
      <label htmlFor="profile-photo-upload">
        <Button variant="contained" color="primary" component="span">
          Upload Photo
        </Button>
      </label>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDialogClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleButtonSaveClick} color="primary">
        Save
      </Button>
    </DialogActions>
  </Dialog>

  <Snackbar
    open={snackbarOpen}
    autoHideDuration={6000}
    onClose={() => setSnackbarOpen(false)}
    message={snackbarMessage}
    severity={severity}
  />

</Box>

<div style={{backgroundColor:"#EDF1F6", height:"15px", width:"100%", marginBottom:"10px"}}></div>


  <Box style ={{
 backgroundColor:"white",
 marginTop:"0px",
 marginLeft:"8px",
 marginRight:"0px",
 marginBottom:"4px",
 marginTop:"0px",
 overflow:"hidden",
 width:"100%"
}}>
  
              
  <div style={{ marginBottom: '15px' }}>
      <div>
      <div
  style={{
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between Description and button
  }}
>
  <Typography
    style={{
      fontSize: "18px !important",
      fontWeight: "600",
      fontFamily: "Satoshi, sans-serif",
    }}
  >
    <span style={{fontSize:"18px"}}>Description</span>
  </Typography>

  <div
    style={{
      padding: "6px 14px",
      borderRadius: "48px",
      backgroundColor: "#1877F2",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }}
  >
    <button
      style={{
        background: "none",
        border: "none",
        display: "flex",
        alignItems: "center",
      }}
      onClick={isEditing ? handleSaveClick : handleEditClick}
    >
      <img
        src={isEditing ? "/images/save_link.svg" : "/images/wedit.svg"}
        style={{
          width: "12px",
          height: "12px",
          marginBottom: "2px",
          marginRight: "0px",
        }}
        alt="Edit Icon"
      />
    </button>
  </div>
</div>

        {isEditing ? (
          <TextField
            value={description}
            onChange={handleDescriptionChange}
            multiline
            
            rows={4}
            variant="outlined"
            style={{
              marginTop: '10px',
              fontFamily: 'Satoshi, sans-serif',
              fontSize: '16px',
              fontWeight: '500',
              width:"96%"
            }}
          />
        ) : (
          <Typography
            style={{
              color: '#7F7F80',
              fontSize: '17px',
              fontWeight: '500',
              fontFamily: 'Satoshi, sans-serif',
              wordWrap: 'break-word',
              marginTop: '10px',
              marginLeft:'6px'
            }}
          >
            {description}
          </Typography>
        )}
      </div>
    </div>
              

             

             
                <div>
                  <div>
                   
                    <div>

                    
    <div className="" style={{ marginTop: "0px", display: "" }}>
      {/* YouTube Block */}
      {/*  */}
   

      {/* Twitter Block */}
      <div style={{ marginLeft: "2px", marginTop:"1%", marginRight:"8px" }}>
  <div className="" style={{
    display: "flex",
    border:"2px solid #1877F2",
    alignItems: "center",
    padding: "5px",
    borderRadius: "46px",
    background: "#fff",
    marginBottom: "10px",
    gap: "10px",
    width: "100%", // Set the field width to 80% of the page
    margin: "0 auto", // Center the field horizontally
  }}>
    {/* Twitter Icon */}
    <img
      src="/images/twitter.svg"
      width="32px"
      height="32px"
      style={{
        border: "2px solid black",
        borderRadius: "50%",
        padding: "5px",
        marginLeft:"2px"
      }}
    />
    {/* Editable Field */}
    <div style={{
      display: "flex",
      alignItems: "center",
      flexGrow: 1, // Allow this div to expand as needed
      overflow: "hidden", // Prevent the content from overflowing the container
    }}>
      {isEditingTwitter ? (
        <input
          type="text"
          value={twitterLink}
          onChange={handleTwitterChange}
          placeholder="Add Link"
          style={{
            width: "100%",
            color: "black",
            backgroundColor: "#F7F7F7",
            border: "none",
            fontSize: "16px",
            fontFamily: "Satoshi, sans-serif",
            outline: "none",
            whiteSpace: "nowrap", // Prevent line break
            overflow: "hidden", // Hide overflowing content
          }}
        />
      ) : (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#9D9DA5",
            whiteSpace: "nowrap", // Prevent line break
            overflow: "hidden", // Hide overflowing content
          }}
        >
          {twitterLink || 'Add Link'}
        </div>
      )}
    </div>
    {/* Action Button */}
    <div style={{
      padding: "6px 14px",
      borderRadius: "48px",
      backgroundColor: "#1877F2",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }}>
      <button style={{
        background: "none",
        border: "none",
        display: "flex",
        alignItems: "center",
      }}
        onClick={isEditingTwitter ? (e) => handleTwitterSaveClick(e, 'twitter') : (e) => handleTwitterEditClick(e, 'twitter')}>
        <img
          src={isEditingTwitter ? "/images/save_link.svg" : "/images/wedit.svg"}
          style={{ width: "12px", height: "12px", marginBottom: "2px", marginRight: "0px" }}
          alt="Edit Icon"
        />
      </button>
    </div>
  </div>
</div>



      
    </div>

                  </div>
                </div>
              </div>
             
              </Box>
<div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      width: '100%', 
      maxWidth: '400px', 
      margin: '10px 10px 0 10px',
      marginBottom: '15px' ,
    }}>
      <label 
  htmlFor="userInput" 
  style={{ 
    marginBottom: '8px', 
    fontSize: '16px', 
    color: '#333',
    display: 'flex', // Ensures horizontal alignment
    alignItems: 'center',
    gap: '10px', // Adds space between text and button
  }}
>
  <span 
    style={{
      fontSize: '18px',
      fontWeight: '600',
      fontFamily: 'Satoshi, sans-serif',
    }}
  >
    Solana Wallet Address
  </span>

  <div
    style={{
      padding: "6px 14px",
      borderRadius: "48px",
      backgroundColor: "#1877F2",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }}
  >
    <button
      style={{
        background: "none",
        border: "none",
        display: "flex",
        alignItems: "center",
      }}
      onClick={handleSave}
    >
      <img
        src={isEditingTwitter ? "/images/save_link.svg" : "/images/save_link.svg"}
        style={{
          width: "12px",
          height: "12px",
          marginBottom: "2px",
          marginRight: "0px",
        }}
        alt="Edit Icon"
      />
    </button>
  </div>
</label>
      <input
        type="text"
        id="userInput"
        placeholder="Enter your Wallet Address Here"
        value={walletAddress}
        onChange={handleInputChange} 
        style={{
          background:'white',
          color:'black',
          padding: '10px',
          border: '2px solid #1877F2',
          borderRadius: '15px',
          fontSize: '16px',
          width: '100%',
          marginBottom:"1%",
          boxSizing: 'border-box',
        }}
      />

{isSaved && (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginTop: '10px', 
          color: 'green',
          fontFamily: 'Satoshi, sans-serif',
        }}>
          <span style={{ marginRight: '8px' }}>✔</span>
          <span>Your wallet address has been saved!</span>
        </div>
      )}
    </div>
   

        <div style={{
    backgroundColor: '#E3EEFD',
    padding: '5px 10px',
    borderRadius: '12px',
    marginTop: '10px',
    textAlign: 'center',
    marginBottom: '10px',
    position: 'relative',
    overflow: 'hidden',
    marginLeft: "10px",
    marginRight: "5px",
    width: "98%"
  }}>
  <h3 style={{
    fontSize: '18px',
    fontFamily: 'Satoshi, sans-serif',
    fontWeight: '700',
    color: '#444',
    marginBottom: '20px'
  }}>
    Referral Link
  </h3>
  <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    <input
      type="text"
      value={generateReferralLink()}
      readOnly
      style={{
        width: '100%',
        padding: '12px',
        border: 'none',
        borderRadius: '25px',
        fontSize: '16px',
        fontFamily: 'Satoshi, sans-serif',
        color: '#333',
        marginBottom: '5px',
        background: '#FFFFFF',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        marginRight: '10px'
      }}
      onFocus={(e) => e.target.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'}
      onBlur={(e) => e.target.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.1)'}
    />
    <img
      src="/images/copy-new.svg"
      alt="Copy"
      style={{
        cursor: 'pointer',
        width: '24px',
        height: '24px',
        marginBottom: '20px',
        transition: 'transform 0.2s ease'
      }}
      onClick={() => navigator.clipboard.writeText(generateReferralLink())}
      onMouseEnter={(e) => e.target.style.transform = 'scale(1.1)'}
      onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
    />
  </div>
</div>
<div style={{backgroundColor:"#EDF1F6", height:"15px", width:"100%", marginBottom:"0px"}}></div>
<div
  style={{
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    marginTop: '10px',
    marginLeft: '0',
    marginRight: '0',
    width: '100vw', // Takes full width of the viewport
    padding: '0 5px 0 13px', // Optional padding for inner content
    boxSizing: 'border-box', // Ensures padding doesn't affect width
  }}
>
  {/* Tab Buttons */}
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
      gap:"10px"
    }}
  >
    <button
        style={{
          background:
            activeTab === 'myPortfolios'
              ? 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))'
              : '#fff',
          color: '#000',
          border: '1px solid #1877F2',
          padding: '2px 5px',
          borderRadius: '6px',
          fontSize: '13px',
          fontWeight: activeTab === 'myPortfolios' ? '700' : '500',
          cursor: 'pointer',
          flexGrow: 1,
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
        onClick={() => handleTabChange2('myPortfolios')} // Handle click for this button
      >
        My Portfolios
      </button>

      <button
        style={{
          background:
            activeTab === 'portfolioWatchlist'
              ? 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))'
              : '#fff',
          color: '#000',
          border: '1px solid #1877F2',
          padding: '2px 5px',
          borderRadius: '6px',
          fontSize: '13px',
          fontWeight: activeTab === 'portfolioWatchlist' ? '700' : '500',
          cursor: 'pointer',
          flexGrow: 1,
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
        onClick={() => handleTabChange2('portfolioWatchlist')} // Handle click for this button
      >
        Portfolio Watchlist
      </button>

      <button
        style={{
          background:
            activeTab === 'purchases'
              ? 'var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))'
              : '#fff',
          color: '#000',
          border: '1px solid #1877F2',
          padding: '2px 5px',
          borderRadius: '6px',
          fontSize: '13px',
          fontWeight: activeTab === 'purchases' ? '700' : '500',
          cursor: 'pointer',
          flexGrow: 1,
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
        onClick={() => handleTabChange2('purchases')} // Handle click for this button
      >
        Purchases
      </button>
  </div>

  {/* Portfolio Cards */}
  <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginBottom:"50px"
  }}
>
{activeTab === 'myPortfolios' ? (
  slugdata.length > 0 ? (
    <>
      {slugdata.slice(0, rowsToShow).map((obj, idx) => (
        <div
        key={idx}
        style={{
          backgroundColor: '#E5F3FF',
          borderRadius: '8px',
          padding: '8px 5px',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #1877F2',
        }}
      >
        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {obj.photo ? (
              <img
                width={39.77}
                height={38}
                src={obj.photo}
                style={{
                  borderRadius: "19px",
                  border: "2px solid black",
                }}
              />
            ) : (
              <img
                width={39.77}
                height={38}
                src={'/images/avtar.jpeg'}
                style={{
                  borderRadius: "19px",
                  border: "2px solid black",
                }}
              />
            )}
            <div
              style={{
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              {obj.portfolio_name}
            </div>
          </div>
        </div>

        {/* Stats */}
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px 8px',
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              fontSize: '14px',
              marginBottom: "5px",
            }}
          >
            <div>ROI</div>
            <div style={{ color: '#000', fontWeight: '600' }}>{obj.cal_roi}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px 8px',
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              fontSize: '14px',
              marginBottom: "5px",
            }}
          >
            <div>Current Value</div>
            <div style={{ color: '#000', fontWeight: '600' }}>{obj.net_worth}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px 8px',
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              fontSize: '14px',
              marginBottom: "5px",
            }}
          >
            <div style={{ color: '#008000', fontWeight: 'bold' }}>Gain</div>
            <div style={{ color: '#000', fontWeight: 'bold' }}>{obj.total_net_profit}</div>
          </div>
        </div>

        {/* Buttons */}
        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '6px',
          }}
        >
          <button
            style={{
              backgroundColor: '#FFFFFF',
              color: '#007BFF',
              border: '1px solid #007BFF',
              borderRadius: '48px',
              padding: '4px 10px',
              fontSize: '11px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#1877F2';
              e.target.style.color = '#FFFFFF';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#FFFFFF';
              e.target.style.color = '#007BFF';
            }}
            onClick={() =>
              navigate(`../my-portfolio/${obj.portfolio_type_id}/${obj.portfolio_name.replace(/ /g, '-')}/detailed-portfolio-analysis`)


            }
            
            
          >
            Analysis
          </button>
          <button
            style={{
              backgroundColor: '#FFFFFF',
              color: '#007BFF',
              border: '1px solid #007BFF',
              borderRadius: '48px',
              padding: '4px 10px',
              fontSize: '11px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#1877F2';
              e.target.style.color = '#FFFFFF';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#FFFFFF';
              e.target.style.color = '#007BFF';
            }}
            onClick={() =>
              navigate(`../my-portfolio/${obj.portfolio_type_id}/${obj.portfolio_name.replace(/ /g, '-')}/order-book`)


            }
          >
            Order Book
          </button>
        </div>
      </div>
      ))}

      {rowsToShow < slugdata.length && (
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <LoadingButton
            loading={addMoreLoader}
            loadingPosition="center"
            variant="outlined"
            style={{
              maxWidth: "360px",
              width: "80vw",
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              marginBottom: "80px",
            }}
            onClick={handleLoadMore}
          >
            <span style={{ color: "#4361ee" }}>Load More</span>
          </LoadingButton>
        </Box>
      )}
    </>
  ) : (
    // Skeleton Loader for My Portfolios
    Array(5)
      .fill()
      .map((_, idx) => (
        <div
          key={idx}
          style={{
            backgroundColor: '#E5F3FF',
            borderRadius: '8px',
            padding: '8px 5px',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #1877F2',
            opacity: 0.6,
          }}
        >
          <div
            style={{
              height: '25px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '20px',
            }}
          ></div>
          <div
            style={{
              height: '15px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
          ></div>
          <div
            style={{
              height: '15px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
          ></div>
          <div
            style={{
              height: '15px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
          ></div>
        </div>
      ))
  )
) : null}
{activeTab === 'portfolioWatchlist' ? (
  photogalleryloader && slugdata.length > 0 ? (
    <>
      {gallarydata.slice(0, rowsToShow).map((obj, idx) => (
        <div
        key={idx}
        style={{
          backgroundColor: '#E5F3FF',
          borderRadius: '8px',
          padding: '8px 5px',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #1877F2',
          
        }}
      >
        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {obj.photo ? (
              <img
                width={39.77}
                height={38}
                src={obj.photo}
                style={{
                  borderRadius: "19px",
                  border: "2px solid black",
                }}
              />
            ) : (
              <img
                width={39.77}
                height={38}
                src={'/images/avtar.jpeg'}
                style={{
                  borderRadius: "19px",
                  border: "2px solid black",
                }}
              />
            )}
            <div
              style={{
                fontSize: '16px',
                fontWeight: '600',
              }}
            >
              {obj.portfolio_name}
            </div>
          </div>
        </div>

        {/* Stats */}
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px 8px',
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              fontSize: '14px',
              marginBottom: "5px",
            }}
          >
            <div>ROI</div>
            <div style={{ color: '#000', fontWeight: '600' }}>{obj.cal_roi}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px 8px',
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              fontSize: '14px',
              marginBottom: "5px",
            }}
          >
            <div>Current Value</div>
            <div style={{ color: '#000', fontWeight: '600' }}>{obj.net_worth}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px 8px',
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              fontSize: '14px',
              marginBottom: "5px",
            }}
          >
            <div style={{ color: '#008000', fontWeight: 'bold' }}>Gain</div>
            <div style={{ color: '#000', fontWeight: 'bold' }}>{obj.total_net_profit}</div>
          </div>
        </div>

        {/* Buttons */}
        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '6px',
          }}
        >
          <button
            style={{
              backgroundColor: '#FFFFFF',
              color: '#007BFF',
              border: '1px solid #007BFF',
              borderRadius: '48px',
              padding: '4px 10px',
              fontSize: '11px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#1877F2';
              e.target.style.color = '#FFFFFF';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#FFFFFF';
              e.target.style.color = '#007BFF';
            }}
            onClick={() =>
              navigate(`../portfolio/${obj.portfolio_name.replace(/ /g, '-')}/detailed-portfolio-analysis`)


            }
            
          >
            Analysis
          </button>
          <button
            style={{
              backgroundColor: '#FFFFFF',
              color: '#007BFF',
              border: '1px solid #007BFF',
              borderRadius: '48px',
              padding: '4px 10px',
              fontSize: '11px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#1877F2';
              e.target.style.color = '#FFFFFF';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#FFFFFF';
              e.target.style.color = '#007BFF';
            }}
            onClick={() =>
              navigate(`../portfolio/${obj.portfolio_name.replace(/ /g, '-')}/order-book`)


            }
          >
            Order Book
          </button>
        </div>
      </div>
      ))}

      {rowsToShow < gallarydata.length && (
        <Box sx={{ textAlign: "center", mt: 2 }}>
          <LoadingButton
            loading={addMoreLoader}
            loadingPosition="center"
            variant="outlined"
            style={{
              maxWidth: "360px",
              width: "80vw",
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              marginBottom: "80px",
            }}
            onClick={loadMoregallery}
          >
            <span style={{ color: "#4361ee" }}>Load More</span>
          </LoadingButton>
        </Box>
      )}
    </>
  ) : (
    // Skeleton Loader for Portfolio Watchlist
    Array(5)
      .fill()
      .map((_, idx) => (
        <div
          key={idx}
          style={{
            backgroundColor: '#E5F3FF',
            borderRadius: '8px',
            padding: '8px 5px',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #1877F2',
            opacity: 0.6,
          }}
        >
          <div
            style={{
              height: '25px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '20px',
            }}
          ></div>
          <div
            style={{
              height: '15px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
          ></div>
          <div
            style={{
              height: '15px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
          ></div>
          <div
            style={{
              height: '15px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
          ></div>
        </div>
      ))
  )
) : null}

{activeTab === 'purchases' ? (
  gallarydata1.length > 0 && gallarydata1[0].paid_member=="yes" ? (
    <>
      {gallarydata1.map((obj, idx) => (
        <div
        key={idx}
        style={{
          backgroundColor: '#E5F3FF',
          borderRadius: '8px',
          padding: '8px 5px',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #1877F2',
        }}
      >
        {/* Header */}
  

        {/* Stats */}
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px 8px',
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              fontSize: '14px',
              marginBottom: "5px",
            }}
          >
            <div>Name</div>
            <div style={{ color: '#000', fontWeight: '600' }}>{gallarydata1[0].subscription_type}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px 8px',
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              fontSize: '14px',
              marginBottom: "5px",
            }}
          >
            <div>Date</div>
            <div style={{ color: '#000', fontWeight: '600' }}>{gallarydata1[0].subscribed_on ? formatDateTime(gallarydata1[0].subscribed_on) : 'N/A'}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '2px 8px',
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              fontSize: '14px',
              marginBottom: "5px",
            }}
          >
            <div style={{ color: '#008000', fontWeight: 'bold' }}>Amount</div>
            <div style={{ color: '#000', fontWeight: 'bold' }}>{gallarydata1[0].amount}</div>
          </div>
        </div>

        {/* Buttons */}
      </div>
      ))}

    
    </>
  ) : (
    // Skeleton Loader for Purchases
    Array(5)
      .fill()
      .map((_, idx) => (
        <div
          key={idx}
          style={{
            backgroundColor: '#E5F3FF',
            borderRadius: '8px',
            padding: '8px 5px',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #1877F2',
            opacity: 0.6,
            marginBottom: idx === 4 ? '20px' : '0', // Adds marginBottom to the last skeleton loader item
          }}
        >
          <div
            style={{
              height: '15px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
          ></div>
          <div
            style={{
              height: '10px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '5px',
            }}
          ></div>
          <div
            style={{
              height: '10px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '5px',
            }}
          ></div>
          <div
            style={{
              height: '10px',
              backgroundColor: '#ccc',
              borderRadius: '4px',
              marginBottom: '5px',
            }}
          ></div>
        </div>
      ))
  )
) : null}


</div>

</div>



  {/* <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between", marginTop: "30px", marginLeft: "10px", width: "100%",marginRight:"20px" }}>
  <div style={{
    width: "360px",
    minHeight: "80px",
    height: "80px",
    display: "flex",
    flexDirection: "row",
    marginRight:"10px",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    border: "1px solid #E4E4E4",
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/roadmap.svg" alt="Roadmap" style={{ marginRight: "30px",marginLeft:"10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap",
        
      }}>
        View Roadmap
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{  marginRight: "15px"}}/>
  </div>

  <div style={{
    display: "flex",
    width: "360px",
    minHeight: "80px",
    height: "80px",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    marginRight:"10px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    border: "1px solid #E4E4E4",
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/report.svg" alt="Report" style={{  marginRight: "30px",marginLeft:"10px"}} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap"
      }}>
        Access My Report
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{  marginRight: "15px"}}/>
  </div>
  <Link to="/my-watchlist" style={{ textDecoration: 'none' }}>
  <div style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    width: "340px",
    minHeight: "80px",
    height: "80px",
    marginRight:"20px",
    border: "1px solid #E4E4E4"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/watchlist.svg" alt="Watchlist" style={{  marginRight: "30px",marginLeft:"10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap"
      }}>
        Go To Watchlist
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{  marginRight: "15px"}} />
  </div>
  </Link>
</div> */}


              {/* <div style={{
                marginLeft:"20px",
                marginTop:"20px",
                width:"100%",
                marginRight:"20px"
              }}>
                <Typography style={{
                  marginBottom:"10px",
                  fontSize:"20px",
                  fontWeight:"700"
                }}>Milestone</Typography>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between" }}>
  <div class="milestone1" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
   <div style={{ display: "flex", alignItems: "center" }}>

<div style={{ marginRight: "10px", position: "relative" }}>
    <img src="/images/milestone1.svg" alt="Milestone1" style={{
      
      width: "76px",  // Adjust width as necessary
      height: "76px", // Adjust height as necessary
      clipPath: "circle(33% at 50% 50%)",
    }}></img>
    <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
  </div>
  
  <Typography style={{
    borderRadius: "10px",
    padding: "10px 26px",
    background: "rgba(226.08, 236, 112, 0.14)",
    color: "#A87727",
    fontSize: "12px",
    whiteSpace: "nowrap"
  }}>
    Yet to complete
  </Typography>
</div>
</div>

  <div class="milestone2" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone2.svg" alt="Milestone2" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone3" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone3.svg" alt="Milestone3" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone4" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone4.svg" alt="Milestone4" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>
</div>

                </div> */}
              

              {/* <Grid item xs={12} style={{marginLeft:"5px",marginBottom:"60px"}}>
      <Typography style={{ fontSize: "20px", fontWeight: "700", marginBottom:"10px" }}>Transaction History</Typography>
      <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Total Received in Farming</Typography>
          </CardContent>
          <img src="/images/handcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Buy Back History</Typography>
          </CardContent>
          <img src="/images/clockcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      </Grid>
    </Grid> */}

            </Grid>
          </Box>
        </div>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{snackbarmessage}</Alert>
        </Snackbar>
      </Box>
      <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "105%",
            zIndex: "100",
            marginLeft:"-10px"
          }}
        >

      <BottomNavigation showLabels>
      <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => {
          navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
        }}
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
    </BottomNavigation>
        </div>
        {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    </Box>

    

)}
</>


  );
  
 
}
  
export default Account;
