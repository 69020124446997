import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Drawer from '@mui/material/Drawer';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoginPopUp from './loginpopup';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip, Button } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import { Helmet } from 'react-helmet';
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import { useLocation } from "react-router-dom";
const drawerWidth = 292;


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PortfolioNetworthGraph = (props) => {
  const { window1 } = props;
  const location = useLocation(); 
  const loc = location.pathname;
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

  const [chartData, setChartData] = useState({});
  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [roiData, setRoiData] = useState({});
  const container = window1 !== undefined ? () => window1().document.body : undefined;
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [thesisContent, setThesisContent] = useState('');
  const [retrievalLoading, setRetrievalLoading] = useState(true);
 
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};
const [data, setData] = useState(null);
const [loading, setLoading] = useState(true);
const drawer = (
    <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
    </div>
);

const mPortfolioName = 'CW360 Memecoin Index';


const fetchDescription = async () => {
  console.log(mPortfolioName);
  const portfolioTitle = mPortfolioName;
  try {
    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/getThesisDescription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ portfolioTitle }),
    });
    const result = await response.json();
    console.log("Thesis Data",result);
    if (result.code === '200') {
      const formattedDescription = result.description.replace(/\\n/g, "\n"); 
      setThesisContent(formattedDescription); // Assuming the API returns the description field
    }
  } catch (error) {
    console.error('Error fetching description:', error);

  } finally {
    setRetrievalLoading(false);
  }
};

useEffect(() => {
  fetchDescription();
}, [mPortfolioName]);

  const chartRef = useRef(null);  // Create a ref for the chart

  useEffect(() => {

    setTimeout(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://crowdwisdom.live/wp-json/api/v1/portfolio_networth_graph_api',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
          }
        );
  
        if (response.ok) {
          const data = await response.json(); // Parse the JSON response
  
          if (Array.isArray(data)) {
            console.log("Response Data:", data[0].full_name);
          
            const processedData = processApiData(data);
  
            setChartData(processedData.chartData);
            setRoiData(processedData.roiData);
            setDropdownOptions(processedData.dropdownOptions);
            setSelectedPortfolio(processedData.firstPortfolioId);
            setData(processedData);
            setLoading(false); 
          } else {
            console.error('Error: Unexpected data structure');
          }
        } else {
          console.error('Error: Unexpected response code', response.status);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };
  
    fetchData();
  }, 2000);
  }, []);
  

  const processApiData = (results) => {
    const data = {};
    const roiData = {};
    const dropdownOptions = [];
    const uniquePortfolios = new Set();
    let firstPortfolioId = null;

    results.forEach((row) => {
      const formattedDate = new Date(row.roi_date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      });

      if (!data[row.portfolio_type_id]) {
        data[row.portfolio_type_id] = {
          dates: [],
          values: [],
        };
      }

      data[row.portfolio_type_id].dates.push(formattedDate);
      data[row.portfolio_type_id].values.push(parseFloat((row.net_worth / 2500).toFixed(1)));


      roiData[row.portfolio_type_id] = {
        full_name: row.full_name || 'Portfolio',
        portfolio_name: row.portfolio_name || 'Portfolio',
        one_day_roi: row.one_day_roi || 'NA',
        seven_day_roi: row.seven_day_roi || 'NA',
        thirty_day_roi: row.thirty_day_roi || 'NA',
        ninety_day_roi: row.ninety_day_roi || 'NA',
      };

      if (!uniquePortfolios.has(row.portfolio_type_id)) {
        uniquePortfolios.add(row.portfolio_type_id);

        if (!firstPortfolioId) {
          firstPortfolioId = row.portfolio_type_id;
        }

        const displayName = `${row.portfolio_name || 'Unknown Portfolio'}`;
        dropdownOptions.push({
          id: row.portfolio_type_id,
          name: displayName,
        });
      }
    });

    return {
      chartData: data,
      roiData,
      dropdownOptions,
      firstPortfolioId,
    };
  };

  const handlePortfolioChange = (event) => {
    const selectedPortfolioId = event.target.value;
    const selectedOption = dropdownOptions.find(option => option.id === selectedPortfolioId);
   
    if (selectedOption) {
      navigate(`/indices/${selectedOption.name}-index`);
      window.location.reload(); 
    }
  };

  const getChartOptions = (isMobile) => ({
    scales: {
      x: {
        title: {
          display: true,
          text: '',
          font: { size: isMobile ? 10 : 14, family: 'Arial', weight: 'bold' },
          color: '#4A4A4A',
        },
        ticks: { font: { size: isMobile ? 8 : 12, family: 'Arial' }, color: '#4A4A4A',
        autoSkip: true, // Automatically skip ticks
        maxTicksLimit: Math.floor((isMobile ? 7 : 14) ), 
       },
        
        grid: { display: false },
        
      },
      y: {
        title: {
          display: true,
          text: 'Index Value',
          font: { size: isMobile ? 10 : 14, family: 'Arial', weight: 'bold' },
          color: '#4A4A4A',
        },
        ticks: {
          font: { size: isMobile ? 8 : 12, family: 'Arial' },
          color: '#4A4A4A',
          stepSize: isMobile ? 25 : 50,
        },
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0.05)', // Subtle grid color
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            family: 'Arial',
            size: isMobile ? 12 : 14,
          },
          color: '#4A4A4A',
        },
      },
      title: {
        display: true,
        // text: 'Portfolio Net Worth',
        font: { size: isMobile ? 16 : 20, family: 'Arial', weight: 'bold' },
        color: '#333333',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `Index Value: ${tooltipItem.raw}`;
          },
        },
        backgroundColor: '#ffffff',
        titleFont: {
          family: 'Arial',
          size: 14,
        },
        bodyFont: {
          family: 'Arial',
          size: 12,
        },
        borderColor: '#333',
        borderWidth: 1,
        titleColor: '#4A4A4A',
        bodyColor: '#4A4A4A',
      },
    },
    elements: {
      point: {
        radius: 0, // Remove dots
      },
      line: {
        tension: 0.5, // Smooth the line further
        backgroundColor: '#7e7ef7',
      },
    },
    interaction: {
      mode: 'nearest', // Show the tooltip for the nearest point
      axis: 'x', // Tooltip should follow the x-axis
      intersect: false, // Tooltip will show even if not hovering over a data point
    },
  });

  // Create a gradient color for the chart background
  const createGradient = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(31, 119, 180, 0.4)');
    gradient.addColorStop(1, 'rgba(31, 119, 180, 0)');
    return gradient;
  };

  const isMobile = window.innerWidth <= 768;
  const selectedChartData = chartData[selectedPortfolio];

 



  return (
    <div className="Indices-page" 
    style={{
      height:"100vh",
      backgroundColor: mediaQueryVar ?"#EDF1F6":"#fff",
      fontFamily : "Satoshi, sans-serif",
      marginBottom: mediaQueryVar?"":"20%"
      }}>
    <Helmet>
     <title>Crowdwisdom360 Crypto Indices</title>
</Helmet>


<Grid item xs={12} align="left">
    <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "space-between", // Changed from space-between to flex-end
        marginTop: "0px", 
        marginBottom: "10px",
        marginLeft: "5px",
        
    }}>
       
    {mediaQueryVar===false ? (
        <Typography
        variant="h6"
        component="div"
        style={{
          fontFamily: "Satoshi, sans-serif",
          fontWeight: 500,
          fontSize: "24px",
          margin:"5px"
          
        }}
      > Cryptocurrency
        <span style={{
          backgroundColor:"#1877f2",
          padding:"4px 4px",
          borderRadius:"5px",
          color:"white",
          marginLeft:'3px',
          
        }}> Indices</span>
      </Typography>
    ) : (
      <Typography
      variant="h6"
      noWrap
      component="div"
      style={{
        textAlign: mediaQueryVar === false ? "center" : "left",
        marginTop: mediaQueryVar === false ? "60px" : "20px",
        marginLeft:"21%",
        marginBottom:"-20px"
      }}
    >
       <span style={{ fontSize: "24px", fontFamily: "Satoshi,sans-serif", fontWeight: "700", marginLeft: "20px" }}>
      Cryptocurrency Indices
        </span>
    </Typography>
    )}
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "24px", 
                    height: "24px",
                    marginRight: "10px",
                    marginTop: "10px" 
                }} 
            />
        </IconButton>
    </div>
</Grid>
<Drawer
    container={container}
    variant="temporary"
    open={mobileOpen}
    onClose={handleDrawerToggle}
    ModalProps={{
        keepMounted: true, // Better open performance on mobile.
    }}
    sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: "400", backgroundColor: 'white', color: '#727376' },
    }}
>
    {drawer}
</Drawer>

{mediaQueryVar && (
  <div>
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: 'white',
            color: '#727376',
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: 'white',
            color: '#727376',
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  </div>
)}

    <div
      className="portfolio-networth-container"
      style={{
        padding:  mediaQueryVar ?'30px':'10px',
        background: '#fff',
        borderRadius: '15px',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
        marginTop: '40px',
        marginLeft: mediaQueryVar ?'340px':'0',
        marginRight: mediaQueryVar ?'50px':'0',
        maxWidth: mediaQueryVar ?'75%':'100%',
        
      }}
    >
      {/* Dropdown for portfolio selection */}
      <div className="dropdown-container" style={{ marginBottom: '20px' }}>
        <label
          htmlFor="portfolio-select"
          style={{ marginRight: '10px', fontWeight: 'bold', fontSize: '16px', color: '#4A4A4A' }}
        >
          Select Index:
        </label>
        <select
          id="portfolio-select"
          value={selectedPortfolio}
          onChange={handlePortfolioChange}
          style={{
            padding: '10px',
            borderRadius: '8px',
            fontSize: '16px',
            background: '#fff',
            border: '1px solid #ddd',
            color: '#4A4A4A',
          }}
        >
          <option value=""> Memecoin </option>
          {dropdownOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>

      <div
  style={{
    marginBottom: '20px',
    overflow: 'hidden',
    position: 'relative',
    border: '2px solid #7e7ef7',
    height: mediaQueryVar ? '450px' : '300px',
    borderRadius: '20px',
    padding: '10px',
  }}
>
  {loading && (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        color: '#7e7ef7',
      }}
    >
      {/* Loading spinner */}
      <div
        style={{
          border: '4px solid rgba(0, 0, 0, 0.1)',
          borderTop: '4px solid #7e7ef7', // Spinner color
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          marginLeft: '50%',
          animation: 'spin 1s linear infinite',
        }}
      />
      <p
        style={{
          fontWeight: 'bold',
          fontSize: '18px',
          marginTop: '10px',
        }}
      >
        Rendering Memecoin Index...
      </p>
    </div>
  )}
  <Line
    ref={chartRef}
    data={{
      labels: selectedChartData ? selectedChartData.dates : [], // Empty if no data
      datasets: [
        {
          label:
            selectedChartData && roiData[selectedPortfolio]
              ? roiData[selectedPortfolio]?.portfolio_name + ' Index'
              : 'Memecoin Index',
          data: selectedChartData ? selectedChartData.values : [], // Empty if no data
          fill: true,
          borderColor: '#7e7ef7',
          backgroundColor: '#7e7ef7',
          borderWidth: 2,
        },
      ],
    }}
    options={getChartOptions(isMobile)}
  />
</div>
      {/* ROI Summary */}
      {selectedPortfolio && (
  mediaQueryVar ? (
    <div
      className="roi-summary-container"
      style={{
        marginTop: '20px',
        border: '1px solid #b6b6b6',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          textAlign: 'center',
        }}
      >
        <thead
          style={{ backgroundColor: '#f8f8f8', fontWeight: 'bold' }}
        >
          <tr>
            <th
              style={{
                padding: '0px',
                borderRight: '1px solid #b6b6b6',
              }}
            >
              <button
                disabled
                style={{
                  textAlign: 'center',
                  color: '#fff',
                  width: '99%',
                  height: '100%',
                  border: 'none',
                  background: 'white',
                  padding: '12px 12px',
                  height:'48.5px',
                  margin: '0px 2px 10px 2px',
                  boxShadow: '0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)',
                }}
              >
             
              </button>
            </th>
            <th style={{ padding: '0px', borderRight: '1px solid #b6b6b6' }}>
              <button
                disabled
                style={{
                  textAlign: 'center',
                  color: '#727376',
                  width: '99%',
                  height: '100%',
                  border: 'none',
                  background: 'white',
                  padding: '12px 12px',
                  margin: '0px 2px 10px 2px',
                  boxShadow: '0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)',
                }}
              >
                Last 1 Day
              </button>
            </th>
            <th style={{ padding: '0px', borderRight: '1px solid #b6b6b6' }}>
              <button
                disabled
                style={{
                  textAlign: 'center',
                  color: '#727376',
                  width: '99%',
                  height: '100%',
                  border: 'none',
                  background: 'white',
                  padding: '12px 12px',
                  margin: '0px 2px 10px 2px',
                  boxShadow: '0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)',
                }}
              >
                Last 7 Days
              </button>
            </th>
            <th style={{ padding: '0px', borderRight: '1px solid #b6b6b6' }}>
              <button
                disabled
                style={{
                  textAlign: 'center',
                  color: '#727376',
                  width: '99%',
                  height: '100%',
                  border: 'none',
                  background: 'white',
                  padding: '12px 12px',
                  margin: '0px 2px 10px 2px',
                  boxShadow: '0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)',
                }}
              >
                Last 30 Days
              </button>
            </th>
            <th style={{ padding: '0px' }}>
              <button
                disabled
                style={{
                  textAlign: 'center',
                  color: '#727376',
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  background: 'white',
                  padding: '12px 12px',
                  margin: '0px 2px 10px 2px',
                  boxShadow: '0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)',
                }}
              >
                Last 90 Days
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ padding: '10px', borderRight: '1px solid #b6b6b6' }}>
              <a
                href={`https://investing.crowdwisdom.live/portfolio/${roiData[selectedPortfolio]?.full_name}`}
                style={{
                  color: '#1a73e8',
                  textDecoration: 'underline',
                }}
              >
                {roiData[selectedPortfolio]?.portfolio_name} Index Performance
              </a>
            </td>
            <td style={{ padding: '10px', borderRight: '1px solid #b6b6b6' }}>
              {roiData[selectedPortfolio]?.one_day_roi}
            </td>
            <td style={{ padding: '10px', borderRight: '1px solid #b6b6b6' }}>
              {roiData[selectedPortfolio]?.seven_day_roi}
            </td>
            <td style={{ padding: '10px', borderRight: '1px solid #b6b6b6' }}>
              {roiData[selectedPortfolio]?.thirty_day_roi}
            </td>
            <td style={{ padding: '10px' }}>
              {roiData[selectedPortfolio]?.ninety_day_roi}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    

    
  ) : (
    <div
  className="index"
  style={{
    border: '2px solid #ececec', // Light border
    borderRadius: '10px',
    padding: '15px',
    maxWidth: '300px',
    fontFamily: 'Satoshi, sans-serif',
    width: '100%',
    margin: '5px auto',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Light gray shadow
    backgroundColor: '#fff', // White background
  }}
>
  <p
    style={{
      textAlign: 'center',
      color: '#007BFF',
      fontSize: '16px',
      fontWeight: 'bold',
      marginTop: 0,
      marginBottom: '5px',
    }}
  >
    <a
      href={`https://investing.crowdwisdom.live/portfolio/${roiData[selectedPortfolio]?.full_name}`}
      style={{
        color: '#1a73e8',
        textDecoration: 'underline',
      }}
    >
      {roiData[selectedPortfolio]?.portfolio_name} Index Performance
    </a>
  </p>

  <div className="index" style={{ padding: '10px 0', borderBottom: '1px solid #000', display: 'flex', justifyContent: 'space-between' }}>
    <span style={{ color: '#606060', fontSize: '16px' }}>Last 1 Day</span>
    <span style={{ color: '#007BFF', fontSize: '16px', fontWeight: 'bold' }}>
      {roiData[selectedPortfolio]?.one_day_roi}
    </span>
  </div>

  <div className="index" style={{ padding: '10px 0', borderBottom: '1px solid #000', display: 'flex', justifyContent: 'space-between' }}>
    <span style={{ color: '#606060', fontSize: '16px' }}>Last 7 Days</span>
    <span style={{ color: '#007BFF', fontSize: '16px', fontWeight: 'bold' }}>
      {roiData[selectedPortfolio]?.seven_day_roi}
    </span>
  </div>

  <div className="index" style={{ padding: '10px 0', borderBottom: '1px solid #000', display: 'flex', justifyContent: 'space-between' }}>
    <span style={{ color: '#606060', fontSize: '16px' }}>Last 30 Days</span>
    <span style={{ color: '#007BFF', fontSize: '16px', fontWeight: 'bold' }}>
      {roiData[selectedPortfolio]?.thirty_day_roi}
    </span>
  </div>

  <div className="index" style={{ padding: '10px 0', display: 'flex', justifyContent: 'space-between' }}>
    <span style={{ color: '#606060', fontSize: '16px' }}>Last 90 Days</span>
    <span style={{ color: '#007BFF', fontSize: '16px', fontWeight: 'bold' }}>
      {roiData[selectedPortfolio]?.ninety_day_roi}
    </span>
  </div>
</div>

  )
)}

{/* <Box
  component="main"
  sx={{
    flexGrow: 1,
    p: 3,
    fontFamily : "Satoshi, sans-serif",
    margin: mediaQueryVar? "20px 2px 25px 2px":"20px 10px 60px 10px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "24px",
    backgroundColor: "#fff",
    color: "#333",
    position: "relative",
    textTransform: "none",
  }}
>
  <h3 style={{ fontWeight: "600", color: "black", fontSize: "20px" }}>
    Thesis
  </h3>

  {retrievalLoading ? (
    <p>Loading Thesis...</p>
  ) : (
    <div
      style={{
        width: "100%",
        height: "auto",
        padding: "10px",
        marginTop: "10px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#f9f9f9",
        color: "#333",
        fontFamily: "Satoshi, sans-serif",
        whiteSpace: "pre-wrap",
      }}
    >
     {thesisContent ? (
    <div dangerouslySetInnerHTML={{ __html: thesisContent }} />
  ) : (
    'No Thesis available.'
  )}
    </div>
  )}

  
</Box> */}


{mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "105%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-5%"
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Top Portfolios"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="Crypto Screener"
        onClick={() => 
          navigate("/crypto-screener")
        }
        icon={<img src={loc === '/crypto-screener' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
              label="$1/Month Subscription"
              onClick={() => {
                if (userData) {
                  navigate("/subscriptions/screener360");
                } else {
                  const originalUrl = '/subscriptions/screener360';
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    
                       loc === "/subscriptions/screener360"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                     
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
</BottomNavigation>
</div>
)}
{isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
    </div>
    </div>
  );
};

export default PortfolioNetworthGraph;
