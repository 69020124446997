import React, {useState, useEffect, useRef, useMemo, useCallback, lazy, Suspense} from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {Popover} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useParams} from "react-router-dom";
import Modal from "react-modal";
import "./CryptoPage.css"; // Assuming you have a CSS file for styling
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import {styled} from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import EmailPopUp from "./EmailPopUp.js";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {BottomNavigation, Button} from "@mui/material";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";

import IconButton from "@mui/material/IconButton";
import {Grid} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import {useLocation, Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import LoginPopUp from "./loginpopup";
import {Tabs, Tab} from "@mui/material";
import {getCryporCurrencyListHome} from "../apihelper/homepage";

import Dialog from "@mui/material/Dialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {Card, TextField} from "@mui/material";

import {DialogTitle, DialogContent, DialogActions} from "@mui/material"; // Import Material-UI components

import {addToPortfolio, copyToPortfolio} from "../apihelper/portfolio";

import CloseIcon from "@mui/icons-material/Close";

import CreatableSelect from "react-select/creatable";
import {getTotalunit, getFetchdropdown} from "../apihelper/cryptocurrencylist";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Footer from "../common/footer/Footer";
import MetaProperty from "../common/meta_property/MetaProperty";

const drawerWidth = 292;
const CryptoPage = (props) => {
    const {window1} = props;
    const {crypto} = useParams();
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortType, setSortType] = useState("fd_asc");
    const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [loader, setLoader] = useState(false);
    const [slug, setSlug] = useState(false);
    const [tfhrs, settfhrs] = useState("");
    const [sevenDays, setSevenDays] = useState(false);
    const [thirtyDays, setThirtyDays] = useState(false);
    const [yearDays, setYearDays] = useState(false);
    const [buyPercent, setBuyPercent] = useState(false);
    const [holdings, setHoldings] = useState(false);
    const [description, setDescription] = useState("");
    const [symbolLogo, setSymbolLogo] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(false);
    const [marketCap, setMarketCap] = useState(false);
    const [rowsToShow, setRowsToShow] = useState(2);
    const [selectedSort, setSelectedSort] = useState("");
    const [hashtags, setHashtags] = useState(false);
    const sortRef = useRef();
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const loc = location.pathname;
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [cryptoName, setCryptoName] = useState(false);
    const [sortOrder, setSortOrder] = useState({
        // Track ascending/descending for each column
        forecaster: "asc",
        target: "asc",
        accuracy: "asc",
        percent: "asc",
        targetDate: "asc",
    });
    const [isAtBottom, setIsAtBottom] = useState(false);

    // Render row based on the action color
    const renderAction = (action) => {
        switch (action) {
            case "Buy":
            case "Bullish":
            case "bullish":
            case "Partially Bullish":
            case "Outperformer":
            case "Strong Buy":
                return <span style={{color: "green", fontWeight: ""}}> {capitalizeFirstLetter(action)}</span>;

            case "Sell":
            case "Bearish":
            case "Partially Bearish":
            case "Underperformer":
            case "Strong Sell":
                return <span style={{color: "red", fontWeight: ""}}> {capitalizeFirstLetter(action)}</span>;
            case "Neutral":
            case "Moderate Performer":
                return <span style={{color: "orange", fontWeight: ""}}>Neutral</span>;
            default:
                return null;
        }
    };

    function capitalizeFirstLetter(text) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    const mediaQueryVar = useMediaQuery(
        json2mq({
            minWidth: 900,
        })
    );

    function formatDate(dateString) {
        if (!dateString) return ""; // Handle cases where dateString is undefined or null
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0"); // Add leading zero to day
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero to month (0-based index)
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const handleLoginClick = () => {
        // Mask the URL by adding /login at the end without navigating
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        // Open the login modal
        setLoginModalOpen(true);
    };
    const handleModalClose = () => {
        // Close the modal and reset the URL to the welcome page
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };

    const mediaQueryVar1 = useMediaQuery(
        json2mq({
            minWidth: 400,
        })
    );

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            border: 0,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: 0,
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        // hide last border

        "& td, & th": {
            border: 0,
        },
    }));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
            <SidebarMenuList />
        </div>
    );

    const [predictionLoader, setPredictionLoader] = useState(false);
    const [oscillatorData, setOscillatorData] = useState(false);
    const [movingAveragesData, setMovingAveragesData] = useState(false);
    const [timestamp, setTimestamp] = useState("");
    const [pivotdata, setpivotdata] = useState("");
    const [evaluation_data, setevaluation_data] = useState("");
    const [analysis_data, setanalysis_data] = useState("");
    const [outlookData, setOutlookData] = useState("");
    const [paid_member, setpaid_member] = useState("no");
    const [currentCrypto, setCurrentCrypto] = useState();

    const handleNavigation = () => {
        const userData = localStorage.getItem("cw_portfolio_user");
        if (!userData) {
            const originalUrl = "/subscriptions/screener360";
            sessionStorage.setItem("originalUrl", originalUrl);
            window.history.pushState({}, "", "/login");
            localStorage.setItem("isPopupManuallyOpened", "true");

            // Open the login modal
            setLoginModalOpen(true);
            //window.location.href = `${process.env.REACT_APP_HOME_URL}subscriptions/screener360`;

            return;
        } else if (userData && paid_member == "no") {
            window.location.href = `${process.env.REACT_APP_HOME_URL}subscriptions/screener360`;
        }
    };

    const container = window1 !== undefined ? () => window1().document.body : undefined;

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const userId = userData?.user_id; // Use optional chaining to safely access user_id

        const fetchData = async () => {
            try {
                const response = await fetch(
                    "https://crowdwisdom.live/wp-json/api/v1/showForecasterFutureDataInvesting",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            searchVal: crypto, // Adjust if necessary
                            sortType: sortType,
                            userId: userId || null, // Send null if userId is not available
                        }),
                    }
                );

                const result = await response.json();
                setCurrentCrypto(result);
                if (result.code === "200") {
                    if (result.response.length === 0) {
                        setPredictionLoader(true);
                    }
                    setLoader(true);
                    setSlug(result.symbol);
                    setCurrentPrice(result.current_price);
                    setSymbolLogo(result.symbol_logo);
                    settfhrs(result.tfhrs);
                    setSevenDays(result.last_7_days);
                    setThirtyDays(result.last_30_days);
                    setYearDays(result.last_365_days);
                    setBuyPercent(result.buy_percent);
                    setHoldings(result.holdings);
                    setDescription(result.crypto_desc);
                    setMarketCap(result.market_cap);
                    setHashtags(result.hashtags);
                    setData(result.response);
                    setpaid_member(result.paid_member);
                    setOscillatorData(result.oscillator_data); // Store oscillator data
                    setMovingAveragesData(result.moving_averages_data); // Store moving averages data
                    setTimestamp(result.timestamp);
                    setpivotdata(result.pivot_data);
                    setevaluation_data(result.evaluation_data);
                    setanalysis_data(result.analysis_data);
                    setOutlookData(result.outlook_data);
                    setCryptoName(result.crypto_name);

                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [crypto, sortType]);
    const sanitizedDescription = description;
    const mediaQueryVar2 = useMediaQuery(
        json2mq({
            maxWidth: 420,
        })
    );
    const mediaQueryVar3 = useMediaQuery(
        json2mq({
            minWidth: 375,
        })
    );

    // Function to extract indentation level and style accordingly
    const getIndentationStyle = (htmlString) => {
        const div = document.createElement("div");
        div.innerHTML = htmlString;

        const items = div.querySelectorAll("li");
        items.forEach((item) => {
            const match = item.className.match(/ql-indent-(\d+)/);
            if (match) {
                const level = parseInt(match[1], 10);
                // Set padding based on indent level for content, reducing by 10px
                item.style.paddingLeft = `${20 * level - 20}px`; // Adjusted padding
                // Adjust margin for bullet alignment
                item.style.marginLeft = `${40 * level}px`; // Bullet alignment
            }
        });

        return div.innerHTML; // Return modified HTML
    };

    const processedDescription = getIndentationStyle(sanitizedDescription);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sortRef.current && !sortRef.current.contains(event.target)) {
                setSortDropdownVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = (index) => {
        setDropdownVisible(dropdownVisible === index ? null : index);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSortChange = (sortType) => {
        setSortType(sortType);
        setSortDropdownVisible(false);
        setSelectedSort(sortType);
    };

    const openModal = async (data) => {
        if (data.accuracy.toLowerCase() !== "pending") {
            try {
                const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/showForecasterPastData", {
                    // Replace with your actual API endpoint
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        searchVal: crypto,
                        forecasterName: data.forecaster_name,
                    }),
                });

                const result = await response.json();

                if (result.code === "200") {
                    setModalData(result.response);
                    setModalIsOpen(true);
                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            }
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalData(null);
    };

    const filteredData = data.filter((forecaster) =>
        forecaster.forecaster_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleLoadMore = () => {
        navigate(`/crypto/${crypto}/predictions`);
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    const [isTableView, setIsTableView] = useState(true);
    const [selectedButton, setSelectedButton] = useState("CoinPrices");

    const handleButtonClick = (buttonName) => {
        // If the same button is clicked again, don't toggle the view
        if (selectedButton === buttonName) {
            return;
        }

        // Toggle the view based on the clicked button
        setSelectedButton(buttonName);
        setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
    };

    let url = `/predict-now`;
    const parseDate = (dateStr) => {
        const [month, day, year] = dateStr.split(" ");
        const monthMap = {
            Jan: "01",
            Feb: "02",
            Mar: "03",
            Apr: "04",
            May: "05",
            Jun: "06",
            Jul: "07",
            Aug: "08",
            Sep: "09",
            Oct: "10",
            Nov: "11",
            Dec: "12",
        };
        const formattedDate = `${year}-${monthMap[month]}-${day.padStart(2, "0")}`;
        return new Date(formattedDate);
    };

    const parseForecastPrice = (price) => {
        return parseFloat(price.replace(/[^0-9.-]+/g, ""));
    };

    const parseAccuracy = (accuracy) => {
        if (typeof accuracy !== "string") return NaN; // Return NaN if input is not a string

        // Extract the number from the accuracy string
        const percentage = accuracy.split("%")[0].trim(); // Get the part before '%'
        const parsedValue = parseFloat(percentage.split(" ")[0]); // Convert to float

        return isNaN(parsedValue) ? 0 : parsedValue; // Return 0 for NaN for safety
    };

    const compareValues = (val1, val2, order = "asc") => {
        // If either value is NaN, treat it as less than any valid number
        if (isNaN(val1) && isNaN(val2)) return 0; // Both are NaN
        if (isNaN(val1)) return 1; // Treat NaN as the largest value
        if (isNaN(val2)) return -1; // Treat NaN as the largest value

        // Perform comparison based on order
        if (order === "asc") return val1 - val2;
        return val2 - val1;
    };

    const handleSort = (column) => {
        const newSortOrder = sortOrder[column] === "asc" ? "desc" : "asc"; // Toggle sort order
        setSortOrder({...sortOrder, [column]: newSortOrder});

        // Set the sort type based on the column
        switch (column) {
            case "forecaster":
                setSortType(newSortOrder === "asc" ? "fc_asc" : "fc_desc");
                break;
            case "target":
                setSortType(newSortOrder === "asc" ? "tg_asc" : "tg_desc");
                break;
            case "accuracy":
                setSortType(newSortOrder === "asc" ? "ac_asc" : "ac_desc");
                break;
            case "percent":
                setSortType(newSortOrder === "asc" ? "pr_asc" : "pr_desc");
                break;
            case "targetDate":
                setSortType(newSortOrder === "asc" ? "fd_asc" : "fd_desc");
                break;
            default:
                break;
        }
    };

    const sortedData = [...filteredData].sort((a, b) => {
        switch (sortType) {
            case "fc_asc":
                return a.forecaster_name.localeCompare(b.forecaster_name);
            case "fc_desc":
                return b.forecaster_name.localeCompare(a.forecaster_name);
            case "tg_asc":
                return parseForecastPrice(a.forecast_price) - parseForecastPrice(b.forecast_price);
            case "tg_desc":
                return parseForecastPrice(b.forecast_price) - parseForecastPrice(a.forecast_price);
            case "ac_asc": {
                const accuracyA = parseAccuracy(a.accuracy);
                const accuracyB = parseAccuracy(b.accuracy);
                return compareValues(accuracyA, accuracyB, "asc");
            }
            case "ac_desc": {
                const accuracyA = parseAccuracy(a.accuracy);
                const accuracyB = parseAccuracy(b.accuracy);
                return compareValues(accuracyA, accuracyB, "desc");
            }
            case "pr_asc":
                return parseFloat(a.target_percent) - parseFloat(b.target_percent);
            case "pr_desc":
                return parseFloat(b.target_percent) - parseFloat(a.target_percent);
            case "fd_asc":
                return parseDate(a.target_date) - parseDate(b.target_date);
            case "fd_desc":
                return parseDate(b.target_date) - parseDate(a.target_date);
            default:
                return 0;
        }
    });

    const [isPredictionDataLoaded, setIsPredictionDataLoaded] = useState(false);
    useEffect(() => {
        if (sortedData && sortedData.length > 0) {
            setIsPredictionDataLoaded(true); // Set to true when data is available
        }
    }, [sortedData]);

    const countEvaluations = (data, evaluationKeys) => {
        let buys = 0,
            sells = 0,
            neutrals = 0;

        data.forEach((item) => {
            evaluationKeys.forEach((key) => {
                if (item[key] === "Buy") buys += 1;
                else if (item[key] === "Sell") sells += 1;
                else if (item[key] === "Neutral") neutrals += 1;
            });
        });

        return {buys, sells, neutrals};
    };

    const handleTabClick = () => {
        navigate("/predict-now", {state: {cryptoValue: "btc"}});
    };

    const oscillatorEvaluationKeys = [
        "rsi_evaluation",
        "cci_evaluation",
        "ao_evaluation",
        "mom_evaluation",
        "macd_evaluation",
        "stochrsi_evaluation",
        "willr_evaluation",
        "ultosc_evaluation",
    ];

    const movingAverageEvaluationKeys = [
        "sma_10_evaluation",
        "sma_50_evaluation",
        "sma_100_evaluation",
        "sma_200_evaluation",
        "ema_10_evaluation",
        "ema_50_evaluation",
        "ema_100_evaluation",
        "ema_200_evaluation",
    ];

    const isDataLoaded = oscillatorData && movingAveragesData;

    const oscillatorCounts = isDataLoaded
        ? countEvaluations(oscillatorData, oscillatorEvaluationKeys)
        : {buys: 0, sells: 0, neutrals: 0};

    const movingAverageCounts = isDataLoaded
        ? countEvaluations(movingAveragesData, movingAverageEvaluationKeys)
        : {buys: 0, sells: 0, neutrals: 0};

    const {buys: oscillatorBuys, sells: oscillatorSells, neutrals: oscillatorNeutrals} = oscillatorCounts;
    const {buys: movingAverageBuys, sells: movingAverageSells, neutrals: movingAverageNeutrals} = movingAverageCounts;

    const getSummary = (buys, sells) => {
        if (buys > sells) return "Strong Buy";
        if (sells > buys) return "Strong Sell";
        return "Neutral";
    };

    const oscillatorSummary = isDataLoaded ? getSummary(oscillatorBuys, oscillatorSells) : "-";
    const movingAverageSummary = isDataLoaded ? getSummary(movingAverageBuys, movingAverageSells) : "-";

    const getOverallSummary = () => {
        if (oscillatorSummary === "Strong Buy" && movingAverageSummary === "Strong Buy") return "Bullish";
        if (oscillatorSummary === "Strong Sell" && movingAverageSummary === "Strong Sell") return "Bearish";
        if (oscillatorSummary === "Strong Buy" || movingAverageSummary === "Strong Buy") return "Partially Bullish";
        if (oscillatorSummary === "Strong Sell" || movingAverageSummary === "Strong Sell") return "Partially Bearish";
        if (!oscillatorSummary || !movingAverageSummary) return "Neutral";
        return "";
    };

    const overallSummary = isDataLoaded ? getOverallSummary() : "-";

    // Check if any data is available for oscillators or moving averages
    const hasOscillatorData = oscillatorBuys + oscillatorSells + oscillatorNeutrals > 0;
    const hasMovingAverageData = movingAverageBuys + movingAverageSells + movingAverageNeutrals > 0;

    // Define statements based on sentiment
    const bullish =
        `A breakout above its initial resistance is likely to draw in more buyers. ` +
        `The next level of resistance at ${pivotdata.pivot_r2} may be retested or surpassed if buying pressure intensifies. `;

    const bearish =
        `If ${slug} fails to hold above its first level of support, ${pivotdata.pivot_s2} ` +
        `will be the immediate support. `;

    const neutral =
        `${slug} is likely to trade in the range between ${pivotdata.pivot_s1} and ${pivotdata.pivot_r1} ` + `for now.`;

    // Main statement based on overall sentiment
    const sentimentStatement =
        overallSummary === "Bullish" || overallSummary === "Partially Bullish"
            ? bullish
            : overallSummary === "Bearish" || overallSummary === "Partially Bearish"
            ? bearish
            : neutral;

    const [data_replacements, setData_replacements] = useState([]);
    const [loading_replacements, setLoading_replacements] = useState(true);
    const [predictionLoader_replacements, setPredictionLoader_replacements] = useState(false);
    const [isPredictionDataLoaded_replacements, setIsPredictionDataLoaded_replacements] = useState(false);
    useEffect(() => {
        const fetchCryptoData = async () => {
            try {
                const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/showCryptoReplacements", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        crypto: crypto,
                        sortType: sortType,
                    }),
                });

                const result = await response.json();
                if (result.code === "200") {
                    setData_replacements(result.response);
                    if (result.response.length === 0) {
                        setPredictionLoader_replacements(true);
                    }
                } else {
                    setError(result.message);
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading_replacements(false);
            }
        };

        fetchCryptoData();
    }, [sortType, crypto]);

    const [showModal, setShowModal] = useState(false); // Change the state name to showModal

    const filteredData1 = data_replacements;

    const sortedData1 = [...filteredData1].sort((a, b) => {
        switch (sortType) {
            case "ac_asc":
                return compareValues(a.accuracy, b.accuracy, "asc");
            case "ac_desc":
                return compareValues(a.accuracy, b.accuracy, "desc");
            default:
                return 0;
        }
    });

    const [ignoredRows, setIgnoredRows] = useState([]);

    const [open, setOpen] = useState(false);
    const fetchDropdownOptions = async () => {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

        getFetchdropdown({
            user_id: userData.user_id,

            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                const newOptions = res.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                setOptions(newOptions);
                setuser_role(res.user_role);
            } else {
                console.error("Data fething error");
            }
        });
    };

    const handleIgnoreClick = (index) => {
        const updatedIgnoredRows = [...ignoredRows, index];
        setIgnoredRows(updatedIgnoredRows);

        // Check if all currently displayed rows are ignored
        const allIgnored = sortedData1.slice(0, rowsToShow).every((_, idx) => updatedIgnoredRows.includes(idx));

        // If all rows are ignored, call handleLoadMore
        if (allIgnored) {
            handleLoadMore();
        }
    };

    const allRowsIgnored = sortedData1.slice(0, rowsToShow).every((_, index) => ignoredRows.includes(index));

    useEffect(() => {
        if (sortedData1 && sortedData1.length > 0) {
            setIsPredictionDataLoaded_replacements(true); // Set to true when data is available
        }
    }, [sortedData1]);

    const [unit, setUnit] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [price, setPrice] = useState("");
    const [totalUnits, setTotalUnits] = useState(0);
    const [selectedDate, handleDateChange] = useState("");
    const [Usablewallet, setUsablewallet] = useState("");
    const [portfolioTypeId, setPortfolioTypeId] = useState("");
    const handleChange = async (selectedOption, slug) => {
        if (selectedOption && selectedOption._isNew_) {
            setIsDialogOpen(true);
            setSelectedOption(null);
            setdataslug(slug);
        } else if (!selectedOption) {
            // Handle the case when the selection is cleared
            setSelectedOption(null);
            setShowAlert(false);
            // Perform any other necessary actions
            return;
        } else {
            const selectedValue = selectedOption.value;
            if (selectedValue.includes("-")) {
                setErrorMessage("Hyphens are not allowed in the option."); // Set error message
                setShowAlert(true); // Show the alert
                return; // Abort further processing
            }
            setShowAlert(false);
            setSelectedOption(selectedOption);
            const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
            getTotalunit({
                user_id: userData.user_id,
                slug: slug, // Assuming value contains the slug
                portfolio_type_id: selectedOption.value, // Adjust as needed
                Token: userData.token,
            }).then((res) => {
                if (res.code === "200") {
                    setTotalUnits(res.total_uint);
                    setUsablewallet(res.wallet_amount);
                } else {
                    console.error("Data fething error");
                }
            });
        }
    };
    useEffect(() => {
        if (open || showModal) {
            fetchDropdownOptions();
        }
    }, [open, showModal]);
    const handleCreate = async (inputValue) => {
        setTotalUnits(0);
        setUsablewallet("");

        if (options.find((option) => option.value === inputValue)) {
            return; // Exit early if the option already exists
        }
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const newOption = {value: inputValue, label: inputValue};
        const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
        setOptions(newOptions); // Update the options state
        const createdOption = newOptions.find((option) => option.value == inputValue);
        if (createdOption) {
            handleChange(createdOption);
        }
    };
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const formatToNonZero = (number) => {
        number = parseFloat(number);

        if (number >= 1) {
            return number.toFixed(2);
        } else if (number >= 0.1) {
            return number.toFixed(1);
        } else if (number >= 0.01) {
            return number.toFixed(4);
        } else if (number >= 0.001) {
            return number.toFixed(5);
        } else if (number >= 0.0001) {
            return number.toFixed(6);
        } else if (number >= 0.00001) {
            return number.toFixed(7);
        } else if (number >= 0.000001) {
            return number.toFixed(8);
        } else if (number >= 0.0000001) {
            return number.toFixed(9);
        } else if (number >= 0.00000001) {
            return number.toFixed(10);
        } else if (number >= 0.000000001) {
            return number.toFixed(11);
        }
        return number.toFixed(1);
    };
    const [buySell, setbuySell] = React.useState("");
    const handleClick = (buySell) => {
        setSnackbaropen(true);
        setOpen(true);
        setSeverity("info");
        setIsFormSubmitted(false);
        // setSnackbarcolor("success");
        // setSnackbarmessage("Data saved successfully");
        setMsgforUpdatePortfolio("loading...");
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const portfolioId =
            selectedOption && !isNaN(parseInt(selectedOption.value)) ? parseInt(selectedOption.value) : 0;
        const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId

        addToPortfolio({
            portfolio_type_id: portfolioId,
            portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined

            existing_portfolio: existingPortfolio,
            user_id: userData.user_id,
            slug: slugpopupdata.slugname,
            unit: unit,
            acquisition_price: price,
            date_time: selectedDate,
            type: buySell,
            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                // setOpen(false);
                setSeverity("info");
                setMsgforUpdatePortfolio(res.message);
                setPortfolioTypeId(res.portfolio_type_id);
                setIsFormSubmitted(true);
                // navigate(
                //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
                //     selectedOption.label
                //   )}`
                // );
            } else {
                setSeverity("error");
                // setOpen(true)
                setMsgforUpdatePortfolio(res.message);
            }
        });
    };
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newOptionValue, setNewOptionValue] = useState("");
    const handleDialogSubmit = () => {
        // Handle submitting the dialog (e.g., creating the new option)

        // Update options state with the new option
        const newOption = {value: newOptionValue, label: newOptionValue};
        setOptions([...options, newOption]);
        setSelectedOption(newOption);
        // Close the dialog
        setIsDialogOpen(false);

        // setSelectedOption(selectedOption);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        getTotalunit({
            user_id: userData.user_id,
            slug: dataslug, // Assuming value contains the slug
            portfolio_type_id: newOption.value, // Adjust as needed
            Token: userData.token,
        }).then((res) => {
            if (res.code === "200") {
                setTotalUnits(res.total_uint);
                setNewOptionValue("");
                setUsablewallet(res.wallet_amount);
            } else {
                console.error("Data fething error");
            }
        });
    };
    const [snackbaropen, setSnackbaropen] = React.useState(false);
    const closesnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbaropen(false);
    };
    const [severity, setSeverity] = useState("info");
    const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] = useState("loading...");
    const handleClose = () => setOpen(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [dataslug, setdataslug] = useState("");
    const [options, setOptions] = useState([]);
    const handleChange2 = (e) => {
        const value = e.target.value;
        // Check if the input contains a hyphen
        if (value.includes("-")) {
            setShowAlert(true); // Show the alert if there's a hyphen
        } else {
            setShowAlert(false); // Hide the alert if there's no hyphen
            setNewOptionValue(value); // Update the state with the new value
        }
    };
    const [slugdata, setSlugdata] = useState([]);
    const [user_role, setuser_role] = useState("");
    const [slugpopupdata, setSlugpopupdata] = useState({
        slugname: "",
        slug: "",
        units: 0,
    });

    const handleAddToPortfolio = (idx) => {

        getCryporCurrencyListHome({
            slug: idx,
        }).then((res) => {
            if (res.code === "200") {

                // Update slugdata
                setSlugdata(res.data);
                setuser_role(res.user_role);

                // Use res.data directly here
                const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
                if (userData) {
                    setSlugpopupdata({
                        slugname: res.data[0].slug,
                        slug: res.data[0].name,
                        units: res.data[0].total_unit,
                        pricetoday: res.data[0].price_today,
                        logo: res.data[0].logo,
                    });
                    setOpen(true);
                } else {
                    // Save the source in sessionStorage
                    const originalUrl = window.location.pathname + window.location.search;
                    sessionStorage.setItem("originalUrl", originalUrl);
                    sessionStorage.setItem("loginSource", "addToPortfolio");
                    window.history.pushState({}, "", "/login");
                    localStorage.setItem("isPopupManuallyOpened", "true");
                    setLoginModalOpen(true);
                }
            } else {
                console.error("Data fetching error");
            }
        });
    };

    const [selectedFilter, setSelectedFilter] = useState(null);
    const handleClickROIHL = () => {
        setSortType("roi_desc");
    };
    const handleClickROILH = () => {
        setSortType("roi_asc");
    };
    const handleClearAll = () => {
        setSortType("roi_asc");
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const openfilter = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseeoption = () => {
        setAnchorEl(null);
    };

    return (
        <div
            className="crypto-page"
            style={{
                padding: mediaQueryVar ? 0 : "10px",
                margin: 0,
                backgroundColor: mediaQueryVar ? "#F2F2F2" : "#fff",
            }}
        >
            <MetaProperty
                title={`${crypto.charAt(0).toUpperCase() + crypto.slice(1)} Price Analysis`}
                description={`Accurate ${
                    crypto.charAt(0).toUpperCase() + crypto.slice(1)
                } Influencer Price Predictions & In-depth ${
                    crypto.charAt(0).toUpperCase() + crypto.slice(1)
                } Price Analysis.`}
                logo={currentCrypto?.symbol_logo}
            />

            <Modal
                isOpen={open}
                onRequestClose={handleClose}
                overlayClassName={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
                        backdropFilter: "blur(5px)", // Optional: Add a blur effect to the overlay
                    },
                }}
                className={{
                    content: {
                        position: "relative",
                        backgroundColor: "white", // Set background for inner popup
                        padding: "20px", // Add padding for content
                        borderRadius: "8px", // Rounded corners
                        maxWidth: mediaQueryVar ? "100%" : "100%", // Set a max width for popup
                        margin: "0", // Center the popup horizontally
                        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Optional: Popup shadow
                    },
                }}
            >
                <div className="CardOpener" style={{overflow: "hidden"}}>
                    {isFormSubmitted ? (
                        <div
                            style={{
                                position: "fixed", // Fix the popup to the top of the viewport
                                top: 0, // Align it to the top
                                left: 0,
                                width: "100%",
                                height: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: darken the background for emphasis
                                zIndex: 1000, // High z-index to overlay other elements
                            }}
                        >
                            <Card className="card__container">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CheckCircleOutlineIcon sx={{fontSize: 50, mr: 1}} />
                                    </div>
                                    <Typography
                                        severity="success"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        Portfolio added successfully!
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            marginTop: "10px",
                                            width: "100%",
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setIsFormSubmitted(false);
                                                setOpen(false);
                                                setUnit("");
                                                setSelectedOption("");
                                                setPrice("");
                                                setTotalUnits(0);
                                                handleDateChange("");
                                                setUsablewallet("");
                                            }}
                                            color="primary"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "150px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                        >
                                            Add More Cryptos
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                                                        selectedOption.label.replace(/\s+/g, "-")
                                                    )}`
                                                )
                                            }
                                            variant="outlined"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "100px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                            color="primary"
                                        >
                                            View Portfolio
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ) : (
                        <Dialog
                            PaperProps={{
                                sx: {
                                    position: mediaQueryVar ? "static" : "fixed",
                                    bottom: mediaQueryVar ? "" : 0,
                                    left: mediaQueryVar ? "" : 0,
                                    right: mediaQueryVar ? "" : 0,
                                    marginLeft: mediaQueryVar ? "" : "0px",
                                    marginRight: mediaQueryVar ? "" : "0px",
                                    marginTop: "1%",
                                    maxWidth: "100%",
                                    minHeight: mediaQueryVar ? "60%" : "", // Adjust max height if needed
                                    borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                                    marginBottom: mediaQueryVar ? "" : "0px",
                                    padding: mediaQueryVar ? "10px" : "1px",
                                    alignItems: "center",
                                    overflow: "scroll",
                                    "&::-webkit-scrollbar": {
                                        display: "none",
                                    },
                                    "-ms-overflow-style": "none",
                                    "scrollbar-width": "none", // Ensure no scrollbar
                                },
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <div
                                style={{
                                    padding: "20px",
                                    width: mediaQueryVar ? "400px" : "100%",
                                    margin: "0",
                                    marginTop: "-5px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                        borderBottom: "1px #E3E3E3 solid",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "20.767px",
                                            paddingLeft: "100px",
                                        }}
                                        gutterBottom
                                    >
                                        Add To Portfolio
                                    </Typography>

                                    <div className="primaryButton">
                                        <img
                                            className="frameIcon"
                                            alt=""
                                            onClick={handleClose}
                                            src={window.constants.asset_path + "/images/frame17.svg"}
                                            style={{
                                                background: "#F3F3F3",
                                                width: "25px",
                                                height: "25px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginBottom: "5px",
                                        marginLeft: mediaQueryVar === false ? "10px" : "",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: "13.845px",
                                            fontWeight: "500",
                                            marginBottom: "1px",
                                            color: "black",
                                            marginTop: "-8px",
                                        }}
                                    >
                                        Select Portfolio
                                    </Typography>
                                    <div style={{marginTop: "5px"}}>
                                        <CreatableSelect
                                            isClearable
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "12px",
                                                    border: "1px solid #1877F2",
                                                    boxShadow: "none",
                                                    minHeight: "40px",
                                                    "&:hover": {
                                                        borderRadius: "12px",
                                                        border: "2px solid #1877F2",
                                                    },
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "8px",
                                                }),
                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    color: "",
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    color: "#1877F2",
                                                    border: "1px solid #1877F2",
                                                    padding: "5px",
                                                    margin: "8px",
                                                    borderRadius: "3.786px",
                                                    "&:hover": {
                                                        color: "#1877F2",
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    display: "none",
                                                }),
                                            }}
                                            placeholder=""
                                            onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                                            onCreateOption={handleCreate}
                                            options={[
                                                {
                                                    value: "",
                                                    label: "Create new portfolio",
                                                    _isNew_: true,
                                                }, // Dynamic "create new option" item
                                                ...options, // Existing options
                                            ]}
                                            value={selectedOption}
                                        />
                                    </div>
                                    {showAlert && (
                                        <Alert severity="error" sx={{marginBottom: "10px"}}>
                                            {errorMessage}
                                        </Alert>
                                    )}
                                </div>
                                <Typography
                                    style={{
                                        fontSize: "13.845px",
                                        fontWeight: "500",
                                        color: "#000",
                                        marginTop: "10px",
                                        marginBottom: "5px",
                                    }}
                                >
                                    Usable Wallet Value: {Usablewallet}
                                </Typography>
                                <div
                                    style={{
                                        padding: mediaQueryVar ? "8px" : "5px",
                                        background: "#F3F3F3",
                                        borderRadius: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flex: 0,
                                            minWidth: 0,
                                        }}
                                    >
                                        <img
                                            style={{
                                                marginRight: "10px",
                                                border: "2px solid #000",
                                                borderRadius: "19px",
                                                height: "27.69px",
                                                width: "27.69px",
                                            }}
                                            alt=""
                                            src={slugpopupdata.logo}
                                        />
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "500",
                                                    marginBottom: "0px",
                                                    fontSize: "14px",
                                                    color: "#7F7F80",
                                                }}
                                            >
                                                {slugpopupdata.slugname}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: "20%",
                                            flex: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                marginBottom: "4px",
                                                fontSize: "13px",
                                                color: "#7F7F80",
                                            }}
                                        >
                                            {" "}
                                            Price
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12.114px",
                                                fontWeight: "500",
                                                color: "black",
                                                margin: "0",
                                                marginLeft: "5%",
                                            }}
                                        >
                                            {slugpopupdata.pricetoday}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                marginBottom: "4px",
                                                fontSize: "13px",
                                                color: "#7F7F80",
                                            }}
                                        >
                                            {" "}
                                            Units
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12.114px",
                                                fontWeight: "500",
                                                color: "black",
                                                margin: "0",
                                            }}
                                        >
                                            {totalUnits}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="card__container__row3">
                                    <div style={{marginLeft: "-5px"}}>
                                        <p style={{fontSize: "14px", fontWeight: "500"}}>Enter number of Units</p>
                                        <input
                                            style={{
                                                backgroundColor: "white",
                                                color: "black",
                                                padding: "20px",
                                                border: "1px solid #1877F2",
                                                borderRadius: "10.384px",
                                                width: mediaQueryVar ? "350px" : "100%",
                                                height: "45px",
                                                margin: 0,
                                            }}
                                            min="1"
                                            max="10"
                                            type="number"
                                            placeholder=""
                                            value={unit}
                                            onChange={(e) => setUnit(e.target.value)}
                                        ></input>
                                    </div>
                                    <div>
                                        $
                                        {formatToNonZero(
                                            unit *
                                                parseFloat(
                                                    (slugpopupdata.pricetoday || "")
                                                    .replace(/^\$/, "") // Remove the dollar sign
                                                    .replace(/,/g, "") // Remove commas
                                                )
                                        )}
                                    </div>
                                    {user_role === "superuser" && (
                                        <>
                                            <div className="card__container__row3__r2">
                                                <div style={{marginLeft: "-5px"}}>
                                                    <p style={{fontSize: "14px", fontWeight: "500"}}>
                                                        Buy/Sell Price($)
                                                    </p>
                                                    <input
                                                        style={{
                                                            backgroundColor: "white",
                                                            color: "black",
                                                            padding: "20px",
                                                            border: "1px solid #1877F2",
                                                            borderRadius: "10.384px",
                                                            width: "345px",
                                                            height: "45px",
                                                        }}
                                                        className="card__container__row3__r2__input"
                                                        min="1"
                                                        max="10"
                                                        type="number"
                                                        placeholder="Enter Price"
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div style={{marginBottom: "10px", marginLeft: "-5px"}}>
                                                <p style={{fontSize: "14px", fontWeight: "500"}}>Date</p>
                                                <TextField
                                                    type="date"
                                                    value={selectedDate}
                                                    onChange={(e) => handleDateChange(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            borderRadius: "10.384px",
                                                            border: "1px solid #1877F2",
                                                            boxShadow: "none",
                                                            width: "190%",
                                                            height: "45px",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <div className="card__container__row3__r4" style={{marginTop: "10px"}}>
                                        <Button
                                            className={buySell === "buy" ? "buttonToggleButActive" : "buttonToggleBuy"}
                                            value="Buy"
                                            style={{
                                                lineHeight: "20px",
                                                width: mediaQueryVar ? "159px" : "130px",
                                                borderRadius: "27px",
                                                border: "1px solid #1877F2",
                                                padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                marginLeft: "-6px",
                                                backgroundColor: buySell === "buy" ? "" : "#1877F2",
                                                color: buySell === "buy" ? "" : "white",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                            onClick={() => handleClick("buy")}
                                        >
                                            Buy
                                        </Button>
                                        <Button
                                            className={
                                                buySell === "sell" ? "buttonToggleSellActive" : "buttonToggleSell"
                                            }
                                            value="sell"
                                            style={{
                                                lineHeight: "20px",
                                                width: mediaQueryVar ? "159px" : "130px",
                                                borderRadius: "27px",
                                                border: "1px solid #1877F2",
                                                marginLeft: "10px",
                                                padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                backgroundColor: buySell === "sell" ? "" : "#1877F2",
                                                color: buySell === "sell" ? "" : "white",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                            onClick={() => handleClick("sell")}
                                        >
                                            Sell
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    )}
                </div>
            </Modal>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Create New Portfolio
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setIsDialogOpen(false);
                            setNewOptionValue("");
                        }}
                        sx={{position: "absolute", right: 13, top: 8}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            autoFocus
                            label="Enter Name"
                            value={newOptionValue}
                            onChange={handleChange2}
                            fullWidth
                            margin="dense"
                        />
                        {showAlert && (
                            <Alert severity="error" sx={{marginTop: "8px"}}>
                                Hyphens are not allowed in the name.
                            </Alert>
                        )}
                    </div>
                </DialogContent>
                <DialogActions sx={{marginRight: "14px"}}>
                    <Button
                        onClick={() => {
                            setIsDialogOpen(false);
                            setNewOptionValue("");
                        }}
                        sx={{fontWeight: "bold"}}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDialogSubmit}
                        variant="contained"
                        color="primary"
                        sx={{fontWeight: "bold"}}
                        disabled={!newOptionValue.trim()}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbaropen}
                autoHideDuration={5000}
                onClose={closesnackbar}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
            </Snackbar>

            <Grid item xs={12} align="left">
                <EmailPopUp />
                {mediaQueryVar ? null : (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between", // Changed from space-between to flex-end
                            marginTop: "0px",
                            marginLeft: "5px",
                            fontSize: "20px",
                        }}
                    >
                        <Typography style={{fontSize: "20px", fontWeight: "700"}}> Price Analysis </Typography>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            sx={{display: {sm: "none"}}}
                        >
                            <img
                                src="/images/menubar.svg"
                                alt="Menu Icon"
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "10px",
                                    marginTop: "10px",
                                }}
                            />
                        </IconButton>
                    </div>
                )}
            </Grid>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: "block", sm: "none"},
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "400",
                        backgroundColor: "white",
                        color: "#727376",
                    },
                }}
            >
                {drawer}
            </Drawer>
            {mediaQueryVar ? null : (
                <Box
                    sx={{
                        backgroundColor: "#1877F2", // Background color for the container
                        padding: "3.105px", // Padding inside the container
                        borderRadius: "38.042px", // Border radius for the container
                        display: "flex", // Ensure that the box is flexible
                        alignItems: "center", // Center the content vertically
                        display: "inline-block",
                        width: "auto",
                        height: "37px",
                        border: "none",
                        marginLeft: mediaQueryVar3 ? "-3px" : "-7px",
                        marginTop: "2%",
                        marginBottom: "5%",
                    }}
                >
                    <Tabs
                        value={selectedButton}
                        onChange={(event, newValue) => handleButtonClick(newValue)}
                        aria-label="basic tabs example"
                        sx={{
                            flexGrow: 1, // Make the tabs take up remaining space
                            "& .MuiTabs-indicator": {
                                display: "none",
                            },
                            display: "flex", // Ensure that the tabs are displayed in a row
                            overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                        }}
                    >
                        <Tab
                            label={isPredictionDataLoaded ? `${slug} Price Analysis` : "Price Analysis"}
                            value="CoinPrices"
                            sx={{
                                whiteSpace: "nowrap",
                                borderRadius: "20.962px",
                                minHeight: "31px",
                                height: "31px",
                                marginLeft: "1.5px",
                                marginTop: "0.2px",
                                border: "1px solid black",
                                fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
                                marginRight: "0px",
                                fontSize: mediaQueryVar2 ? "13px" : "15px",
                                paddingRight: "-8px",
                                textTransform: "none",
                                backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                                color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                border: "none", // Remove border for tabs
                            }}
                        />
                        {/* <Tab
              label={userData ? "My Portfolios" : "Boost Portfolio"}
              value="AuditPortfolio"
              onClick={() => {
        navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
      }}
              
              sx={{
                whiteSpace: "nowrap",
                borderRadius: "20.962px",
                minHeight: "31.5px",
                height: "31.5px",
                fontWeight:
                  selectedButton === "AuditPortfolio" ? "700" : "500",
                marginLeft: "-10px",
                paddingLeft: "-15px",
                marginTop: "0.5px",
                border: "1px solid black",
                marginBottom: "-5px",
                fontSize: mediaQueryVar2 ? "13px" : "15px",
                marginRight: "-25px",
                backgroundColor:
                  selectedButton === "AuditPortfolio"
                    ? "#FFFFFF"
                    : "transparent", // White background for selected tab
                color:
                  selectedButton === "AuditPortfolio"
                    ? "#000000 !important"
                    : "#FFFFFF", // Black text for selected tab
                textTransform: "none",
                border: "none",
              }}
            /> */}

                        <Tab
                            label={userData ? "Predict & Win" : "Audit My Portfolio"}
                            value="PortfolioGallery"
                            onClick={() => {
                                if (userData) {
                                    navigate("/predict-now", { state: { cryptoValue: slug } });
                                } else {
                                    navigate("/audit-my-portfolio");
                                }
                            }}
                            sx={{
                                whiteSpace: "nowrap",
                                borderRadius: "20.962px",
                                minHeight: "31.5px",
                                height: "31.5px",
                                fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
                                marginLeft: "0.5px",
                                paddingLeft: "-15px",
                                marginTop: "0.5px",
                                border: "1px solid black",
                                marginBottom: "-5px",
                                fontSize: mediaQueryVar2 ? "13px" : "15px",
                                marginRight: mediaQueryVar3 ? "-3px" : "-10px",
                                backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
                                color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                textTransform: "none",
                                border: "none",
                            }}
                        />
                    </Tabs>
                </Box>
            )}

            {mediaQueryVar ? null : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "2%",
                        marginLeft: "0.3%",
                        marginRight: "1%",
                        marginBottom: "10%",
                    }}
                >
                    <div style={{display: "flex", alignItems: "center"}}>
                        <button
                            onClick={() => handleAddToPortfolio(slug)}
                            style={{
                                border: "1px solid #1877F2",
                                borderRadius: "48px",
                                padding: "5px 12px",
                                backgroundColor: "white",
                                color: "#1877F2",
                                fontSize: "12px",
                                height: "33px",
                                marginRight: "8px",
                                fontWeight: "500",
                            }}
                        >
                            Add to Portfolio
                        </button>
                        {/* <button
  onClick={() => alert("Alerts functionality coming soon!")}
  style={{
    border: "1px solid #1877F2",
    borderRadius: "6px",
    padding: "6px 14px",
    backgroundColor: "rgba(24, 119, 242, 0.12)",
    color: "#1877F2",
    fontSize: "14px",
    height: "33px",
    border: "1px solid rgba(24, 119, 242, 0.12)",
    fontWeight:"500"
  }}>
  <img src={window.constants.asset_path + "/images/notification.svg"} width="14" height="14" style={{ marginRight: "5px" }} />
  Alerts
</button> */}
                    </div>
                </div>
            )}

            {mediaQueryVar ? null : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                        marginLeft: "0px",
                    }}
                >
                    <button
                        style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                            borderRadius: "6px",
                            background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                            padding: "0px 4px",
                            marginBottom: "4px",
                            color: "black",
                        }}
                    >
                        Performance Metrics
                    </button>
                </div>
            )}

            {mediaQueryVar ? null : !loader ? (
                <div
                    style={{
                        backgroundColor: "#e7f2fd",
                        borderRadius: "10px",
                        padding: "16px",
                        width: "100%",
                        fontFamily: "Satoshi, sans-serif",
                    }}
                >
                    {Array(6)
                    .fill("")
                    .map((_, index) => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "8px",
                                marginTop: "10px",
                            }}
                            key={index}
                        >
                            <div
                                style={{
                                    height: "16px",
                                    backgroundColor: "#ccc",
                                    borderRadius: "4px",
                                    width: "40%",
                                    animation: "shimmer 1.2s infinite linear",
                                    background: "linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%)",
                                    backgroundSize: "800px 104px",
                                }}
                            />
                            <div
                                style={{
                                    height: "16px",
                                    backgroundColor: "#ccc",
                                    borderRadius: "4px",
                                    width: "60%",
                                    animation: "shimmer 1.2s infinite linear",
                                    background: "linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%)",
                                    backgroundSize: "800px 104px",
                                }}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: "#E5F3FF",
                        borderRadius: "8px",
                        border: "0.3px solid #1877F2",
                        padding: "12px",
                        width: "100%",
                        fontFamily: "Satoshi, sans-serif",
                        marginTop: "10px",
                        fontWeight: "400",
                    }}
                >
                    <div style={{display: "flex", justifyContent: "center", marginBottom: "8px", alignItems: "center"}}>
                        <div>
                            {slug}
                            <img
                                alt=""
                                src={symbolLogo}
                                width={30}
                                height={30}
                                style={{
                                    borderRadius: "19px",
                                    border: "2px solid #000",
                                    width: "30px",
                                    height: "30px",
                                    margin: "0px 8px",
                                }}
                            />
                            <span>${currentPrice}</span>
                            <span
                                style={{
                                    marginLeft: "15px",
                                    color: parseFloat(tfhrs) > 0 ? "green" : "red",
                                }}
                            >
                                {parseFloat(tfhrs) > 0 ? `+${tfhrs}` : tfhrs}
                            </span>
                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                        <div>7 Days</div>
                        <div style={{color: "green"}}>
                            <span
                                style={{
                                    color: parseFloat(sevenDays) > 0 ? "green" : "red",
                                }}
                            >
                                {parseFloat(sevenDays) > 0 ? `+${sevenDays}` : sevenDays}
                            </span>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                        <div>30 Days</div>
                        <div style={{color: "green"}}>
                            <span
                                style={{
                                    color: parseFloat(thirtyDays) > 0 ? "green" : "red",
                                }}
                            >
                                {parseFloat(thirtyDays) > 0 ? `+${thirtyDays}` : thirtyDays}
                            </span>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                        <div>12 Months</div>
                        <div style={{color: "green"}}>
                            <span
                                style={{
                                    color: yearDays == "NA" ? "black" : parseFloat(yearDays) > 0 ? "green" : "red",
                                }}
                            >
                                {parseFloat(yearDays) > 0 ? `+${yearDays}` : yearDays}
                            </span>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                        <div>Buy Intent</div>
                        <div>{buyPercent}</div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "3px"}}>
                        <div>Market Cap</div>
                        <div> {marketCap}</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "3px",
                            alignItems: "center",
                            marginTop: "8px",
                        }}
                    >
                        <button
                            onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                            style={{
                                textAlign: "center",
                                color: "white",
                                fontSize: "12px",
                                fontWeight: "700",
                                width: "141px",
                                height: "35px",
                                backgroundColor: "#1877F2",
                                padding: "4px 4px",
                                borderRadius: "6px",
                                border: "none",
                            }}
                        >
                            Best Portfolios
                            <img
                                src={window.constants.asset_path + "/images/open_in_new_tab.svg"}
                                style={{marginLeft: "8px", marginTop: "-2px"}}
                            />
                        </button>
                        <div> {holdings}</div>
                    </div>
                </div>
            )}

            {mediaQueryVar
                ? null
                : evaluation_data && (
                      <div
                          style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "10%",
                              marginLeft: "0px",
                              marginBottom: "10px",
                          }}
                      >
                          <button
                              style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  borderRadius: "6px",
                                  background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  padding: "0px 4px",
                                  marginBottom: "4px",
                                  color: "black",
                              }}
                          >
                              Outlook Summary
                          </button>
                      </div>
                  )}
            {mediaQueryVar
                ? null
                : evaluation_data && (
                      <div style={{marginBottom: "10px"}}>
                          <div
                              style={{
                                  backgroundColor: "#E5F3FF",
                                  borderRadius: "8px",
                                  border: "0.3px solid #1877F2",
                                  padding: "12px",
                                  width: "100%",
                                  fontFamily: "Satoshi, sans-serif",
                                  fontWeight: "400",
                              }}
                          >
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Price Target 2025</div>
                                  <div>{outlookData.target || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Accuracy</div>
                                  <div>{outlookData.accuracy || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Recommendation</div>
                                  <div> {renderAction(outlookData.recommendation) || "-"}</div>
                              </div>
                          </div>
                      </div>
                  )}

            {mediaQueryVar
                ? null
                : evaluation_data && (
                      <div
                          style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "10%",
                              marginLeft: "0px",
                              marginBottom: "10px",
                          }}
                      >
                          <button
                              style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  borderRadius: "6px",
                                  background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  padding: "0px 4px",
                                  marginBottom: "4px",
                                  color: "black",
                              }}
                          >
                              Technical Signals
                          </button>
                      </div>
                  )}
            {mediaQueryVar
                ? null
                : evaluation_data && (
                      <div style={{marginBottom: "10px"}}>
                          <div
                              style={{
                                  backgroundColor: "#E5F3FF",
                                  borderRadius: "8px",
                                  border: "0.3px solid #1877F2",
                                  padding: "12px",
                                  width: "100%",
                                  fontFamily: "Satoshi, sans-serif",
                                  fontWeight: "400",
                              }}
                          >
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>RSI</div>
                                  <div>{renderAction(evaluation_data.rsi_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Momentum</div>
                                  <div>{renderAction(evaluation_data.mom_oscillator_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>MACD</div>
                                  <div> {renderAction(evaluation_data.macd_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>OBV</div>
                                  <div> {renderAction(evaluation_data.obv_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Bollinger Bands</div>
                                  <div> {renderAction(evaluation_data.bbands_evaluation) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Head and Shoulders</div>
                                  <div> {renderAction(evaluation_data.head_shoulders) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                  <div>Triangles</div>
                                  <div> {renderAction(evaluation_data.triangles) || "-"}</div>
                              </div>
                              <div style={{display: "flex", justifyContent: "space-between", marginBottom: "0px"}}>
                                  <div>Fibonacci Retracement</div>
                                  <div> {renderAction(evaluation_data.fibonacci_evaluation) || "-"}</div>
                              </div>
                          </div>
                      </div>
                  )}

            {mediaQueryVar
                ? null
                : analysis_data && (
                      <div
                          style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "10%",
                              marginLeft: "0px",
                              marginBottom: "10px",
                          }}
                      >
                          <button
                              style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  borderRadius: "6px",
                                  background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  padding: "0px 4px",
                                  marginBottom: "4px",
                                  color: "black",
                              }}
                          >
                              Influencer Signals
                              {paid_member === "no" && (
                                  <img
                                      src={window.constants.asset_path + "/images/lock-new.svg"}
                                      width="24"
                                      height="24"
                                      style={{marginLeft: "6px", marginRight: "2px"}}
                                  />
                              )}
                          </button>
                      </div>
                  )}
            {mediaQueryVar
                ? null
                : analysis_data && (
                      <div
                          style={{
                              backgroundColor: "#E5F3FF",
                              borderRadius: "8px",
                              border: "0.3px solid #1877F2",
                              padding: "12px",
                              width: "100%",
                              fontFamily: "Satoshi, sans-serif",
                              fontWeight: "400",
                          }}
                      >
                          {paid_member === "yes" ? (
                              <>
                                  {/* Render the data if the user is a paid member */}
                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>All Influencers</div>
                                      <div>{renderAction(analysis_data.hashtags) || "-"}</div>
                                  </div>
                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>Top 10% Influencers</div>
                                      <div>{renderAction(analysis_data.top_10p_influencers) || "-"}</div>
                                  </div>
                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>Institutions</div>
                                      <div>{renderAction(analysis_data.indices) || "-"}</div>
                                  </div>
                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>Whales</div>
                                      <div>{renderAction(analysis_data.whales) || "-"}</div>
                                  </div>
                                 

                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "8px"}}>
                                      <div>Individuals</div>
                                      <div>{renderAction(analysis_data.social_media) || "-"}</div>
                                  </div>

                                  <div style={{display: "flex", justifyContent: "space-between", marginBottom: "0px"}}>
                                      <div>Grade</div>
                                      <div>{renderAction(analysis_data.grade) || "-"}</div>
                                  </div>
                              </>
                          ) : (
                              // Render the subscription button if the user is not a paid member
                              <div
                                  style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      backgroundColor: "#e7f2fd",
                                      borderRadius: "5px",
                                      padding: "2px",
                                      fontFamily: "Satoshi, sans-serif",
                                      width: "100%",
                                  }}
                              >
                                  {/* Left Column: List of Items */}
                                  <div style={{flex: 1}}>
                                      <div style={{marginBottom: "8px"}}>All Influencers</div>
                                      <div style={{marginBottom: "8px"}}>Top 10% Influencers</div>
                                      <div style={{marginBottom: "8px"}}>Indices</div>
                                      <div style={{marginBottom: "8px"}}>Whales</div>
                                      <div style={{marginBottom: "8px"}}>Individuals</div>
                                      <div style={{marginBottom: "0px"}}>Grade</div>
                                  </div>

                                  {/* Right Column: Button */}
                                  <div
                                      style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                      }}
                                  >
                                      <button
                                          style={{
                                              background: "linear-gradient(90deg, #1877F2 -19.84%, #519DFF 99.98%)",
                                              color: "#fff",
                                              fontSize: "12px",
                                              fontWeight: "700",
                                              borderRadius: "24.866px",
                                              padding: "10px 20px",
                                              border: "none",
                                              cursor: "pointer",
                                          }}
                                          onClick={handleNavigation}
                                      >
                                          Premium Signals @ $1 per month
                                      </button>
                                  </div>
                              </div>
                          )}
                      </div>
                  )}
            {mediaQueryVar
                ? null
                : loader === true && (
                      <div
                          style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center", // Center align buttons horizontally
                              gap: "20px", // Space between buttons
                              marginTop: "10%", // Space above the group
                          }}
                      >
                          <button
                              onClick={() => {
                                  navigate(`/crypto/${crypto}/predictions`);
                                  window.scrollTo({top: 0, behavior: "smooth"}); // Scroll to the top of the page smoothly
                              }}
                              style={{
                                  padding: "9px 20px",
                                  fontSize: "20px",
                                  backgroundColor: "#fff",
                                  color: "#1E88E5",
                                  border: "2px solid #1E88E5",
                                  borderRadius: "6px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                  cursor: "pointer",
                                  fontWeight: "700",
                                  marginBottom: "-9px",
                              }}
                          >
                              <span
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "28.769px",
                                      height: "31.385px",
                                      backgroundImage: `url(${
                                          window.constants.asset_path + "/images/round-arrow.svg"
                                      })`,
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                  }}
                              >
                                  <span
                                      style={{
                                          fontSize: "18px",
                                          color: "#1E88E5",
                                          marginTop: "5px",
                                      }}
                                  >
                                      $
                                  </span>
                              </span>
                              <span
                                  style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "center",
                                      margin: "0px 4px",
                                  }}
                              >
                                  Price Forecasts
                                  <span
                                      style={{
                                          color: "#1877F2",
                                          fontSize: "10px",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          marginLeft: "-15px",
                                      }}
                                  >
                                      By Influencers and Publishers
                                  </span>
                              </span>
                              <span style={{marginLeft: "auto"}}>
                                  <img
                                      src={window.constants.asset_path + "/images/link.svg"}
                                      width="24"
                                      height="24"
                                      alt="link"
                                  />
                              </span>
                          </button>

                          <button
                              onClick={() => {
                                  navigate(`/crypto-screener`);
                                  window.scrollTo({top: 0, behavior: "smooth"}); // Scroll to the top of the page smoothly
                              }}
                              style={{
                                  padding: "14px 20px",
                                  fontSize: "20px",
                                  backgroundColor: "#fff",
                                  color: "#1E88E5",
                                  border: "2px solid #1E88E5",
                                  borderRadius: "6px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                  cursor: "pointer",
                                  fontWeight: "700",
                                  marginBottom: "3%",
                              }}
                          >
                              <span
                                  style={{
                                      position: "relative",
                                      display: "flex",
                                      alignItems: "center",
                                  }}
                              >
                                  <span
                                      style={{
                                          width: "21.126px",
                                          height: "18.842px",
                                          backgroundImage: `url(${
                                              window.constants.asset_path + "/images/down-graph.svg"
                                          })`,
                                          backgroundSize: "contain",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                      }}
                                  ></span>
                                  <span
                                      style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                          width: "22.839px",
                                          height: "22.839px",
                                          backgroundImage: `url(${
                                              window.constants.asset_path + "/images/lens-new.svg"
                                          })`,
                                          backgroundSize: "contain",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          marginLeft: "2px",
                                          marginBottom: "3px",
                                      }}
                                  ></span>
                              </span>
                              Crypto Screener
                              <span style={{marginLeft: "auto"}}>
                                  <img
                                      src={window.constants.asset_path + "/images/link.svg"}
                                      width="24"
                                      height="24"
                                      alt="link"
                                      style={{marginLeft: "8px"}}
                                  />
                              </span>
                          </button>
                          <button
                              onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                              style={{
                                  textAlign: "center",
                                  color: "white",
                                  fontSize: "14px",
                                  width: "141px",
                                  height: "42px",
                                  backgroundColor: "#1877F2",
                                  padding: "4px 4px",
                                  borderRadius: "10px",
                                  border: "none",
                                  marginBottom: "6%", // Space below this button
                              }}
                          >
                              Best Portfolios
                              <img
                                  src={window.constants.asset_path + "/images/open_in_new_tab.svg"}
                                  alt="open in new tab"
                                  style={{marginLeft: "5px"}}
                              />
                          </button>
                      </div>
                  )}

            {mediaQueryVar
                ? null
                : overallSummary &&
                  loader && (
                      <div>
                          <button
                              style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  borderRadius: "6px",
                                  background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                  padding: "0px 4px",
                                  marginBottom: "25px",
                                  marginTop: "2%",
                                  color: "black",
                              }}
                          >
                              Technical Summary
                          </button>
                          <p style={{margin: "-10px 0 1% 1%"}}>
                              In the next week, the overall sentiment for {slug} crypto price is {overallSummary}. The
                              first level of support for {slug} is at {pivotdata.pivot_s1}&nbsp; while the first level
                              of resistance for {slug} price is at {pivotdata.pivot_r1}.&nbsp;
                              {sentimentStatement}
                          </p>
                      </div>
                  )}
            {mediaQueryVar ? null : (
                <div style={{fontFamily: "Satoshi, sans-serif", marginBottom: "6%"}}>
                    {(hasOscillatorData || hasMovingAverageData) && (
                        <div>
                            {(hasOscillatorData || hasMovingAverageData) && (
                                <div
                                    style={{
                                        width: "98%",
                                        border: "1px solid #DFDFDF",
                                        borderRadius: "6px",
                                        margin: "0 0 20px",
                                        overflow: "hidden",
                                        backgroundColor: "#fff",
                                    }}
                                >
                                    <table
                                        style={{
                                            fontSize: "14px",
                                            width: "100%",
                                            borderCollapse: "collapse",
                                            textAlign: "left",
                                        }}
                                    >
                                        <tbody>
                                            {/* Summary Row */}
                                            <tr style={{borderBottom: "1px solid #DADEDF"}}>
                                                <td
                                                    style={{
                                                        color: "#76787A",
                                                        fontSize: "14px",
                                                        textAlign: "left", // Align text to the left
                                                        padding: "8px",
                                                    }}
                                                >
                                                    Summary
                                                </td>
                                                <td
                                                    style={{
                                                        fontSize: "14px",
                                                        textAlign: "right", // Align text to the right
                                                        padding: "8px",
                                                    }}
                                                >
                                                    {renderAction(overallSummary)}
                                                </td>
                                            </tr>

                                            {/* Technical Indicators Row */}
                                            <tr>
                                                <td
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        padding: "8px",
                                                        borderBottom: "1px solid #DFDFDF",
                                                        color: "#76787A",
                                                    }}
                                                >
                                                    Technical Indicators
                                                </td>
                                                <td
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        padding: "8px",
                                                        borderBottom: "1px solid #DFDFDF",
                                                        color: "#008000", // Green text
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {renderAction(oscillatorSummary)}
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#000", // Neutral black
                                                            fontWeight: "normal",
                                                        }}
                                                    >
                                                        Buy: ({oscillatorBuys || "0"}) Sell: ({oscillatorSells || "0"})
                                                        Neutral: ({oscillatorNeutrals || "0"})
                                                    </span>
                                                </td>
                                            </tr>

                                            {/* Moving Averages Row */}
                                            <tr>
                                                <td
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        padding: "8px",
                                                        borderBottom: "1px solid #DFDFDF",
                                                        color: "#76787A",
                                                    }}
                                                >
                                                    Moving Averages
                                                </td>
                                                <td
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        padding: "8px",
                                                        borderBottom: "1px solid #DFDFDF",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {renderAction(movingAverageSummary)}
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "#000", // Neutral black
                                                            fontWeight: "normal",
                                                        }}
                                                    >
                                                        Buy: ({movingAverageBuys || "0"}) Sell: (
                                                        {movingAverageSells || "0"}) Neutral: (
                                                        {movingAverageNeutrals || "0"})
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {(hasOscillatorData || hasMovingAverageData) && (
                                <h5
                                    style={{
                                        textAlign: "right",
                                        fontStyle: "italic",
                                        margin: "5px 1%",
                                        fontSize: "10px",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    {timestamp}&nbsp;UTC
                                </h5>
                            )}

                            {oscillatorData.length > 0 && (
                                <>
                                    {/* Check if either RSI or MACD is present */}
                                    {(oscillatorData[0].RSI || oscillatorData[4].MACD) && (
                                        <>
                                            {oscillatorData[0].RSI && (
                                                <p style={{fontSize: "16px", margin: "0% 2% 0% 0.5%"}}>
                                                    The RSI value is at {oscillatorData[0].RSI}, which indicates that
                                                    the {slug} market is in a {oscillatorData[0].rsi_evaluation}{" "}
                                                    position.
                                                </p>
                                            )}

                                            {oscillatorData[4].MACD && (
                                                <p
                                                    style={{
                                                        fontSize: "16px",
                                                        margin: "2% 1% 0% 0.5%",
                                                    }}
                                                >
                                                    The MACD value is {oscillatorData[4].MACD}.{" "}
                                                    {oscillatorData[4].macd_evaluation === "Buy" ? (
                                                        <>
                                                            The MACD line is above the signal line, and the green
                                                            histogram bars have started to increase on the positive
                                                            axis. This indicates that {slug} is in a buy position.
                                                        </>
                                                    ) : (
                                                        <>
                                                            The MACD line is below the signal line, and the red
                                                            histogram bars have started to increase on the negative
                                                            axis. This indicates that {slug} is in a sell position.
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                </p>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                            {movingAveragesData.length > 0 && (
                                <>
                                    {(movingAveragesData[0]["SMA (10)"] ||
                                        movingAveragesData[4]["EMA (10)"] ||
                                        movingAveragesData[1]["SMA (50)"] ||
                                        movingAveragesData[5]["EMA (50)"] ||
                                        movingAveragesData[2]["SMA (100)"] ||
                                        movingAveragesData[3]["SMA (200)"] ||
                                        movingAveragesData[6]["EMA (100)"] ||
                                        movingAveragesData[7]["EMA (200)"]) && (
                                        <>
                                            <p
                                                style={{
                                                    fontSize: "16px",
                                                    margin: "2% 1% 0% 0.5%",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                {movingAveragesData[0]["SMA (10)"] && (
                                                    <>
                                                        For {slug}, the 10-day SMA is{" "}
                                                        {movingAveragesData[0]["SMA (10)"]}. As the price of {slug} is{" "}
                                                        {currentPrice < movingAveragesData[0]["SMA (10)"]
                                                            ? "below"
                                                            : "above"}{" "}
                                                        the 10-day SMA, it is{" "}
                                                        {currentPrice < movingAveragesData[0]["SMA (10)"]
                                                            ? "bearish"
                                                            : "bullish"}
                                                        . The short-term outlook is{" "}
                                                        {currentPrice < movingAveragesData[0]["SMA (10)"]
                                                            ? "negative"
                                                            : "positive"}
                                                        .
                                                    </>
                                                )}

                                                {movingAveragesData[1]["SMA (50)"] && (
                                                    <>
                                                        &nbsp;Additionally, the 50-day SMA is{" "}
                                                        {movingAveragesData[1]["SMA (50)"]}. {slug} is{" "}
                                                        {currentPrice < movingAveragesData[1]["SMA (50)"]
                                                            ? "below"
                                                            : "above"}{" "}
                                                        its 50-day SMA, it is{" "}
                                                        {currentPrice < movingAveragesData[1]["SMA (50)"]
                                                            ? "bearish"
                                                            : "bullish"}
                                                        .
                                                    </>
                                                )}

                                                {(movingAveragesData[2]["SMA (100)"] ||
                                                    movingAveragesData[3]["SMA (200)"]) && (
                                                    <>
                                                        &nbsp;The 100-day and 200-day SMAs representing the long-term
                                                        outlook of {slug} are&nbsp;
                                                        {movingAveragesData[2]["SMA (100)"] || "-"} and{" "}
                                                        {movingAveragesData[3]["SMA (200)"] || "-"} respectively.
                                                        Currently, the price of {slug} is{" "}
                                                        {currentPrice < movingAveragesData[2]["SMA (100)"] &&
                                                        currentPrice < movingAveragesData[3]["SMA (200)"]
                                                            ? "below"
                                                            : "above"}{" "}
                                                        both the 100-day and 200-day Moving Averages. It is{" "}
                                                        {currentPrice < movingAveragesData[2]["SMA (100)"] &&
                                                        currentPrice < movingAveragesData[3]["SMA (200)"]
                                                            ? "bearish"
                                                            : "bullish"}
                                                        .
                                                    </>
                                                )}
                                            </p>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
            {mediaQueryVar
                ? null
                : loader === true && (
                      <div>
                          <div>
                              <button
                                  style={{
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                      borderRadius: "6px",
                                      background:
                                          "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                      padding: "0px 4px",
                                      marginBottom: "5px",
                                      marginTop: "5px",
                                      color: "black",
                                  }}
                              >
                                  {slug} Analysis
                              </button>
                          </div>
                          <div
                              className="description"
                              style={{
                                  fontFamily: "Satoshi, sans-serif!important",
                                  padding: "1%",
                                  borderRadius: "4px",
                                  marginBottom: "2%",
                                  marginTop: "0px",
                                  marginLeft: "0%",
                                  marginRight: "2%",
                              }}
                          >
                              {processedDescription ? (
                                  <div
                                      style={{marginTop: "6px"}}
                                      dangerouslySetInnerHTML={{__html: processedDescription}}
                                  />
                              ) : (
                                  <p></p> // or any other placeholder text
                              )}
                          </div>
                      </div>
                  )}
            {/* {mediaQueryVar
                ? null
                : loader === true && (
                      <div>
                          <p style={{fontSize: "20px", fontWeight: "700", color: "#1877F2", background: "white"}}>
                              Updates
                          </p>
                          <div
                              className="description"
                              style={{
                                  fontFamily: "Satoshi, sans-serif!important",
                                  borderRadius: "4px",
                                  padding: "2px",
                                  marginTop: "15px",
                                  marginBottom: "8%",
                              }}
                          >
                              {analysis_data.ai_news && analysis_data.ai_news != "NA" ? 
                              (
                                  analysis_data.ai_news
                                  .split("\n") 
                                  .filter((line) => line.trim() !== "") 
                                  .map((line, index) => (
                                      <p key={index}>{line.trim().replace(/^-\s, "")}</p> 
                                  ))
                              ) : (
                                  <p>No Updates Available</p>
                              )}
                          </div>
                      </div>
                  )} */}

            {mediaQueryVar ? (
                <div className="layout">
                    <div className>
                        <Box
                            component="nav"
                            sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                            aria-label="mailbox folders"
                        >
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <Drawer
                                container={container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: {xs: "block", sm: "none"},
                                    "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                        backgroundColor: "white",
                                        color: "#727376",
                                    },
                                }}
                            >
                                {drawer}
                            </Drawer>
                            <Drawer
                                variant="permanent"
                                sx={{
                                    display: {xs: "none", sm: "block"},
                                    "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                        backgroundColor: "white",
                                        color: "#727376",
                                    },
                                }}
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Box>
                    </div>

                    <div
                        className="content"
                        style={{
                            margin: "0%",
                            padding: "10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "#1877F2", // Background color for the container
                                    paddingTop: {xs: "10px", sm: "4px"}, // Padding inside the container
                                    paddingLeft: {xs: "0px", sm: "0px"},
                                    paddingRight: {xs: "0px", sm: "0px"},
                                    paddingBottom: {xs: "0px", sm: "4px"},
                                    borderRadius: "50px", // Border radius for the container
                                    display: "flex", // Make sure the box is flexible
                                    justifyContent: "flex-start", // Center the content
                                    alignItems: "center", // Center the content
                                    display: "inline-block",
                                    minWidth: isPredictionDataLoaded ? "400px" : "360px",
                                    height: {xs: "44px", sm: "48px"},
                                    border: "none",
                                    marginLeft: "0.3%",
                                    marginTop: "10px",
                                }}
                            >
                                <Tabs
                                    value={selectedButton}
                                    onChange={(event, newValue) => handleButtonClick(newValue)}
                                    aria-label="basic tabs example"
                                    sx={{
                                        alignSelf: mediaQueryVar === true ? "flex-start" : "",
                                        marginLeft: mediaQueryVar === true ? "2px" : "2px",
                                        borderRadius: "50px", // Adjust border radius for more rounded corners
                                        border: "none",
                                        "& .MuiTabs-indicator": {
                                            display: "none",
                                        },
                                        display: "flex", // Make sure the tabs are displayed in a row
                                        overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                                    }}
                                >
                                    <Tab
                                        label={isPredictionDataLoaded ? `${slug} Price Analysis` : "Price Analysis"}
                                        value="CoinPrices"
                                        sx={{
                                            whiteSpace: "nowrap",
                                            bottom: "0px",
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                            marginLeft: "3px",
                                            marginRight: "3px",
                                            paddingBottom: "0px",
                                            paddingTop: "0px",
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            minHeight: "20px",
                                            lineHeight: {xs: "36px", sm: "36px"},
                                            borderRadius: selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
                                            textTransform: "none",
                                            fontSize: "17px",
                                            fontStyle: "normal",
                                            fontWeight: selectedButton === "CoinPrices" ? "700" : "500",

                                            backgroundColor:
                                                selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                                            color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                            border: "none", // Remove border for tabs
                                            background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
                                        }}
                                    />
                                    {/* <Tab
      label={userData ? "My Portfolios" : " Boost Portfolio"} // Dynamic label
      value="AuditPortfolio"
      onClick={() => {
        navigate(userData ? "/my-portfolio" : "/audit-my-portfolio");
      }}
      sx={{
        whiteSpace: "nowrap",
        bottom: "0px",
        marginTop: "0px",
        marginBottom: "0px",
        marginLeft: "3px",
        marginRight: "-10px",
        paddingBottom: "0px",
        paddingTop: "0px",
        paddingLeft: "20px",
        paddingRight: "20px",
        minHeight: "20px",
        lineHeight: { xs: "36px", sm: "36px" },
        borderRadius: selectedButton === "AuditPortfolio" ? "50px" : "50px", // Rounded corners for selected tab
        textTransform: "none",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: selectedButton === "AuditPortfolio" ? "700" : "500",
        backgroundColor: selectedButton === "AuditPortfolio" ? "#FFFFFF" : "transparent", // White background for selected tab
        color: selectedButton === "AuditPortfolio" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
        border: "none", // Remove border for tabs
      }}
    /> */}
                                    <Tab
                                        label={userData ? "Predict and Win" : "Audit My Portfolio"}
                                        value="PredictandWin"
                                        onClick={() => {
                                            if (userData) {
                                                navigate("/predict-now", { state: { cryptoValue: slug } });
                                            } else {
                                                navigate("/audit-my-portfolio");
                                            }
                                        }}
                                        sx={{
                                            whiteSpace: "nowrap",
                                            bottom: "0px",
                                            marginTop: "1.1px",
                                            marginBottom: "0px",
                                            marginLeft: "3px",
                                            marginRight: "3px",
                                            paddingBottom: "2px",
                                            paddingTop: "0px",
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            minHeight: "20px",
                                            lineHeight: {xs: "28px", sm: "36px"},
                                            borderRadius: selectedButton === "PredictandWin" ? "20px" : "20px", // Rounded corners for selected tab
                                            textTransform: "none",
                                            fontSize: {xs: "13px", sm: "17px"},
                                            fontStyle: "normal",
                                            fontWeight: selectedButton === "PredictandWin" ? "700" : "500",

                                            backgroundColor:
                                                selectedButton === "PredictandWin" ? "#FFFFFF" : "transparent", // White background for selected tab
                                            color:
                                                selectedButton === "PredictandWin" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
                                            border: "none", // Remove border for tabs
                                            background: selectedButton === "PredictandWin" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
                                        }}
                                    />
                                </Tabs>
                            </Box>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "14px",
                                marginLeft: "0.3%",
                                marginRight: "0.5%",
                            }}
                        >
                            <div style={{display: "flex", alignItems: "center"}}>
                                <button
                                    onClick={() => handleAddToPortfolio(slug)}
                                    style={{
                                        border: "1px solid #1877F2",
                                        borderRadius: "48px",
                                        padding: "5px 10px",
                                        backgroundColor: "white",
                                        color: "#1877F2",
                                        fontSize: "14px",
                                        height: "33px",
                                        marginRight: "8px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Add to Portfolio
                                </button>
                                {/* <button
      onClick={() => alert("Alerts functionality coming soon!")}
      style={{
        border: "1px solid #1877F2",
        borderRadius: "6px",
        padding: "6px 14px",
        backgroundColor: "rgba(24, 119, 242, 0.12)",
        color: "#1877F2",
        fontSize: "14px",
        height: "33px",
        border: "1px solid rgba(24, 119, 242, 0.12)",
        fontWeight:"500"
      }}>
      <img src={window.constants.asset_path + "/images/notification.svg"} width="14" height="14" style={{ marginRight: "5px" }} />
      Alerts
    </button> */}
                            </div>
                        </div>

                        <div
                            style={{
                                borderRadius: "10px",
                                backgroundColor: "#fff",
                                padding: "10px 0px 0px 0px",
                                fontFamily: "Satoshi, sans-serif",
                                margin: "15px 5px",
                            }}
                        >
                            <button
                                style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                    borderRadius: "12px",
                                    background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                    padding: "4px 10px",
                                    marginLeft: "5px",
                                    marginBottom: "8px",
                                    color: "black",
                                }}
                            >
                                Performance Metrics
                            </button>
                            {/* <button
      onClick={() => handleAddToPortfolio(slug)}
      style={{
        border: "1px solid #1877F2",
        borderRadius: "48px",
        padding: "6px 14px",
        backgroundColor: "white",
        color: "#1877F2",
        fontSize: "14px",
        height: "33px",
        marginLeft: "6px",
        fontWeight:"500"
      }}>
      Add to Watchlist
    </button> */}

                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <TableContainer
                                    style={{
                                        width: "100%",
                                        overflowX: "hidden",
                                        padding: 0,
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #DADEDF",
                                                        padding: 0,
                                                        width: "30%",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        Crypto
                                                    </button>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #DADEDF",
                                                        padding: 0,
                                                        width: "12%",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        7 Days
                                                    </button>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #DADEDF",
                                                        padding: 0,
                                                        width: "12%",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        30 Days
                                                    </button>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #DADEDF",
                                                        padding: 0,
                                                        width: "14%",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        12 Months
                                                    </button>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #DADEDF",
                                                        padding: 0,
                                                        //width: "auto",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        Buy Intent
                                                    </button>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    style={{
                                                        border: 0,
                                                        borderTop: "1px solid #1877F2",
                                                        borderBottom: "1px solid #1877F2",
                                                        borderRight: "1px solid #DADEDF",
                                                        padding: 0,
                                                        //width: "auto",
                                                    }}
                                                >
                                                    <button
                                                        disabled
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#1877F2",
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            background: "rgba(24, 119, 242, 0.12)",
                                                            padding: "19px 12px 5px 12px",
                                                            fontWeight: "900",
                                                            border: "none",
                                                        }}
                                                    >
                                                        Market Cap
                                                    </button>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {loader === false ? (
                                                <StyledTableRow>
                                                    {[...Array(6)].map((_, index) => (
                                                        <StyledTableCell key={index}>
                                                            <Stack>
                                                                <Skeleton
                                                                    sx={{marginTop: "10px"}}
                                                                    variant="rounded"
                                                                    height={40}
                                                                />
                                                            </Stack>
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            ) : (
                                                <StyledTableRow style={{backgroundColor: "white"}}>
                                                    <StyledTableCell
                                                        component="th"
                                                        scope="row"
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            paddingLeft: "16px",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            borderRight: "1px solid #DADEDF",
                                                            width: "auto",
                                                            height: "5vw",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {/* Left section with logo and name */}
                                                            <div style={{display: "flex", alignItems: "center"}}>
                                                                <img
                                                                    width={35}
                                                                    height={35}
                                                                    style={{
                                                                        borderRadius: "19px",
                                                                        border: "2px solid black",
                                                                        marginLeft: "-8px",
                                                                    }}
                                                                    alt=""
                                                                    src={symbolLogo}
                                                                />
                                                                <span style={{marginLeft: "8px"}}>{slug}</span>
                                                            </div>

                                                            {/* Middle section with price */}
                                                            <div style={{textAlign: "center", fontWeight: "500"}}>
                                                                ${currentPrice}
                                                            </div>

                                                            {/* Right section with percentage */}
                                                            <div
                                                                style={{
                                                                    textAlign: "right",
                                                                    color: parseFloat(tfhrs) > 0 ? "green" : "red",
                                                                }}
                                                            >
                                                                {parseFloat(tfhrs) > 0 ? `+${tfhrs}` : tfhrs}
                                                            </div>
                                                        </div>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            borderRight: "1px solid #DADEDF",
                                                            width: "75px",
                                                            height: "5vw",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: parseFloat(sevenDays) > 0 ? "green" : "red",
                                                            }}
                                                        >
                                                            {parseFloat(sevenDays) > 0 ? `+${sevenDays}` : sevenDays}
                                                        </span>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            borderRight: "1px solid #DADEDF",
                                                            width: "75px",
                                                            height: "5vw",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: parseFloat(thirtyDays) > 0 ? "green" : "red",
                                                            }}
                                                        >
                                                            {parseFloat(thirtyDays) > 0 ? `+${thirtyDays}` : thirtyDays}
                                                        </span>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            borderRight: "1px solid #DADEDF",
                                                            width: "75px",
                                                            height: "5vw",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color:
                                                                    yearDays == "NA"
                                                                        ? "black"
                                                                        : parseFloat(yearDays) > 0
                                                                        ? "green"
                                                                        : "red",
                                                            }}
                                                        >
                                                            {parseFloat(yearDays) > 0 ? `+${yearDays}` : yearDays}
                                                        </span>
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            borderRight: "1px solid #DADEDF",
                                                            width: "75px",
                                                            height: "5vw",
                                                        }}
                                                    >
                                                        {buyPercent}
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        style={{
                                                            borderBottom: "none",
                                                            color: "#000",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            textAlign: "center",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            width: "85px",
                                                            height: "5vw",
                                                            borderRight: "1px solid #DADEDF",
                                                        }}
                                                    >
                                                        {marketCap}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div
                                    style={{
                                        borderRadius: "5px",
                                        border: "1px solid #DADEDF",
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        height: "120px",
                                    }}
                                >
                                    <button
                                        onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                                        style={{
                                            textAlign: "center",
                                            color: "#727376",
                                            fontSize: "16px",
                                            width: "141px",
                                            height: "46px",
                                            fontSize: "14px",
                                            color: "white",
                                            backgroundColor: "#1877F2",
                                            padding: "4px 4px",
                                            borderRadius: "10px",
                                            border: "none",
                                        }}
                                    >
                                        Best Portfolios
                                        <img
                                            src={window.constants.asset_path + "/images/open_in_new_tab.svg"}
                                            style={{marginLeft: "5px"}}
                                        ></img>
                                    </button>
                                    <div
                                        style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            textAlign: "center",
                                            padding: "11px 5px",
                                        }}
                                        onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                                    >
                                        <span
                                            style={{
                                                color: "black",
                                            }}
                                        >
                                            {holdings}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {evaluation_data && (
                            <div
                                style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    padding: "10px 0px 0px 0px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "15px 5px",
                                }}
                            >
                                <button
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        borderRadius: "12px",
                                        background:
                                            "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        padding: "4px 10px",
                                        marginLeft: "5px",
                                        marginBottom: "8px",
                                        color: "black",
                                    }}
                                >
                                    Outlook Summary
                                </button>

                                <TableContainer
                                    style={{
                                        width: "100%",
                                        overflowX: "hidden",
                                        padding: 0,
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {["Price Target 2025", "Accuracy", "Recommendation"].map(
                                                    (label, index, array) => (
                                                        <StyledTableCell
                                                            key={index}
                                                            className="cryptotableviewHeader"
                                                            style={{
                                                                padding: 0,
                                                                whiteSpace: "normal", // Allow text to wrap
                                                                wordWrap: "break-word", // Ensure longer words break properly
                                                                border: "none", // Remove all default borders
                                                                borderTop: "1px solid #1877F2", // Top border
                                                                borderBottom: "1px solid #1877F2", // Bottom border
                                                                borderRight:
                                                                    index !== array.length - 1
                                                                        ? "1px solid #DADEDF"
                                                                        : "none", // Right border for all but last column
                                                                height: "40px", // Increased height for the header
                                                                textAlign: "center", // Centering the text
                                                                width: "auto%", // Ensure each header cell takes equal width (100% / 8 columns)
                                                            }}
                                                        >
                                                            <button
                                                                disabled
                                                                style={{
                                                                    color: "#1877F2",
                                                                    fontSize: "16px",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    borderBottom: "none",
                                                                    background: "rgba(24, 119, 242, 0.12)",
                                                                    padding: "19px 12px 5px 12px",
                                                                    fontWeight: "900",
                                                                    border: "none", // Remove borders from the button
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {label}
                                                            </button>
                                                        </StyledTableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {loader === false ? (
                                                <StyledTableRow>
                                                    {[...Array(7)].map((_, index) => (
                                                        <StyledTableCell key={index}>
                                                            <Stack>
                                                                <Skeleton
                                                                    sx={{marginTop: "8px"}}
                                                                    variant="rounded"
                                                                    height={40}
                                                                />
                                                            </Stack>
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            ) : (
                                                <StyledTableRow style={{backgroundColor: "white"}}>
                                                    {[
                                                        outlookData.target,
                                                        outlookData.accuracy,
                                                        outlookData.recommendation,
                                                    ].map((data, index, array) => (
                                                        <StyledTableCell
                                                            key={index}
                                                            style={{
                                                                borderBottom: "none",
                                                                color: "#000",
                                                                fontSize: "16px",
                                                                fontWeight: "500",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                paddingBottom: "12px",
                                                                borderRight:
                                                                    index !== array.length - 1
                                                                        ? "1px solid #DADEDF"
                                                                        : "none", // No borderRight for the last column
                                                                width: "auto", // Ensure each column in the body has equal width
                                                                height: "5vw",
                                                            }}
                                                        >
                                                            {data === outlookData.recommendation
                                                                ? renderAction(data)
                                                                : data || "-"}
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}

                        {evaluation_data && (
                            <div
                                style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    padding: "10px 0px 0px 0px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "15px 5px",
                                }}
                            >
                                <button
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        borderRadius: "12px",
                                        background:
                                            "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        padding: "4px 10px",
                                        marginLeft: "5px",
                                        marginBottom: "8px",
                                        color: "black",
                                    }}
                                >
                                    Technical Signals
                                </button>

                                <TableContainer
                                    style={{
                                        width: "100%",
                                        overflowX: "hidden",
                                        padding: 0,
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {[
                                                    "RSI",
                                                    "Momentum",
                                                    "MACD",
                                                    "OBV",
                                                    "Bollinger Bands",
                                                    "Head and Shoulders",
                                                    "Triangles",
                                                    "Fibonacci Retracement",
                                                ].map((label, index, array) => (
                                                    <StyledTableCell
                                                        key={index}
                                                        className="cryptotableviewHeader"
                                                        style={{
                                                            padding: 0,
                                                            whiteSpace: "normal", // Allow text to wrap
                                                            wordWrap: "break-word", // Ensure longer words break properly
                                                            border: "none", // Remove all default borders
                                                            borderTop: "1px solid #1877F2", // Top border
                                                            borderBottom: "1px solid #1877F2", // Bottom border
                                                            borderRight:
                                                                index !== array.length - 1
                                                                    ? "1px solid #DADEDF"
                                                                    : "none", // Right border for all but last column
                                                            height: "40px", // Increased height for the header
                                                            textAlign: "center", // Centering the text
                                                            width: "auto%", // Ensure each header cell takes equal width (100% / 8 columns)
                                                        }}
                                                    >
                                                        <button
                                                            disabled
                                                            style={{
                                                                color: "#1877F2",
                                                                fontSize: "16px",
                                                                width: "100%",
                                                                height: "100%",
                                                                borderBottom: "none",
                                                                background: "rgba(24, 119, 242, 0.12)",
                                                                padding: "19px 12px 5px 12px",
                                                                fontWeight: "900",
                                                                border: "none", // Remove borders from the button
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {label}
                                                        </button>
                                                    </StyledTableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {loader === false ? (
                                                <StyledTableRow>
                                                    {[...Array(7)].map((_, index) => (
                                                        <StyledTableCell key={index}>
                                                            <Stack>
                                                                <Skeleton
                                                                    sx={{marginTop: "8px"}}
                                                                    variant="rounded"
                                                                    height={40}
                                                                />
                                                            </Stack>
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            ) : (
                                                <StyledTableRow style={{backgroundColor: "white"}}>
                                                    {[
                                                        evaluation_data.rsi_evaluation,
                                                        evaluation_data.mom_oscillator_evaluation,
                                                        evaluation_data.macd_evaluation,
                                                        evaluation_data.obv_evaluation,
                                                        evaluation_data.bbands_evaluation,
                                                        evaluation_data.head_shoulders,
                                                        evaluation_data.triangles,
                                                        evaluation_data.fibonacci_evaluation,
                                                    ].map((data, index, array) => (
                                                        <StyledTableCell
                                                            key={index}
                                                            style={{
                                                                borderBottom: "none",
                                                                color: "#000",
                                                                fontSize: "16px",
                                                                fontWeight: "500",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                paddingBottom: "12px",
                                                                borderRight:
                                                                    index !== array.length - 1
                                                                        ? "1px solid #DADEDF"
                                                                        : "none", // No borderRight for the last column
                                                                width: "auto", // Ensure each column in the body has equal width
                                                                height: "5vw",
                                                            }}
                                                        >
                                                            {renderAction(data) || "-"}
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <TableContainer
            style={{
              width: "100%",
              overflowX: "hidden",
              border: "1px solid #DADEDF",
              borderRadius: "5px",
              padding: 0,
              marginTop: "20px"
            }}
          >
            <Table className="non_responsiveTable" aria-label="customized table" style={{ borderCollapse: "collapse" }}>
  <TableHead>
    <TableRow>
      {[ 'SMA (10)', 
        'SMA (50)', 'SMA (100)', 'SMA (200)'].map((label, index) => (
        <StyledTableCell
          key={index}
          className="cryptotableviewHeader"
          style={{
            padding: 0,
            whiteSpace: "normal", // Allow text to wrap
            wordWrap: "break-word", // Ensure long words break
            border: 0,
            textAlign: "center", // Center text in the header
            width: "12.5%", // Ensures equal width for all columns (100% / 8 columns)
          }}
        >
          <button
            disabled
            style={{
              color: "#727376",
              fontSize: "16px",
              width: "100%",
              height: "100%",
              borderBottom: "none",
              background: "#D3E6FD",
              padding: "12px 12px",
              borderRight: "1px solid #DADEDF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {label}
          </button>
        </StyledTableCell>
      ))}
    </TableRow>
  </TableHead>
  <TableBody>
    {loader === false ? (
      <StyledTableRow>
        {[...Array(8)].map((_, index) => (
          <StyledTableCell key={index}>
            <Stack>
              <Skeleton sx={{ marginTop: '8px' }} variant="rounded" height={40} />
            </Stack>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    ) : (
      <StyledTableRow style={{ backgroundColor: "white" }}>
        {[
         
          evaluation_data.sma_10_evaluation,
          evaluation_data.sma_50_evaluation,
          evaluation_data.sma_100_evaluation,
          evaluation_data.sma_200_evaluation,
        ].map((data, index) => (
          <StyledTableCell
            key={index}
            style={{
              borderBottom: "none",
              color: "#000",
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              borderRight: "1px solid #DADEDF",
              width: "12.5%", // Ensures equal width for all columns in the body
            }}
          >
            {renderAction(data) || '-'}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    )}
  </TableBody>
</Table>

          </TableContainer>   */}
                            </div>
                        )}

                        {analysis_data && (
                            <div
                                style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    padding: "10px 0px 0px 0px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "15px 5px",
                                }}
                            >
                                <button
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        borderRadius: "12px",
                                        background:
                                            "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        padding: "4px 10px",
                                        marginLeft: "5px",
                                        marginBottom: "8px",
                                        color: "black",
                                    }}
                                >
                                    Influencer Signals
                                    {paid_member === "no" && (
                                        <img
                                            src={window.constants.asset_path + "/images/lock-new.svg"}
                                            width="24"
                                            height="24"
                                            style={{marginLeft: "4px"}}
                                        />
                                    )}
                                </button>

                                {paid_member === "yes" ? (
                                    <TableContainer
                                        style={{
                                            width: "100%",
                                            overflowX: "hidden",

                                            padding: 0,
                                        }}
                                    >
                                        <Table style={{borderCollapse: "collapse"}}>
                                            <TableHead>
                                                <TableRow>
                                                    {[
                                                        "All Influencers",
                                                        "Top 10% Influencers",
                                                        "Institutions",
                                                        "Whales",
                                                        "Individuals",
                                                        "Grade",
                                                    ].map((label, index, array) => (
                                                        <StyledTableCell
                                                            key={index}
                                                            className="cryptotableviewHeader"
                                                            style={{
                                                                padding: 0,
                                                                whiteSpace: "nowrap", // Prevent word wrapping
                                                                wordWrap: "normal", // Ensure words don't break
                                                                border: "none", // Remove all default borders
                                                                borderTop: "1px solid #1877F2", // Top border
                                                                borderBottom: "1px solid #1877F2", // Bottom border
                                                                borderRight:
                                                                    index !== array.length - 1
                                                                        ? "1px solid #DADEDF"
                                                                        : "none", // Right border for all but last column
                                                                height: "40px", // Increased height for the header
                                                                textAlign: "center", // Centering the text
                                                                paddingLeft: "12px", // Added padding for better readability,
                                                                background: "rgba(24, 119, 242, 0.12)",
                                                                paddingRight: "12px", // Added padding for better readability
                                                            }}
                                                        >
                                                            <button
                                                                disabled
                                                                style={{
                                                                    color: "#1877F2",
                                                                    fontSize: "16px",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    borderBottom: "none",
                                                                    background: "none",
                                                                    padding: "19px 12px 5px 12px",
                                                                    fontWeight: "900",
                                                                    border: "none", // Remove borders from the button
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {label}
                                                            </button>
                                                        </StyledTableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {loader === false ? (
                                                    <StyledTableRow>
                                                        {[...Array(4)].map((_, index) => (
                                                            <StyledTableCell key={index}>
                                                                <Stack>
                                                                    <Skeleton
                                                                        sx={{marginTop: "8px"}}
                                                                        variant="rounded"
                                                                        height={40}
                                                                    />
                                                                </Stack>
                                                            </StyledTableCell>
                                                        ))}
                                                    </StyledTableRow>
                                                ) : (
                                                    <StyledTableRow style={{backgroundColor: "white"}}>
                                                        {[
                                                            analysis_data.hashtags,
                                                            analysis_data.top_10p_influencers,
                                                            analysis_data.indices,
                                                            analysis_data.whales,
                                                            analysis_data.social_media,
                                                            analysis_data.grade,
                                                        ].map((data, index, array) => (
                                                            <StyledTableCell
                                                                key={index}
                                                                style={{
                                                                    borderBottom: "none",
                                                                    color: "#000",
                                                                    fontSize: "16px",
                                                                    fontWeight: "500",
                                                                    textAlign: "center",
                                                                    paddingTop: "12px",
                                                                    paddingBottom: "12px",
                                                                    borderRight:
                                                                        index !== array.length - 1
                                                                            ? "1px solid #DADEDF"
                                                                            : "none",
                                                                    height: "5vw",
                                                                }}
                                                            >
                                                                {renderAction(data) || "-"}
                                                            </StyledTableCell>
                                                        ))}
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <div>
                                        <TableContainer
                                            style={{
                                                width: "100%",
                                                overflowX: "hidden",
                                                border: "1px solid #DADEDF",
                                                borderRadius: "0px",
                                                padding: 0,
                                            }}
                                        >
                                            <Table
                                                className="non_responsiveTable"
                                                aria-label="customized table"
                                                style={{borderCollapse: "collapse"}}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        {[
                                                            "All Influencers",
                                                            "Top 10% Influencers",
                                                            "Institutions",
                                                            "Whales",
                                                            "Individuals",
                                                            "Grade",
                                                        ].map((label, index, array) => (
                                                            <StyledTableCell
                                                                key={index}
                                                                className="cryptotableviewHeader"
                                                                style={{
                                                                    padding: 0,
                                                                    whiteSpace: "nowrap", // Prevent word wrapping
                                                                    wordWrap: "normal", // Ensure words don't break
                                                                    border: "none", // Remove all default borders
                                                                    borderTop: "1px solid #1877F2", // Top border
                                                                    borderBottom: "1px solid #1877F2", // Bottom border
                                                                    borderRight:
                                                                        index !== array.length - 1
                                                                            ? "1px solid #DADEDF"
                                                                            : "none", // Right border for all but last column
                                                                    height: "40px", // Increased height for the header
                                                                    textAlign: "center", // Centering the text
                                                                    paddingLeft: "12px", // Added padding for better readability,
                                                                    background: "rgba(24, 119, 242, 0.12)",
                                                                    paddingRight: "12px", // Added padding for better readability
                                                                }}
                                                            >
                                                                <button
                                                                    disabled
                                                                    style={{
                                                                        color: "#1877F2",
                                                                        fontSize: "16px",
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        borderBottom: "none",
                                                                        background: "none",
                                                                        padding: "19px 12px 5px 12px",
                                                                        fontWeight: "900",
                                                                        border: "none", // Remove borders from the button
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {label}
                                                                </button>
                                                            </StyledTableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {loader === false ? (
                                                        <StyledTableRow>
                                                            {[...Array(7)].map((_, index) => (
                                                                <StyledTableCell key={index}>
                                                                    <Stack>
                                                                        <Skeleton
                                                                            sx={{marginTop: "8px"}}
                                                                            variant="rounded"
                                                                            height={40}
                                                                        />
                                                                    </Stack>
                                                                </StyledTableCell>
                                                            ))}
                                                        </StyledTableRow>
                                                    ) : (
                                                        <StyledTableRow style={{backgroundColor: "white"}}>
                                                            <td
                                                                colSpan="100%"
                                                                style={{textAlign: "center", height: "5vw"}}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        marginTop: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    <button
                                                                        style={{
                                                                            background:
                                                                                "linear-gradient(90deg, #1877F2 -19.84%, #519DFF 99.98%)",
                                                                            color: "#fff",
                                                                            fontSize: "17px",
                                                                            fontWeight: "700",
                                                                            borderRadius: "25px",
                                                                            padding: "7px 20px 10px 20px",
                                                                            border: "none",
                                                                            cursor: "pointer",
                                                                            width: "auto",
                                                                            height: "40px",
                                                                        }}
                                                                        onClick={handleNavigation}
                                                                    >
                                                                        Premium Signals @ $1 per month
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </StyledTableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                )}
                            </div>
                        )}
                        {loader == true && (
                            <div style={{display: "flex", justifyContent: "center", gap: "5%"}}>
                                <button
                                    onClick={() => {
                                        navigate(`/crypto/${crypto}/predictions`);
                                        window.scrollTo({top: 0, behavior: "smooth"}); // Scroll to the top of the page smoothly
                                    }}
                                    style={{
                                        padding: "6px 12px",
                                        fontSize: "19px",
                                        backgroundColor: "#fff", // Light blue background
                                        color: "#1E88E5", // Blue text
                                        border: "2px solid #1E88E5",
                                        borderRadius: "6px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        cursor: "pointer",
                                        fontWeight: "700",
                                    }}
                                >
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "28.769px", // Adjust width as needed
                                            height: "31.385px", // Adjust height as needed
                                            backgroundImage: `url(${
                                                window.constants.asset_path + "/images/round-arrow.svg"
                                            })`,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: "18px", // Adjust size of dollar symbol
                                                color: "#1E88E5", // Match text color
                                                marginTop: "5px",
                                            }}
                                        >
                                            $
                                        </span>
                                    </span>
                                    <span
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            textAlign: "center",
                                            margin: "0px 14px",
                                        }}
                                    >
                                        <span style={{marginLeft: "-17px"}}>Price Forecasts</span>
                                        <span
                                            style={{
                                                color: "#1877F2",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                marginLeft: "5px",
                                            }}
                                        >
                                            By Influencers and Publishers
                                        </span>
                                    </span>
                                    <span style={{marginLeft: "auto"}}>
                                        <img
                                            src={window.constants.asset_path + "/images/link.svg"}
                                            width="24"
                                            height="24"
                                        />
                                    </span>
                                </button>

                                <button
                                    onClick={() => {
                                        navigate(`/crypto-screener`);
                                        window.scrollTo({top: 0, behavior: "smooth"}); // Scroll to the top of the page smoothly
                                    }}
                                    style={{
                                        padding: "6px 12px",
                                        fontSize: "19px",
                                        backgroundColor: "#fff", // Light blue background
                                        color: "#1E88E5", // Blue text
                                        border: "2px solid #1E88E5",
                                        borderRadius: "6px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        cursor: "pointer",
                                        fontWeight: "700",
                                    }}
                                >
                                    <span
                                        style={{
                                            position: "relative", // To position the lens inside the graph
                                            display: "flex",
                                            alignItems: "center", // Center the images horizontally
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: "21.126px", // Adjust the size of the graph image
                                                height: "18.842px", // Adjust the size of the graph image
                                                backgroundImage: `url(${
                                                    window.constants.asset_path + "/images/down-graph.svg"
                                                })`,
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center",
                                            }}
                                        ></span>
                                        <span
                                            style={{
                                                position: "absolute", // Position lens over the graph image
                                                top: "50%", // Center vertically
                                                left: "50%", // Center horizontally
                                                transform: "translate(-50%, -50%)", // Adjust the position to be perfectly centered
                                                width: "22.839px", // Adjust lens size as needed
                                                height: "22.839px", // Adjust lens size as needed
                                                backgroundImage: `url(${
                                                    window.constants.asset_path + "/images/lens-new.svg"
                                                })`,
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center",
                                                marginLeft: "2px",
                                                marginBottom: "3px",
                                            }}
                                        ></span>
                                    </span>
                                    Crypto Screener
                                    <span style={{marginLeft: "auto"}}>
                                        <img
                                            src={window.constants.asset_path + "/images/link.svg"}
                                            width="24"
                                            height="24"
                                        />
                                    </span>
                                </button>
                            </div>
                        )}
                        {(hasOscillatorData || hasMovingAverageData || processedDescription) && (
                            <div
                                style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    padding: "2px 0px 2px 0px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "15px 5px",
                                }}
                            >
                                {(hasOscillatorData || hasMovingAverageData) && (
                                    <div
                                        style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                                    >
                                        <button
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "700",
                                                border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                                borderRadius: "12px",
                                                background:
                                                    "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                                padding: "4px 10px",
                                                marginLeft: "5px",
                                                marginBottom: "8px",
                                                color: "black",
                                            }}
                                        >
                                            Technical Summary
                                        </button>
                                        <button
                                            onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                                            style={{
                                                textAlign: "center",
                                                color: "#727376",
                                                fontSize: "16px",
                                                width: "141px",
                                                height: "42px",
                                                fontSize: "14px",
                                                color: "white",
                                                backgroundColor: "#1877F2",
                                                padding: "4px 4px",
                                                borderRadius: "10px",
                                                marginBottom: "15px",
                                                border: "none",
                                                marginRight: "2%",
                                                marginTop: "0.7%",
                                            }}
                                        >
                                            Best Portfolios
                                            <img
                                                src={window.constants.asset_path + "/images/open_in_new_tab.svg"}
                                                style={{marginLeft: "5px"}}
                                            ></img>
                                        </button>
                                    </div>
                                )}

                                {(hasOscillatorData || hasMovingAverageData) && (
                                    <div
                                        style={{
                                            border: "1px solid #E2E2E4",
                                            borderRadius: "6px",
                                            margin: "0px 22px 15px 15px",
                                            padding: "7px 0px 15px 1px",
                                        }}
                                    >
                                        {overallSummary && (
                                            <div>
                                                <p style={{marginLeft: "4px", marginTop: "20px"}}>
                                                    In the next week, the overall sentiment for {slug} crypto price is{" "}
                                                    {overallSummary}. The first level of support for {slug} is at{" "}
                                                    {pivotdata.pivot_s1}&nbsp; while the first level of resistance for{" "}
                                                    {slug} price is at {pivotdata.pivot_r1}.&nbsp;
                                                    {sentimentStatement}
                                                </p>
                                            </div>
                                        )}

                                        {(hasOscillatorData || hasMovingAverageData) && (
                                            <div>
                                                {(hasOscillatorData || hasMovingAverageData) && (
                                                    <table
                                                        style={{
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            borderCollapse: "collapse",
                                                            margin: "5px 0% 20px 0%",
                                                            borderRadius: "5px",
                                                            border: "1px solid #E2E2E4 !important",
                                                            borderTop: "0.8px solid #E2E2E4",
                                                            borderBottom: "0.8px solid #E2E2E4",
                                                        }}
                                                    >
                                                        <thead>
                                                            <tr style={{borderBottom: "1px solid #E2E2E4"}}>
                                                                <th
                                                                    style={{
                                                                        color: "#727376",
                                                                        textAlign: "left",
                                                                        backgroundColor: "#fff",
                                                                        padding: "8px",
                                                                        fontSize: "16px",
                                                                        paddingLeft: "13px",
                                                                        borderRight: "0px solid #E2E2E4",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    Summary
                                                                </th>
                                                                <td
                                                                    style={{
                                                                        padding: "8px",
                                                                        fontSize: "16px",
                                                                        paddingLeft: "14px",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    {renderAction(overallSummary)}
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{fontSize: "16px"}}>
                                                            <tr
                                                                style={{
                                                                    borderBottom: "1px solid #E2E2E4",
                                                                    backgroundColor: "#D3E6FD",
                                                                }}
                                                            >
                                                                <td
                                                                    style={{
                                                                        width: "24.7%",
                                                                        padding: "12px",
                                                                        color: "#727376",
                                                                        fontSize: "16px",
                                                                        borderRight: "0px solid #E2E2E4",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    Technical Indicators
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: "13vw",
                                                                        padding: "12px",
                                                                        borderRight: "2px solid #E2E2E4",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    {renderAction(oscillatorSummary)}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: "13vw",
                                                                        padding: "12px",
                                                                        borderRight: "2px solid #E2E2E4",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    Buy: ({oscillatorBuys || "0"})
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: "13vw",
                                                                        padding: "12px",
                                                                        fontWeight: "500",
                                                                        borderRight: "2px solid #E2E2E4",
                                                                    }}
                                                                >
                                                                    Sell: ({oscillatorSells || "0"})
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: "13vw",
                                                                        padding: "12px",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    Neutral: ({oscillatorNeutrals || "0"})
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        width: "24.5%",
                                                                        padding: "12px",
                                                                        color: "#727376",
                                                                        fontSize: "16px",
                                                                        borderRight: "0px solid #E2E2E4",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    Moving Averages
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding: "12px",
                                                                        borderRight: "2px solid #E2E2E4",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    {renderAction(movingAverageSummary)}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding: "12px",
                                                                        borderRight: "2px solid #E2E2E4",
                                                                        fontWeight: "500",
                                                                    }}
                                                                >
                                                                    Buy: ({movingAverageBuys || "0"})
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        padding: "12px",
                                                                        fontWeight: "500",
                                                                        borderRight: "2px solid #E2E2E4",
                                                                    }}
                                                                >
                                                                    Sell: ({movingAverageSells || "0"})
                                                                </td>
                                                                <td style={{padding: "12px", fontWeight: "500"}}>
                                                                    Neutral: ({movingAverageNeutrals || "0"})
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                )}
                                                {(hasOscillatorData || hasMovingAverageData) && (
                                                    <p
                                                        style={{
                                                            textAlign: "right",
                                                            fontStyle: "italic",
                                                            margin: "2% 1%",
                                                            fontSize: "14px",
                                                            color: "#000",
                                                        }}
                                                    >
                                                        {timestamp}&nbsp;UTC
                                                    </p>
                                                )}

                                                {oscillatorData.length > 0 && (
                                                    <>
                                                        {/* Check if either RSI or MACD is present */}
                                                        {(oscillatorData[0].RSI || oscillatorData[4].MACD) && (
                                                            <>
                                                                {oscillatorData[0].RSI && (
                                                                    <p
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            margin: "2% 1% 0% 0.5%",
                                                                        }}
                                                                    >
                                                                        The RSI value is at {oscillatorData[0].RSI},
                                                                        which indicates that the {slug} market is in a{" "}
                                                                        {oscillatorData[0].rsi_evaluation} position.
                                                                    </p>
                                                                )}

                                                                {oscillatorData[4].MACD && (
                                                                    <p
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            margin: "2% 1% 0% 0.5%",
                                                                        }}
                                                                    >
                                                                        The MACD value is {oscillatorData[4].MACD}.{" "}
                                                                        {oscillatorData[4].macd_evaluation === "Buy" ? (
                                                                            <>
                                                                                The MACD line is above the signal line,
                                                                                and the green histogram bars have
                                                                                started to increase on the positive
                                                                                axis. This indicates that {slug} is in a
                                                                                buy position.
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                The MACD line is below the signal line,
                                                                                and the red histogram bars have started
                                                                                to increase on the negative axis. This
                                                                                indicates that {slug} is in a sell
                                                                                position.
                                                                            </>
                                                                        )}
                                                                        <br />
                                                                        <br />
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}

                                                {movingAveragesData.length > 0 && (
                                                    <>
                                                        {(movingAveragesData[0]["SMA (10)"] ||
                                                            movingAveragesData[4]["EMA (10)"] ||
                                                            movingAveragesData[1]["SMA (50)"] ||
                                                            movingAveragesData[5]["EMA (50)"] ||
                                                            movingAveragesData[2]["SMA (100)"] ||
                                                            movingAveragesData[3]["SMA (200)"] ||
                                                            movingAveragesData[6]["EMA (100)"] ||
                                                            movingAveragesData[7]["EMA (200)"]) && (
                                                            <>
                                                                <p
                                                                    style={{
                                                                        fontSize: "16px",
                                                                        margin: "0px 1% 0px 0.5%",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    {movingAveragesData[0]["SMA (10)"] && (
                                                                        <>
                                                                            For {slug}, the 10-day SMA is{" "}
                                                                            {movingAveragesData[0]["SMA (10)"]}. As the
                                                                            price of {slug} is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[0]["SMA (10)"]
                                                                                ? "below"
                                                                                : "above"}{" "}
                                                                            the 10-day SMA, it is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[0]["SMA (10)"]
                                                                                ? "bearish"
                                                                                : "bullish"}
                                                                            . The short-term outlook is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[0]["SMA (10)"]
                                                                                ? "negative"
                                                                                : "positive"}
                                                                            .
                                                                        </>
                                                                    )}

                                                                    {movingAveragesData[1]["SMA (50)"] && (
                                                                        <>
                                                                            &nbsp;Additionally, the 50-day SMA is{" "}
                                                                            {movingAveragesData[1]["SMA (50)"]}. {slug}{" "}
                                                                            is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[1]["SMA (50)"]
                                                                                ? "below"
                                                                                : "above"}{" "}
                                                                            its 50-day SMA, it is{" "}
                                                                            {currentPrice <
                                                                            movingAveragesData[1]["SMA (50)"]
                                                                                ? "bearish"
                                                                                : "bullish"}
                                                                            .
                                                                        </>
                                                                    )}

                                                                    {(movingAveragesData[2]["SMA (100)"] ||
                                                                        movingAveragesData[3]["SMA (200)"]) && (
                                                                        <>
                                                                            &nbsp;The 100-day and 200-day SMAs
                                                                            representing the long-term outlook of {slug}{" "}
                                                                            are&nbsp;
                                                                            {movingAveragesData[2]["SMA (100)"] ||
                                                                                "-"}{" "}
                                                                            and{" "}
                                                                            {movingAveragesData[3]["SMA (200)"] || "-"}{" "}
                                                                            respectively. Currently, the price of {slug}{" "}
                                                                            is{" "}
                                                                            {currentPrice <
                                                                                movingAveragesData[2]["SMA (100)"] &&
                                                                            currentPrice <
                                                                                movingAveragesData[3]["SMA (200)"]
                                                                                ? "below"
                                                                                : "above"}{" "}
                                                                            both the 100-day and 200-day Moving
                                                                            Averages. It is{" "}
                                                                            {currentPrice <
                                                                                movingAveragesData[2]["SMA (100)"] &&
                                                                            currentPrice <
                                                                                movingAveragesData[3]["SMA (200)"]
                                                                                ? "bearish"
                                                                                : "bullish"}
                                                                            .
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <button
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        border: "1px solid var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        borderRadius: "12px",
                                        background:
                                            "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                        padding: "4px 10px",
                                        marginLeft: "5px",
                                        marginBottom: "12px",
                                        marginTop: "10px",
                                        color: "black",
                                    }}
                                >
                                    {slug} Analysis
                                </button>

                                <div
                                    style={{
                                        border: "1px solid #E2E2E4",
                                        borderRadius: "6px",
                                        margin: "5px 22px 20px 15px",
                                        padding: "10px 0px 5px 3px",
                                    }}
                                >
                                    <div
                                        className="description"
                                        style={{
                                            fontFamily: "Satoshi, sans-serif!important",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            marginTop: "15px",
                                        }}
                                    >
                                        {processedDescription ? (
                                            <div dangerouslySetInnerHTML={{__html: processedDescription}}></div>
                                        ) : (
                                            <p></p> // or any other placeholder text
                                        )}
                                    </div>
                                </div>
                                {/* <div
                                    style={{
                                        border: "1px solid #E2E2E4",
                                        borderRadius: "6px",
                                        margin: "5px 22px 20px 15px",
                                        padding: "10px 0px 5px 3px",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "700",
                                            color: "#1877F2",
                                            background: "white",
                                        }}
                                    >
                                        Updates
                                    </p>
                                    <div
                                        className="description"
                                        style={{
                                            fontFamily: "Satoshi, sans-serif!important",
                                            borderRadius: "4px",
                                            padding: "2px",
                                            marginTop: "15px",
                                        }}
                                    >
                                        {analysis_data.ai_news && analysis_data.ai_news != "NA" ? (
                                            analysis_data.ai_news
                                            .split("\n") // Split the string into an array using '\n' as the delimiter
                                            .filter((line) => line.trim() !== "") // Remove empty lines
                                            .map((line, index) => (
                                                <p key={index}>{line.trim().replace(/^-\s, "")}</p> // Remove leading '-' and trim whitespace
                                            ))
                                        ) : (
                                            <p>No Updates Available</p> // or any other placeholder text
                                        )}
                                    </div>
                                </div> */}
                            </div>
                        )}

                        {isPredictionDataLoaded === false && predictionLoader ? (
                            <div style={{marginBottom: "30%"}}>
                                <div
                                    style={{
                                        border: "1px solid #B6B6B8",
                                        margin: "0px 2%",
                                        marginTop: "10px",
                                        textAlign: "center",
                                        color: "black",
                                        fontSize: "18px",
                                        backgroundColor: "white",
                                        padding: "2%",
                                        borderRadius: "8px",
                                        marginBottom: "5%",
                                    }}
                                >
                                    No Predictions available.
                                </div>
                                <div>
                                    <i>
                                        <p style={{marginLeft: "2%"}} class="text-justify">
                                            <b>Note:</b> Please consult a registered investment advisor to guide your
                                            financial decisions.
                                        </p>
                                    </i>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    padding: "15px 15px",
                                    fontFamily: "Satoshi, sans-serif",
                                    margin: "15px 5px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span style={{fontSize: "20px", fontWeight: "700"}}>Price Forecasts</span>
                                    <div
                                        style={{
                                            width: "25%",
                                            display: "flex",
                                            alignItems: "center",
                                            border: "1px solid #1877F2",
                                            borderRadius: "46px",
                                            padding: "7px 10px",
                                            background: "#F7F7F7",
                                            marginBottom: "12px",
                                        }}
                                    >
                                        <img
                                            src={window.constants.asset_path + "/images/search.svg"}
                                            alt="Search Icon"
                                            style={{
                                                marginLeft: "5px",
                                                marginRight: "10px",
                                                cursor: "pointer",
                                            }}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Search Forecaster"
                                            style={{
                                                flex: 1, // Makes the input field take up the available space

                                                background: "transparent",
                                                color: "black",
                                                border: "none", // Remove default input border
                                                outline: "none", // Remove focus outline
                                            }}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>

                                {/* Prediction Table */}
                                <div>
                                    <table
                                        className="predictions-table"
                                        style={{border: "2px solid #DADEDF", width: "100%", marginLeft: 0}}
                                    >
                                        <thead>
                                            <tr>
                                                <th
                                                    onClick={() => handleSort("forecaster")}
                                                    style={{
                                                        cursor: "pointer",
                                                        textAlign: "left",
                                                        width: "15%",
                                                        fontWeight: "500",
                                                        color: "#727376",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    Forecaster {sortOrder.forecaster === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th
                                                    onClick={() => handleSort("target")}
                                                    style={{
                                                        cursor: "pointer",
                                                        textAlign: "center",
                                                        fontWeight: "500",
                                                        color: "#727376",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    Target {sortOrder.target === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th
                                                    onClick={() => handleSort("accuracy")}
                                                    style={{
                                                        cursor: "pointer",
                                                        textAlign: "center",
                                                        fontWeight: "500",
                                                        color: "#727376",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    Past Accuracy {sortOrder.accuracy === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th
                                                    onClick={() => handleSort("percent")}
                                                    style={{
                                                        cursor: "pointer",
                                                        textAlign: "center",
                                                        fontWeight: "500",
                                                        color: "#727376",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    %Target Achieved {sortOrder.percent === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th
                                                    onClick={() => handleSort("targetDate")}
                                                    style={{
                                                        cursor: "pointer",
                                                        textAlign: "center",
                                                        fontWeight: "500",
                                                        color: "#727376",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    Forecast For {sortOrder.targetDate === "asc" ? "↑" : "↓"}
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isPredictionDataLoaded === false ? (
                                                // Render skeletons if data is loading
                                                [...Array(10)].map((_, index) => (
                                                    <tr key={index}>
                                                        {[...Array(6)].map((_, cellIndex) => (
                                                            <td key={cellIndex}>
                                                                <Skeleton variant="rounded" height={40} />
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))
                                            ) : sortedData.length > 0 ? (
                                                // Render data if available
                                                sortedData.slice(0, rowsToShow).map((data, index) => (
                                                    <tr key={index}>
                                                        <td
                                                            style={{
                                                                width: "23%",
                                                                background:
                                                                    "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                                                borderBottom: "6px solid #fff",
                                                                padding: "5px 7px",
                                                                color: "#000",
                                                                fontSize: "16px",
                                                                fontWeight: "500",
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            {data.forecaster_name}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                paddingLeft: "10px",
                                                            }}
                                                        >
                                                            {data.forecast_price}
                                                        </td>
                                                        <td
                                                            style={{textAlign: "center"}}
                                                            onClick={() => openModal(data)}
                                                        >
                                                            <span
                                                                className={
                                                                    data.accuracy.toLowerCase() !== "pending"
                                                                        ? "clickable"
                                                                        : "pending"
                                                                }
                                                            >
                                                                {data.accuracy}
                                                            </span>
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                paddingLeft: "10px",
                                                            }}
                                                        >
                                                            {data.target_percent}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                                paddingLeft: "10px",
                                                            }}
                                                        >
                                                            {data.target_date}
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <button
                                                                    style={{width: "20px"}}
                                                                    onClick={() => toggleDropdown(index)}
                                                                    className="dropdown-button"
                                                                >
                                                                    <img
                                                                        src={
                                                                            window.constants.asset_path +
                                                                            "/images/more.png"
                                                                        }
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            marginRight: "5px",
                                                                        }}
                                                                        alt="More"
                                                                    />
                                                                </button>
                                                                {dropdownVisible === index && (
                                                                    <div className="dropdown-content">
                                                                        <a
                                                                            href={data.source_link}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    window.constants.asset_path +
                                                                                    "/images/external-link-symbol.png"
                                                                                }
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    marginRight: "5px",
                                                                                }}
                                                                                alt="Copy"
                                                                            />
                                                                            Source link
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                // Show "No Predictions available" if no data matches the search
                                                <tr>
                                                    <td
                                                        colSpan={6}
                                                        style={{
                                                            textAlign: "center",
                                                            padding: "20px",
                                                            fontSize: "18px",
                                                            fontWeight: "500",
                                                            color: "#727376",
                                                        }}
                                                    >
                                                        No Predictions available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {rowsToShow < sortedData.length && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <button
                                                onClick={handleLoadMore}
                                                className="load-more"
                                                style={{
                                                    maxWidth: "360px",
                                                    width: "80vw",
                                                    backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                    borderRadius: "6px",
                                                    border: "none",
                                                    textTransform: "none",
                                                    borderStyle: "solid",
                                                    borderColor: "rgba(67, 97, 238, 0.15)",
                                                    borderWidth: "1px",
                                                    color: "#4361ee",
                                                    height: "40px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Explore More
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <Footer />
                            </div>
                        )}
                    </div>
                </div>
            ) : isPredictionDataLoaded === false && predictionLoader ? (
                <div style={{marginBottom: "50%"}}>
                    <div
                        style={{
                            border: "1px solid #B6B6B8",
                            margin: "0px 2%",
                            textAlign: "center",
                            color: "black",
                            fontSize: "18px",
                            backgroundColor: "white",
                            padding: "2%",
                            borderRadius: "8px",
                            marginBottom: "10%",
                        }}
                    >
                        No Predictions available
                    </div>
                    <div>
                        <i>
                            <p>
                                <b>Note:</b> Please consult a registered investment advisor to guide your
                                financial decisions.
                            </p>
                        </i>
                    </div>
                </div>
            ) : (
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "-20px",
                            marginBottom: 0,
                        }}
                    >
                        <span style={{fontSize: "17px", fontWeight: "700"}}>Price Forecasts</span>
                        <div
                            style={{
                                width: "47%",
                                display: "flex",
                                alignItems: "center",
                                border: "1px solid #1877F2",
                                borderRadius: "46px",
                                padding: "7px 10px",
                                background: "#F7F7F7",
                                marginBottom: "0px",
                                marginLeft: "15px",
                            }}
                        >
                            <img
                                src={window.constants.asset_path + "/images/search.svg"}
                                alt="Search Icon"
                                style={{
                                    marginLeft: "1%",
                                    marginRight: "2%",
                                    cursor: "pointer",
                                }}
                            />
                            <input
                                type="text"
                                placeholder="Search Forecaster"
                                style={{
                                    flex: 1, // Makes the input field take up the available space

                                    background: "transparent",
                                    color: "black",
                                    border: "none", // Remove default input border
                                    outline: "none", // Remove focus outline
                                }}
                                onChange={handleSearchChange}
                            />
                        </div>

                        <div>
                            <Box
                                sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: "0px",
                                    color: "#1877F2",
                                    marginLeft: "-10px",
                                }}
                            >
                                <Button onClick={handlePopoverOpen}>
                                    <div
                                        style={{
                                            padding: "6px 2px",
                                        }}
                                    >
                                        <img width="23px" src={window.constants.asset_path + "/images/filter2.svg"} />
                                    </div>
                                </Button>

                                <Popover
                                    open={openfilter}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseeoption}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                    style={{}}
                                >
                                    <MenuItem
                                        selected={selectedFilter === "fc_asc"}
                                        onClick={() => handleSort("forecaster")}
                                    >
                                        Forecaster {sortOrder.forecaster === "asc" ? "↑" : "↓"}
                                    </MenuItem>

                                    <MenuItem
                                        selected={selectedFilter === "tg_asc"}
                                        onClick={() => handleSort("target")}
                                    >
                                        Target {sortOrder.target === "asc" ? "↑" : "↓"}
                                    </MenuItem>
                                    <MenuItem
                                        selected={selectedFilter === "ac_asc"}
                                        onClick={() => handleSort("accuracy")}
                                    >
                                        Accuracy {sortOrder.accuracy === "asc" ? "↑" : "↓"}
                                    </MenuItem>

                                    <MenuItem
                                        selected={selectedFilter === "pr_asc"}
                                        onClick={() => handleSort("percent")}
                                    >
                                        Achieved {sortOrder.percent === "asc" ? "↑" : "↓"}
                                    </MenuItem>
                                    <MenuItem
                                        selected={selectedFilter === "fd_asc"}
                                        onClick={() => handleSort("targetDate")}
                                    >
                                        Target Date {sortOrder.targetDate === "asc" ? "↑" : "↓"}
                                    </MenuItem>

                                    <Box sx={{display: "flex", padding: "8px"}}>
                                        <Button
                                            size="small"
                                            sx={{textTransform: "capitalize", marginLeft: "10px"}}
                                            onClick={handleClearAll}
                                            startIcon={<ClearIcon />}
                                        >
                                            Clear All
                                        </Button>
                                    </Box>
                                </Popover>
                            </Box>
                        </div>
                    </div>

                    {/* Prediction Table */}
                    <table
                        className="predictions-table-mobile"
                        style={{
                            width: "100%",
                            borderCollapse: "separate",
                            borderSpacing: "0 10px",
                        }}
                    >
                        {isPredictionDataLoaded === false ? (
                            <tbody>
                                {[...Array(3)].map((_, index) => (
                                    <tr
                                        key={index}
                                        style={{
                                            backgroundColor: "#fff",
                                            borderRadius: "10px",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        {[...Array(5)].map((_, cellIndex) => (
                                            <td key={cellIndex} style={{padding: "15px"}}>
                                                <Skeleton variant="rounded" height={40} />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        ) : sortedData.length === 0 ? ( // Check if no data is found
                            <tbody>
                                <tr>
                                    <td
                                        colSpan={5}
                                        style={{
                                            textAlign: "center",
                                            padding: "20px",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#76787A",
                                        }}
                                    >
                                        No Prediction Available
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {sortedData.slice(0, rowsToShow).map((data, index) => (
                                    <tr
                                        key={index}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            borderRadius: "10px",
                                            marginBottom: "20px",
                                            padding: "0px",
                                        }}
                                    >
                                        <td
                                            style={{
                                                background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
                                                borderRadius: "6px 6px 0px 0px",
                                                padding: "10px",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                                color: "#000",
                                            }}
                                        >
                                            {data.forecaster_name}
                                        </td>

                                        <td style={{padding: "4px 10px", fontSize: "14px"}}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <span
                                                        style={{color: "#76787A", fontSize: "13px", marginRight: "7px"}}
                                                    >
                                                        Target :
                                                    </span>{" "}
                                                    {data.forecast_price}
                                                </div>
                                                <div>
                                                    <span
                                                        style={{color: "#76787A", fontSize: "13px", marginRight: "7px"}}
                                                    >
                                                        Target Achieved :
                                                    </span>{" "}
                                                    {data.target_percent}
                                                </div>
                                            </div>
                                        </td>

                                        <td style={{padding: "4px 10px", fontSize: "14px"}}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <span
                                                        style={{color: "#76787A", fontSize: "13px", marginRight: "7px"}}
                                                    >
                                                        Accuracy :
                                                    </span>{" "}
                                                    {data.accuracy}
                                                </div>
                                                <div>
                                                    <span
                                                        style={{color: "#76787A", fontSize: "13px", marginRight: "7px"}}
                                                    >
                                                        Target Date :
                                                    </span>{" "}
                                                    {formatDate(data.target_date)}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {rowsToShow < sortedData.length && (
                                    <tr style={{background: "#fff"}}>
                                        <td colSpan={5} style={{textAlign: "center"}}>
                                            <button
                                                onClick={handleLoadMore}
                                                style={{
                                                    backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                    borderRadius: "8px",
                                                    padding: "10px 20px",
                                                    color: "#4361ee",
                                                    fontSize: "16px",
                                                    border: "none",
                                                    cursor: "pointer",
                                                    marginBottom: "50px",
                                                }}
                                            >
                                                Explore More
                                            </button>
                                        </td>
                                        <i>
                                            <p class="text-justify">
                                                <b>Note:</b> Please consult a registered investment advisor to guide
                                                your financial decisions.
                                            </p>
                                        </i>
                                    </tr>
                                )}
                            </tbody>
                        )}
                    </table>
                    <Footer />
                </div>
            )}
            {mediaQueryVar && sortedData.length > 0 && (
                <i>
                    <p style={{marginLeft: "20.5vw"}} class="text-justify">
                        <b>Note:</b> Please consult a registered investment advisor to guide your financial decisions.
                    </p>
                </i>
            )}
            {mediaQueryVar === false && (
                <div
                    style={{
                        position: "fixed",
                        bottom: "0",
                        backgroundColor: "white",
                        width: "105%",
                        zIndex: "100",
                        marginBottom: "0px",
                        marginLeft: "-25px",
                    }}
                >
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label="Top Portfolios"
                            onClick={() => navigate("/portfolio-gallery")}
                            icon={
                                <img
                                    src={
                                        loc === "/portfolio-gallery"
                                            ? "/images/dashboardS.svg"
                                            : "/images/dashboardU.svg"
                                    }
                                    width={"20px"}
                                    height={"20px"}
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="Crypto Screener"
                            onClick={() => navigate("/crypto-screener")}
                            icon={
                                <img
                                    src={
                                        loc === "/crypto-screener" ? "/images/portfolioS.svg" : "/images/portfolioU.svg"
                                    }
                                    width={"20px"}
                                    height={"20px"}
                                />
                            }
                        />
                        <BottomNavigationAction
                            label="Portfolio Audit"
                            onClick={() => {
                                if (userData) {
                                    navigate("/subscriptions/screener360");
                                } else {
                                    const originalUrl = "/subscriptions/screener360";
                                    sessionStorage.setItem("originalUrl", originalUrl);
                                    handleLoginClick();
                                }
                            }}
                            icon={
                                <img
                                    src={
                                        loc === "/subscriptions/screener360"
                                            ? "/images/dollarS.svg"
                                            : "/images/dollarU.svg"
                                    }
                                    width={24}
                                    height={24}
                                    alt="Icon"
                                    style={{marginBottom: "-3px"}}
                                />
                            }
                        />
                    </BottomNavigation>
                </div>
            )}
            {isLoginModalOpen && (
                <LoginPopUp
                    isLoginModalOpen={isLoginModalOpen}
                    setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                    onClose={handleModalClose}
                />
            )}
            <Modal
                style={{
                    overlay: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                        marginLeft: mediaQueryVar === true ? "20%" : "",
                    },
                    content: {
                        align: "center",
                        width: "80%",
                        marginBottom: "20%",
                        height: "auto",
                        borderRadius: "8px",
                        position: "center",
                    },
                }}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Accuracy Details"
            >
                <img
                    className="frameIcon"
                    alt=""
                    onClick={closeModal}
                    src="/images/frame17.svg"
                    style={{
                        background: "#F3F3F3",
                        width: "25px",
                        height: "25px",
                        marginTop: "1.5%",
                        marginBottom: "1%",
                    }}
                />
                <h4>Past Accuracy</h4>
                {modalData ? (
                    <table className="modal-table">
                        <thead>
                            <tr>
                                <th>Published On</th>
                                <th>Forecast Date</th>
                                <th style={{textAlign: "center"}}>Forecast Price</th>
                                <th style={{textAlign: "center"}}>Accuracy</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modalData.map((detail, index) => (
                                <tr key={index}>
                                    <td>{detail.publish_date}</td>
                                    <td>{detail.target_date} 11:59 PM</td>
                                    <td style={{textAlign: "center"}}>{detail.forecast_price}</td>
                                    <td style={{textAlign: "center"}}>{detail.accuracy}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No data available</p>
                )}
            </Modal>
        </div>
    );
};

export default CryptoPage;
